import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUser } from "../api/UserApi";

const User_Add = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate(-1);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const addUserHandler = (e) => {
    e.preventDefault();
    const data = {
      name: userName,
      profile: image,
      phone: userPhone,
    };
    dispatch(addUser({ admin_id, auth: token, data }));
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={addUserHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Add User Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>User Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>User Phone No</Box>
              <TextField
                type='tel'
                autoComplete='off'
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                required
                fullWidth
              />
            </Typography>

            <Stack marginTop={3}>
              {image && (
                <Stack direction='column' spacing={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(image)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>User Profile</Box>
            </Typography>
            <TextField
              required
              onChange={imageUploader}
              type='file'
              fullWidth
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                type='button'
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add User
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default User_Add;
