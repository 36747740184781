import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const addSong = createAsyncThunk(
  "addSong",
  async ({ admin_id, auth, data }) => {
    console.log(data);
    const response = await axios.post(
      REAL_API + `/songs?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + auth,
          Accept: "application/json",
          "Content-type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
);

// {{localPath}}/api/admin/songs/7F6MI4JC6O4J24KON995D?admin_id={{adminID}}&_method=PUT
export const updateSong = createAsyncThunk(
  "update Song",
  async ({ admin_id, auth, song_key, data }) => {
    console.log({ admin_id, auth, song_key, data });
    const response = await axios.post(
      REAL_API + `/songs/${song_key}?admin_id=${admin_id}&_method=PUT`,
      data,
      {
        headers: {
          Authorization: "Bearer " + auth,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    return response.data;
  }
);
