import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Stack,
  TextField,
  Button,
  Avatar,
  IconButton,
  Alert,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getArtistList } from "../api/Artist_List_Api";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import { BarLoader } from "react-spinners";

const Artist_List = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [artistName, setArtistName] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { artistList, isLoading, artistTotal, loadingAnimation } = useSelector(
    (state) => state.artist_list
  );
  const [artistDelete, setArtistDelete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const timeoutInput = setTimeout(() => {
      setArtistName(inputVal);
    }, 400);
    return () => clearInterval(timeoutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getArtistList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        filter_name: artistName,
      })
    );
  }, [page, rowsPerPage, artistDelete, artistName]);

  useEffect(() => {
    if (artistName.length < 1)
      dispatch(
        getArtistList({
          admin_id,
          auth: token,
          page,
          limit: rowsPerPage,
          filter_name: artistName,
        })
      );
  }, [artistName]);

  const searchArtistHandler = (e) => {
    e.preventDefault();
    dispatch(
      getArtistList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        filter_name: artistName,
      })
    );
  };

  console.log("Artist List All Data:", artistList);
  console.log({ artistDelete });

  return (
    <>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              onSubmit={searchArtistHandler}
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Stack alignItems='center' direction='row' spacing={2}>
                  <Typography component='span'>
                    <Box
                      sx={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Artist List
                    </Box>
                  </Typography>
                  <TextField
                    width='5rem'
                    label='Search Artist Name'
                    type='text'
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </Stack>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    navigate("/artist-list/add");
                  }}
                  aria-label='create-producer'
                  sx={{
                    width: "150px",
                  }}
                  variant='outlined'
                >
                  Add Artist
                </Button>
              </Box>
            </Box>
          </Paper>

          {isLoading && artistList && (
            <Paper
              sx={{
                width: "100%",
                marginTop: "3rem",
                overflow: "hidden",
              }}
            >
              <TableContainer>
                <Table stickyHeader aria-label='artist-list-table'>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          bgcolor: "#d7ccc8",
                          color: "#424242",
                        },
                      }}
                    >
                      {titles.map((title) => (
                        <TableCell key={title.id} align={title.align}>
                          {title.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {artistList?.data?.length > 0 ? (
                      artistList?.data?.map((list) => (
                        <TableRow key={list.artist_key}>
                          <TableCell align='right'>
                            {list.media == null ? (
                              <Avatar
                                sx={{
                                  width: "100px",
                                  height: "100px",
                                }}
                                src='https://v.totamedia.com/static/media/pngwing.com%20(1).2e5a2f8f1da62b3b2c6e.png'
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: "100px",
                                  height: "100px",
                                }}
                                src={list?.media?.media_link}
                              />
                            )}
                          </TableCell>
                          <TableCell align='right'>
                            <Link
                              to='/artist-detail'
                              state={{
                                artist_key: list.artist_key,
                              }}
                            >
                              {list?.name}
                            </Link>
                          </TableCell>

                          <TableCell align='right'>
                            <Stack direction='row' justifyContent='flex-end'>
                              <IconButton
                                onClick={() =>
                                  navigate("/artist-list/edit", {
                                    state: {
                                      artist_data: list,
                                    },
                                  })
                                }
                              >
                                <EditIcon color='primary' />
                              </IconButton>
                              <ConfirmDelete
                                artistDelete={artistDelete}
                                setArtistDelete={setArtistDelete}
                                artist_key={list.artist_key}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Alert fullWidth severity='error'>
                            No Artist Here!
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component='div'
                count={artistTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </>
  );
};

export default Artist_List;

const titles = [
  {
    id: "image",
    label: "Image",
    minWidth: 170,
    // align: "right",
  },
  { id: "artist-name", label: "Artist Title", minWidth: 170, align: "right" },
  {
    id: "Artist-action",
    label: "Action",
    align: "right",
    minWidth: 100,
  },
];
