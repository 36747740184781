import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAlbumList } from "../../Album_List/api/AlbumListApi";
import { setSelectedAlbumMultiSelect } from "../../Album_List/api/AlbumListApiSlice";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const Group_List_Drawer_Edit_Album_List = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [checkInput, setCheckInput] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { albumListArr, selectedAlbumListMultiSelect } = useSelector(
    (state) => state.album_list
  );

  // useEffect(() => {
  //   setOriginalGroupList(music_list_data);
  // }, []);

  const dispatch = useDispatch();

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getAlbumList({
        admin_id,
        page,
        limit: rowsPerPage,
        auth: token,
        album_name: actVal,
      })
    );
  }, [actVal]);

  console.log({ albumListArr });
  console.log({ checkInput });
  console.log({ selectedAlbumListMultiSelect });

  return (
    <div>
      <Autocomplete
        sx={{
          margin: "60px 500px 300px",
        }}
        multiple
        id='checkboxes-tags-demo'
        options={albumListArr}
        value={selectedAlbumListMultiSelect}
        onChange={(event, newValue) => {
          setCheckInput(newValue);
          dispatch(setSelectedAlbumMultiSelect(newValue));
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          console.log({ option, value });
          return option.album_key === value.album_key;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.album_key}>
              <Checkbox
                icon={icon}
                key={option.album_key}
                label={option.name}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.name}
            </li>
          );
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            sx={{
              width: "800px",
            }}
            onChange={(event) => {
              setInputVal(event.target.value);
            }}
            {...params}
            label='Album Checkbox'
            placeholder='Select Album'
          />
        )}
      />
    </div>
  );
};

export default Group_List_Drawer_Edit_Album_List;
