import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getMusicList = createAsyncThunk(
  "musicList/getMuiscList",
  async ({ admin_id, auth }) => {
    const response = await fetch(
      REAL_API + `/musiclists?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    return result;
  }
);

export const getMusicListDetail = createAsyncThunk(
  "musicList/getMusicListDetail",
  async ({ admin_id, auth, music_list_key, song_name, limit }) => {
    const response = await fetch(
      REAL_API +
        `/musiclists/${music_list_key}?admin_id=${admin_id}&song_name=${song_name}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const addMusicList = createAsyncThunk(
  "musicList/addMusicList",
  async ({ admin_id, auth, data }) => {
    console.log({ admin_id, auth, data });

    const response = await axios.post(
      REAL_API + `/musiclists?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const deleteMusicList = createAsyncThunk(
  "delete musicList",
  async ({ admin_id, auth, id }) => {
    console.log({ admin_id, auth, id });
    const response = await fetch(
      REAL_API + `/musiclists/${id}?user_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const musicListUpdate = createAsyncThunk(
  "update Music List",
  async ({ admin_id, auth, id, data }) => {
    console.log({ admin_id, auth, id, data });
    const response = await axios.post(
      REAL_API + `/musiclists/${id}?admin_id=${admin_id}&_method=PUT`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);
