import { createSlice } from "@reduxjs/toolkit";
import { getBandList } from "./BandApi";
import { getBandDetail } from "./BandApi";

const initialState = {
  bandListDetail: [],
  bandTotal: 1,
  bandDetail: [],
  bandListArr: [],
  selectedBand: [],
  loadingAnimation: false,
};

export const BandApiSlice = createSlice({
  name: "band list",
  initialState,
  reducers: {
    setSelectedBand: (state, { payload }) => {
      state.selectedBand = [payload];
    },
    setSelectedBandDefault: (state) => {
      state.selectedBand = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBandList.fulfilled, (state, { payload }) => {
      state.bandListDetail = payload;
      state.bandTotal = payload.total;
      state.bandListArr = payload.data;
      state.loadingAnimation = true;
    });
    builder.addCase(getBandDetail.fulfilled, (state, { payload }) => {
      state.bandDetail = payload;
    });
  },
});

export const { setSelectedBand, setSelectedBandDefault } = BandApiSlice.actions;

export default BandApiSlice.reducer;
