import { createSlice, current } from "@reduxjs/toolkit";
import { getGroupList } from "./Group_List_Api";

const initialState = {
  groupList: [],
  loadingAnimation: false,
};

export const GroupListApiSlice = createSlice({
  name: "group list",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGroupList.fulfilled, (state, { payload }) => {
      state.groupList = payload.data;
      state.loadingAnimation = true;
    });
  },
});

export default GroupListApiSlice.reducer;
