import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getArtistDetail } from "../api/Artist_Detail_Api";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PianoIcon from "@mui/icons-material/Piano";

const Artist_Detail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { artistDetail } = useSelector((state) => state.artist_detail);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const artist_key = location.state.artist_key;

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  useEffect(() => {
    dispatch(getArtistDetail({ auth: token, admin_id, artist_key }));
  }, []);

  console.log({ artistDetail });

  return (
    <div>
      {artistDetail && (
        <Box>
          <Paper
            sx={{
              width: "1000px",
              padding: "10px",
              margin: "60px auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack alignItems='center' spacing={3} direction='row'>
                <img
                  width='100px'
                  src={artistDetail?.artist?.media?.media_link}
                  alt='artist picture'
                />
                <Typography variant='h5'>
                  {artistDetail?.artist?.name}
                </Typography>
              </Stack>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Box>
          </Paper>
        </Box>
      )}
      {artistDetail?.length == 1 || artistDetail?.songs?.length > 0 ? (
        artistDetail?.songs && (
          <Paper sx={{ width: "100%", marginTop: "3rem", overflow: "hidden" }}>
            <TableContainer
              sx={{
                maxHeight: 440,
              }}
            >
              <Table stickyHeader aria-label='song-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align} key={title.id}>
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {artistDetail?.songs?.length > 0
                    ? artistDetail?.songs.map((list) => (
                        <TableRow key={list.song_key}>
                          <TableCell>
                            <Typography variant='body1' component='div'>
                              <Link
                                to='/song-detail'
                                state={{
                                  song_key: list.song_key,
                                }}
                              >
                                {list?.title}
                              </Link>
                            </Typography>
                            <br />
                            <Box
                              sx={{
                                display: "flex",
                                alginItems: "center",
                              }}
                            >
                              <InterpreterModeIcon
                                size='small'
                                sx={{
                                  margin: "0 4px",
                                }}
                              />

                              {list?.singers ? (
                                list?.singers?.length == 1 ? (
                                  <Link
                                    to='/singer-detail'
                                    state={{
                                      singer_key: list?.singers[0].singer_key,
                                    }}
                                  >
                                    {list?.singers[0]?.name}
                                  </Link>
                                ) : (
                                  list?.singers?.map((singer) => (
                                    <Link
                                      to='/singer-detail'
                                      state={{
                                        singer_key: singer.singer_key,
                                      }}
                                      key={singer.singer_key}
                                    >
                                      {singer.name + " ၊ "}
                                    </Link>
                                  ))
                                )
                              ) : (
                                "N/A"
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Link
                              to='/album-detail'
                              state={{ album_key: list.album.album_key }}
                            >
                              {list.album.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {list?.music_lists === null ? (
                              "N/A"
                            ) : (
                              <LightTooltip
                                placement='right-start'
                                title={
                                  <Box
                                    sx={{
                                      width: "200px",

                                      padding: "20px",
                                    }}
                                  >
                                    <Typography variant='h6'>Group</Typography>
                                    {list?.music_lists?.map((li) => (
                                      <Typography
                                        sx={{
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {li?.name}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <IconButton
                                  sx={{
                                    color: "#0277bd",
                                  }}
                                >
                                  <PianoIcon />
                                </IconButton>
                              </LightTooltip>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.contracts?.audio == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :{" "}
                                        {list.contracts?.audio?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.audio?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <HeadphonesIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.audioKaraoke == null ? (
                              "N/A"
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.audioKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :{" "}
                                        {list.contracts?.audioKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <InterpreterModeIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.video == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :{" "}
                                        {list.contracts?.video?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.video?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <PersonalVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.videoKaraoke == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.videoKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :{" "}
                                        {list.contracts?.videoKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <MusicVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>N/A</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                navigate("/song-list/edit", {
                                  state: {
                                    song_detail_data: list,
                                  },
                                })
                              }
                            >
                              <EditIcon color='info' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    : "N/A"}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )
      ) : (
        <Alert fullWidth severity='error'>
          No Song Here!
        </Alert>
      )}
    </div>
  );
};

export default Artist_Detail;

const titles = [
  { id: "music-title", label: "Music Title", minWidth: 150 },
  { id: "album-name", label: "Album", align: "left", minWidth: 170 },
  // { id: "artist-name", label: "Artist", align: "left", minWidth: 170 },
  { id: "group-name", label: "Group", align: "left", minWidth: 170 },
  { id: "contract-type", label: "Contract", align: "left", minWidth: 170 },
  {
    id: "producer-name",
    label: "Producer",
    align: "left",
    minWidth: 170,
  },
  { id: "action", label: "Action", align: "left", minWidth: 170 },
];
