import "./App.css";
import React from "react";
import SideLayout from "./components/SideLayout";

const App = () => {
  return (
    <div>
      <SideLayout />
    </div>
  );
};

export default App;
