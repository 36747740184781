import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

export const getSongList = createAsyncThunk(
  "songList/getSongList",
  async ({ admin_id, auth, page, limit, searchSong, type, contract }) => {
    const response = await fetch(
      REAL_API +
        `/songs?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}${contract}&filter_name=${searchSong}&type=${type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const getSongDetail = createAsyncThunk(
  "songDetail/getSongDetail",
  async ({ auth, admin_id, song_key }) => {
    console.log(REAL_API + `/songs/${song_key}?admin_id=${admin_id}`);
    const response = await fetch(
      REAL_API + `/songs/${song_key}?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
