import { createSlice } from "@reduxjs/toolkit";
import { getAlbumDetail } from "./AlbumDetailApi";

const initialState = {
  albumDetail: [],
};

export const AlbumDetailApi = createSlice({
  name: "album detail",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAlbumDetail.fulfilled, (state, { payload }) => {
      state.albumDetail = payload;
    });
  },
});

export default AlbumDetailApi.reducer;
