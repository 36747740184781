import { createSlice } from "@reduxjs/toolkit";
import { getRecordingDetail, getRecordingList } from "./RecordingApi";

const initialState = {
  recordingList: [],
  recordingTotal: 1,
  recordingListArr: [],
  selectedRecording: [],
  recordingDetail: [],
  loadingAnimation: false,
};

export const RecordingApiSlice = createSlice({
  name: "Recording List",
  initialState,
  reducers: {
    setSelectedRecording: (state, { payload }) => {
      state.selectedRecording = [payload];
    },
    setSelectedRecordingDefault: (state) => {
      state.selectedRecording = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecordingList.fulfilled, (state, { payload }) => {
      state.recordingList = payload;
      state.recordingTotal = payload.total;
      state.recordingListArr = payload.data;
      state.loadingAnimation = true;
    });
    builder.addCase(getRecordingDetail.fulfilled, (state, { payload }) => {
      state.recordingDetail = payload;
    });
  },
});

export const { setSelectedRecording, setSelectedRecordingDefault } =
  RecordingApiSlice.actions;

export default RecordingApiSlice.reducer;
