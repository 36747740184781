import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Song_Edit_Singer from "../songedit_components/Song_Edit_Singer";
import { setSelectedSinger } from "../../Singer_List/api/SingerListApiSlice";
import { setSelectedMusicList } from "../../Music_List/api/MusicListApiSlice";
import { setSelectedArtist } from "../../Artist/api/ArtistListApiSlice";
import Song_Edit_MusicList from "../songedit_components/Song_Edit_MusicList";
import Song_Edit_Artist from "../songedit_components/Song_Edit_Artist";
import { getAlbumList } from "../../Album_List/api/AlbumListApi";
import {
  setSelectedAlbum,
  setSelectedAlbumMultiSelect,
} from "../../Album_List/api/AlbumListApiSlice";
import { getProducerList } from "../../Producer/api/ProducerApi";
import { setSelectedProducer } from "../../Producer/api/ProducerApiSlice";
import { setSelectedBand } from "../../Band/api/BandApiSlice";
import { getBandList } from "../../Band/api/BandApi";
import { getRecordingList } from "../../Recording/api/RecordingApi";
import { setSelectedRecording } from "../../Recording/api/RecordingApiSlice";
import { updateSong } from "../api/SongAddApi";

const Song_List_Edit = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [updateSongName, setUpdateSongName] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [startSecond, setStartSecond] = useState("");
  const [endMinute, setEndMinute] = useState("");
  const [endSecond, setEndSecond] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const [drawerSinger, setDrawerSinger] = useState(false);
  const [drawerMusicList, setDrawerMusicList] = useState(false);
  const [drawerArtist, setDrawerArtist] = useState(false);
  const { selectedSingerList } = useSelector((state) => state.singer_list);
  const { selectedMusicList } = useSelector((state) => state.music_list);
  const { selectedArtistList } = useSelector((state) => state.artist_list);

  const { albumListArr, selectedAlbumList } = useSelector(
    (state) => state.album_list
  );
  const { producerListArr, selectedProducer } = useSelector(
    (state) => state.producer_data
  );
  const { bandListArr, selectedBand } = useSelector((state) => state.band_list);
  const { recordingListArr, selectedRecording } = useSelector(
    (state) => state.recording_list
  );

  const [albumInputVal, setAlbumInputVal] = useState("");
  const [albumActVal, setAlbumActVal] = useState("");
  const [producerInputVal, setProducerInputVal] = useState("");
  const [producerActVal, setProducerActVal] = useState("");
  const [bandInputVal, setBandInputVal] = useState("");
  const [bandActVal, setBandActVal] = useState("");
  const [recordInputVal, setRecordInputVal] = useState("");
  const [recordActVal, setRecordActVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [producerValue, setProducerValue] = useState(null);
  const [albumValue, setAlbumValue] = useState(null);
  const [bandValue, setBandValue] = useState(null);
  const [recordingValue, setRecordingValue] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const song_detail_data = location.state.song_detail_data;

  console.log({ song_detail_data });
  console.log({ selectedAlbumList });

  // Album Search Start

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setAlbumActVal(albumInputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [albumInputVal]);

  useEffect(() => {
    dispatch(
      getAlbumList({
        admin_id,
        page,
        limit: rowsPerPage,
        auth: token,
        album_name: albumActVal,
      })
    );
  }, [albumActVal]);

  // Album Search End

  // Producer Search Start

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setProducerActVal(producerInputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [producerInputVal]);

  useEffect(() => {
    dispatch(
      getProducerList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        producer_name: producerActVal,
      })
    );
  }, [producerActVal]);
  // Producer Search End

  // Band Search Start

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setBandActVal(bandInputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [bandInputVal]);

  useEffect(() => {
    dispatch(
      getBandList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        band_name: bandActVal,
      })
    );
  }, [bandActVal]);

  // Band Search End

  // Recording Search Start

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setRecordActVal(recordInputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [recordInputVal]);

  useEffect(() => {
    dispatch(
      getRecordingList({
        admin_id,
        recording_name: recordActVal,
        auth: token,
        page,
        limit: rowsPerPage,
      })
    );
  }, [recordActVal]);

  // Recording Search End

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const msToMinute = (totalMilliseconds) => {
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    return minutes;
  };

  const msToSecond = (totalMilliseconds) => {
    const seconds = Math.floor(totalMilliseconds / 1000) % 60;
    return seconds;
  };

  useEffect(() => {
    setUpdateSongName(song_detail_data.title);

    if ("start" in song_detail_data) {
      setStartMinute(msToMinute(song_detail_data.start));
      setStartSecond(msToSecond(song_detail_data.start));
    } else {
      setStartMinute(msToMinute(0));
      setStartSecond(msToSecond(0));
    }

    if ("end" in song_detail_data) {
      setEndMinute(msToMinute(song_detail_data.end));
      setEndSecond(msToSecond(song_detail_data.end));
    } else {
      setEndMinute(msToMinute(0));
      setEndSecond(msToSecond(0));
    }
    setImage(song_detail_data?.media?.media_link);
    dispatch(setSelectedSinger(song_detail_data.singers));
    dispatch(setSelectedMusicList(song_detail_data.music_lists));
    dispatch(setSelectedArtist(song_detail_data.artists));
    dispatch(setSelectedAlbum(song_detail_data.album));

    if ("producer" in song_detail_data) {
      dispatch(setSelectedProducer(song_detail_data.producer));
      setProducerValue(song_detail_data.producer);
    }

    if ("band" in song_detail_data) {
      dispatch(setSelectedBand(song_detail_data.band));
      setBandValue(song_detail_data.band);
    }

    if ("recording" in song_detail_data) {
      dispatch(setSelectedRecording(song_detail_data.recording));
      setRecordingValue(song_detail_data.recording);
    }
  }, []);

  // Changing Singer Format Start
  const requiredSingerFormat = selectedSingerList?.map((singer) => {
    return {
      name: singer?.name,
      singer_key: singer?.singer_key,
    };
  });

  const singerValue = selectedSingerList?.map((item) => {
    return item?.name;
  });
  // Changing Singer Format End

  // Changing Music List Format Start

  const musicListValue = selectedMusicList?.map((item) => {
    return item?.name;
  });

  const requiredMusicListFormat = selectedMusicList?.map((item) => {
    return {
      id: item?._id || item?.id,
      name: item?.name,
    };
  });

  // Changing Music List Format End

  // Changing Artist List Format Start
  const artistListValue = selectedArtistList?.map((item) => {
    return item?.name;
  });

  const requiredArtistListFormat = selectedArtistList?.map((item) => {
    return {
      name: item?.name,
      artist_key: item?.artist_key,
    };
  });
  // Changing Artist List Format End

  // Changing Album List Format Start

  const requiredAlbumListFormat = selectedAlbumList?.map((item) => {
    return {
      name: item?.name,
      album_key: item?.album_key,
    };
  });

  // Changing Album List Format End

  // Changing Producer Format Start

  const requiredProducerFormat = selectedProducer?.map((item) => {
    return {
      name: item?.name,
      producer_key: item?.producer_key,
    };
  });
  // Changing Producer Format End

  // Changing Band Format Start

  console.log({ selectedBand });

  const requiredBandFormat = selectedBand?.map((item) => {
    return {
      name: item?.name,
      band_key: item?.band_key,
    };
  });

  // Changing Band Format End

  // Changing Recording Format Start

  const requiredRecordingFormat = selectedRecording?.map((item) => {
    return {
      name: item?.name,
      recording_key: item?.recording_key,
    };
  });
  // Changing Recording Format End

  const updateSongHandler = (e) => {
    e.preventDefault();
    console.log("Clicked");

    const data = {
      title: updateSongName,
      singers: requiredSingerFormat,
      music_lists: requiredMusicListFormat,
      artists: requiredArtistListFormat,
      album: requiredAlbumListFormat[0],
      producer: requiredProducerFormat[0],
      band: requiredBandFormat[0],
      recording: requiredRecordingFormat[0],
      lyric: null,
      melody: null,
      media: updateImage === undefined ? null : updateImage,
    };

    dispatch(
      updateSong({
        admin_id,
        auth: token,
        song_key: song_detail_data?.song_key,
        data,
      })
    );
    navigate(-1);
  };
  return (
    <div>
      <form onSubmit={updateSongHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={10}
          sx={{
            p: 5,
            height: "100%",
            width: "80%",
            m: "2px auto",
          }}
          square
        >
          <Box>
            <Typography
              variant='h6'
              my={4}
              sx={{
                userSelect: "none",
              }}
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Update Song +</Box>
            </Typography>

            <Stack spacing={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    sx={{
                      userSelect: "none",
                    }}
                    variant='h6'
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Song Name</Box>
                  </Typography>
                  <TextField
                    value={updateSongName}
                    onChange={(e) => setUpdateSongName(e.target.value)}
                    sx={{
                      minWidth: "420px",
                    }}
                    type='text'
                    autoComplete='off'
                    placeholder='Enter Song Name'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    sx={{
                      userSelect: "none",
                    }}
                    variant='h6'
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Singer</Box>
                  </Typography>
                  <TextField
                    value={singerValue}
                    sx={{
                      minWidth: "420px",
                    }}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerSinger(true);
                      setDrawerArtist(false);
                      setDrawerMusicList(false);
                    }}
                    type='text'
                    autoComplete='off'
                    placeholder='Select Singer'
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Select Music List
                    </Box>
                  </Typography>
                  <TextField
                    value={musicListValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerSinger(false);
                      setDrawerArtist(false);
                      setDrawerMusicList(true);
                    }}
                    sx={{
                      minWidth: "420px",
                    }}
                    placeholder='Select Music List'
                    type='text'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Artist</Box>
                  </Typography>
                  <TextField
                    value={artistListValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerSinger(false);
                      setDrawerArtist(true);
                      setDrawerMusicList(false);
                    }}
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                    placeholder='Select Artist'
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Album</Box>
                  </Typography>
                  <Autocomplete
                    options={albumListArr}
                    value={
                      selectedAlbumList.length > 0
                        ? selectedAlbumList[0]
                        : albumValue
                    }
                    onChange={(event, newValue) => {
                      dispatch(setSelectedAlbum(newValue));
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.album_key === value.album_key;
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(event) => {
                          setAlbumInputVal(event.target.value);
                        }}
                        sx={{
                          width: "420px",
                        }}
                        {...params}
                        label='Select Album'
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Producer</Box>
                  </Typography>
                  <Autocomplete
                    options={producerListArr}
                    value={
                      selectedProducer.length > 0
                        ? selectedProducer[0]
                        : producerValue
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      dispatch(setSelectedProducer(newValue));
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.producer_key === value.producer_key;
                    }}
                    renderInput={(params) => (
                      <TextField
                        key={params.producer_key}
                        onChange={(event) => {
                          setProducerInputVal(event.target.value);
                        }}
                        sx={{
                          width: "420px",
                        }}
                        {...params}
                        label='Select Producer'
                      />
                    )}
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Band</Box>
                  </Typography>
                  <Autocomplete
                    options={bandListArr}
                    value={
                      selectedBand.length > 0 ? selectedBand[0] : bandValue
                    }
                    onChange={(event, newValue) => {
                      dispatch(setSelectedBand(newValue));
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.band_key === value.band_key;
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e) => setBandInputVal(e.target.value)}
                        sx={{
                          width: "420px",
                        }}
                        {...params}
                        label='Select Band'
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Select Recording
                    </Box>
                  </Typography>
                  <Autocomplete
                    options={recordingListArr}
                    value={
                      selectedRecording.length > 0
                        ? selectedRecording[0]
                        : recordingValue
                    }
                    onChange={(event, newValue) => {
                      dispatch(setSelectedRecording(newValue));
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.recording_key === value.recording_key;
                    }}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e) => setRecordInputVal(e.target.value)}
                        sx={{
                          width: "420px",
                        }}
                        {...params}
                        label='Select Recording'
                      />
                    )}
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Start Minute</Box>
                  </Typography>
                  <TextField
                    value={startMinute}
                    sx={{
                      minWidth: "420px",
                    }}
                    type='text'
                    autoComplete='off'
                    placeholder='Enter Start Minute'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Second</Box>
                  </Typography>
                  <TextField
                    value={startSecond}
                    sx={{
                      minWidth: "420px",
                    }}
                    type='text'
                    autoComplete='off'
                    placeholder='Enter Second'
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>End Minute</Box>
                  </Typography>
                  <TextField
                    value={endMinute}
                    sx={{
                      minWidth: "420px",
                    }}
                    type='text'
                    autoComplete='off'
                    placeholder='Enter End Minute'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Second</Box>
                  </Typography>
                  <TextField
                    value={endSecond}
                    sx={{
                      minWidth: "420px",
                    }}
                    type='text'
                    autoComplete='off'
                    placeholder='Enter Second'
                  />
                </Stack>
              </Box>
              <Stack marginTop={3}>
                {image && !updateImage && (
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                )}
              </Stack>
              <Stack marginTop={3}>
                {updateImage && (
                  <Stack direction='column' spaicng={2}>
                    <Box
                      sx={{
                        height: "25vh",
                        width: "100%",
                        backgroundImage: `url(${URL.createObjectURL(
                          updateImage
                        )})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </Stack>
                )}
              </Stack>
              <Box>
                <Typography
                  variant='h6'
                  sx={{
                    userSelect: "none",
                  }}
                >
                  <Box sx={{ fontWeight: "bold", m: 1 }}>Upload Poster</Box>
                </Typography>
                <TextField onChange={imageUploader} fullWidth type='file' />
              </Box>
            </Stack>
            <Box
              sx={{
                margin: "10px 0 0 0",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Stack direction='row' spacing={3}>
                <Button
                  type='button'
                  onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: "#f44336",
                    "&:hover, &:focus": {
                      bgcolor: "#d32f2f",
                    },
                  }}
                  variant='contained'
                >
                  Cancel
                </Button>
                <Button variant='outlined' type='submit'>
                  Update Song
                </Button>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </form>
      <Drawer
        width={"100%"}
        anchor='left'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box width='1980px'>
          <Box
            sx={{
              display: "flex",
            }}
            justifyContent='space-between'
            p={1}
            textAlign='center'
            role='presentation'
          >
            <Box
              sx={{
                display: "flex",
                marginRight: "2rem",
              }}
            >
              <IconButton
                size='large'
                sx={{
                  margin: "0 30px 0 0",
                }}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#ED474A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          {drawerSinger && <Song_Edit_Singer />}
          {drawerMusicList && <Song_Edit_MusicList />}
          {drawerArtist && <Song_Edit_Artist />}
        </Box>
      </Drawer>
    </div>
  );
};

export default Song_List_Edit;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
];
