import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getSingerList } from "../../Singer_List/api/SingerListApi";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSinger } from "../../Singer_List/api/SingerListApiSlice";

const Song_Select_Singer = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [checkInput, setCheckInput] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { selectedSingerList, singerListArray } = useSelector(
    (state) => state.singer_list
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getSingerList({
        admin_id,
        page,
        limit: rowsPerPage,
        singer_name: actVal,
        auth: token,
      })
    );
  }, [actVal]);

  console.log({ singerListArray });
  console.log({ checkInput });
  console.log({ selectedSingerList });

  return (
    <div>
      <Typography variant='h6' marginBottom={4} component='div'>
        Select Singer
      </Typography>
      <Autocomplete
        sx={{
          margin: "60px 500px 300px",
        }}
        multiple
        id='singer-check-box'
        options={singerListArray}
        value={selectedSingerList}
        onChange={(event, newValue) => {
          setCheckInput(newValue);
          dispatch(setSelectedSinger(newValue));
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.singer_key === value.singer_key;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.singer_key}>
              <Checkbox
                icon={icon}
                key={option.singer_key}
                label={option.name}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.name}
            </li>
          );
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            sx={{
              width: "800px",
            }}
            onChange={(event) => {
              setInputVal(event.target.value);
            }}
            {...params}
            label='Singer Checkbox'
            placeholder='Select Singer'
          />
        )}
      />
    </div>
  );
};

export default Song_Select_Singer;
