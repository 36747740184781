import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  Button,
  Paper,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import { updateBand } from "../api/BandApi";
const Band_Update = () => {
  const [updateBandName, setUpdateBandName] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);

  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const band_data = location.state.band_data;

  console.log(band_data);

  useEffect(() => {
    setUpdateBandName(band_data.name);
    band_data.media == null ? setImage() : setImage(band_data.media.media_link);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const updateBandHandler = (e) => {
    e.preventDefault();

    const data = {
      name: updateBandName,
      image: updateImage === undefined ? null : updateImage,
    };

    dispatch(
      updateBand({ admin_id, auth: token, band_key: band_data.band_key, data })
    );
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={updateBandHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Update Band Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Band Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={updateBandName}
              onChange={(e) => setUpdateBandName(e.target.value)}
              required
              fullWidth
            />

            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Box>
              )}
            </Stack>

            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Band Profile</Box>
            </Typography>

            <TextField onChange={imageUploader} type='file' fullWidth />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Update Band
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Band_Update;
