import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

export const getSingerList = createAsyncThunk(
  "singerList/getSingerList",
  async ({ admin_id, page, limit, singer_name, auth }) => {
    console.log({ admin_id, page, limit, singer_name, auth });

    const response = await fetch(
      REAL_API +
        `/singers?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&singer_name=${singer_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
