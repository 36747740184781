import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Button,
  Paper,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addProducer } from "../api/ProducerApi";

const ProducerAdd = () => {
  const [producerName, setProducerName] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [image, setImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const imageUploader = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const addProducerHandler = (e) => {
    e.preventDefault();
    console.log("Clicked");
    dispatch(addProducer({ name: producerName, image, admin_id, auth: token }));
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={addProducerHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Add Producer Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Producer Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={producerName}
              onChange={(e) => setProducerName(e.target.value)}
              required
              fullWidth
            />

            <Stack marginTop={3}>
              {image && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(image)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Producer Profile</Box>
            </Typography>
            <TextField
              required
              onChange={imageUploader}
              type='file'
              fullWidth
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Producer
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default ProducerAdd;
