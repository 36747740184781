import { createAsyncThunk } from "@reduxjs/toolkit";
import { FILE_UPLOAD_API } from "../../../globalApi";
import axios from "axios";

export const uploadLyric = createAsyncThunk(
  "upload/lyric",
  async ({ lyric, auth, data }) => {
    console.log({ lyric, auth, data });
    console.log(FILE_UPLOAD_API + `${lyric}`);
    // const response = await axios.post(FILE_UPLOAD_API + `${lyric}`, data, {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer " + auth,
    //   },
    // });
    // console.log(response);
    // return response.data;
  }
);

export const uploadTrack = createAsyncThunk(
  "upload/lyric",
  async ({ track, data, auth }) => {
    console.log({ track, auth, data });
    console.log(FILE_UPLOAD_API + `${track}`);

    // const response = await axios.post(FILE_UPLOAD_API + `${track}`, data, {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer " + auth,
    //   },
    // });
    // console.log(response);
    // return response.data;
  }
);
