import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Button,
  Paper,
  Stack,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { updateProducer } from "../api/ProducerApi";

const ProducerUpdate = () => {
  const [updateProducerName, setUpdateProducerName] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const producer_data = location.state.producer_data;

  console.log({ producer_data });

  useEffect(() => {
    setUpdateProducerName(producer_data.name);
    setImage(producer_data.media.media_link);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const updateProducerHandler = (e) => {
    e.preventDefault();
    const data = {
      name: updateProducerName,
      image: updateImage == undefined ? null : updateImage,
    };

    dispatch(
      updateProducer({
        admin_id,
        auth: token,
        producer_key: producer_data.producer_key,
        data,
      })
    );
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={updateProducerHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Update Producer Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Producer Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={updateProducerName}
              onChange={(e) => setUpdateProducerName(e.target.value)}
              required
              fullWidth
            />

            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Box>
              )}
            </Stack>

            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Producer Profile</Box>
            </Typography>

            <TextField onChange={imageUploader} type='file' fullWidth />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Update Producer
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
      {/* <Paper
        variant='elevation'
        elevation={3}
        sx={{ p: 10, height: "55vh", width: 550, m: "8% auto" }}
        square
      >
        <IconButton
          sx={{
            margin: "0 auto",
          }}
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant='h4' my={4}>
          Update Producer
        </Typography>

        <Stack spacing={2}>
          <Typography>Enter Update Name</Typography>
          <TextField
            value={updateProducerName}
            onChange={(e) => {
              setUpdateProducerName(e.target.value);
            }}
            placeholder='Enter Producer Name'
            type='text'
            fullWidth
            variant='outlined'
            required
          />
          <input
            value={producer_data.image}
            type='file'
            onChange={imageUploader}
          />

          {image && (
            <Stack direction='column' spacing={2}>
              <Box margin='0 auto'>
                <img
                  width='180px'
                  height='180px'
                  src={URL.createObjectURL(image)}
                  alt='Thumb'
                />
              </Box>
              <Button color='error' onClick={removeSelectedImage}>
                Remove This Image
              </Button>
            </Stack>
          )}

          <Button
            onClick={updateHandler}
            sx={{
              marginTop: "2rem",
            }}
            //   startIcon={< />}
            variant='contained'
            type='submit'
            fullWidth
          >
            Update
          </Button>
        </Stack>
      </Paper> */}
    </div>
  );
};

export default ProducerUpdate;
