import {
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  Tooltip,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Card,
  CardContent,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { getSingerDetail } from "../api/SingerDetailApi";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PianoIcon from "@mui/icons-material/Piano";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import { deleteSinger } from "../api/SingerAddApi";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

const Singer_Detail = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [searchSong, setSearchSong] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { singerDetailSong, singerTotalSongs, singerDetail } = useSelector(
    (state) => state.singer_detail
  );
  const singer_key = location.state.singer_key;

  const titles = [
    { id: "music-title", label: "Music Title", minWidth: 150 },
    { id: "album-name", label: "Album", align: "left", minWidth: 170 },
    { id: "artist-name", label: "Artist", align: "left", minWidth: 170 },
    { id: "group-name", label: "Group", align: "left", minWidth: 170 },
    { id: "contract-type", label: "Contract", align: "left", minWidth: 170 },
    {
      id: "producer-name",
      label: "Producer",
      align: "left",
      minWidth: 170,
    },
    { id: "action", label: "Action", align: "left", minWidth: 170 },
  ];

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setSearchSong(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getSingerDetail({
        admin_id,
        limit: rowsPerPage,
        page: page,
        auth: token,
        singer_key,
        song_name: searchSong,
      })
    );
  }, [page, rowsPerPage, singer_key, searchSong]);

  useEffect(() => {
    if (searchSong.length < 1)
      dispatch(
        getSingerDetail({
          admin_id,
          limit: rowsPerPage,
          page: page,
          auth: token,
          singer_key,
          song_name: searchSong,
        })
      );
  }, [searchSong]);

  const searchSongHandler = (e) => {
    e.preventDefault();
    dispatch(
      getSingerDetail({
        admin_id,
        limit: rowsPerPage,
        page: 0,
        auth: token,
        singer_key,
        song_name: searchSong,
      })
    );
    console.log("Clicked");
  };

  console.log({ singerDetailSong });
  console.log({ singerDetail });

  return (
    <>
      <>
        <Stack alignContent='center' direction='row' spacing={2}>
          <Stack alignItems='center' direction='row' spacing={10}>
            <Stack direction='column' alignItems='center'>
              <Avatar
                alt='singer image'
                src={
                  singerDetail?.media?.media_link
                    ? singerDetail?.media?.media_link
                    : "https://v.totamedia.com/static/media/pngegg.fa07ad29e3475803cc76.png"
                }
                sx={{ width: 180, height: 180 }}
              />
              <Typography variant='h5'>{singerDetail.name}</Typography>
              <Button onClick={() => navigate(-1)} color='info'>
                Back
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </>
      <Box
        component='form'
        sx={{
          margin: "2rem",
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Stack direction='row' spacing={2}>
          <TextField
            width='5rem'
            label='Search Song Name'
            type='text'
            onChange={(e) => setInputVal(e.target.value)}
          />
        </Stack>
      </Box>
      {singerTotalSongs > 0 ? (
        <>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label='song-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align} key={title.id}>
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {singerDetailSong?.data?.length > 0
                    ? singerDetailSong?.data?.map((list) => (
                        <TableRow key={list.song_key}>
                          <TableCell>
                            <Typography variant='body1' component='div'>
                              <Link
                                to='/song-detail'
                                state={{
                                  song_key: list.song_key,
                                }}
                              >
                                {list?.title}
                              </Link>
                            </Typography>
                            <br />
                            <Box
                              sx={{
                                display: "flex",
                                alginItems: "center",
                              }}
                            >
                              <InterpreterModeIcon
                                size='small'
                                sx={{
                                  margin: "0 4px",
                                }}
                              />

                              {list?.singers ? (
                                list?.singers?.length == 1 ? (
                                  <Link
                                    to='/singer-detail'
                                    state={{
                                      singer_key: list?.singers[0].singer_key,
                                    }}
                                  >
                                    {list?.singers[0]?.name}
                                  </Link>
                                ) : (
                                  list?.singers?.map((singer) => (
                                    <Link
                                      to='/singer-detail'
                                      state={{
                                        singer_key: singer.singer_key,
                                      }}
                                      key={singer.singer_key}
                                    >
                                      {singer.name + " ၊ "}
                                    </Link>
                                  ))
                                )
                              ) : (
                                "N/A"
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align='left'>
                            {list?.album == null ? (
                              "N/A"
                            ) : (
                              <Link
                                to='/album-detail'
                                state={{ album_key: list?.album.album_key }}
                              >
                                {list?.album.name}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.artists ? (
                              list?.artists?.length == 1 ? (
                                <Link
                                  to='/artist-detail'
                                  state={{
                                    artist_key: list?.artists[0].artist_key,
                                  }}
                                >
                                  {list?.artists[0]?.name}
                                </Link>
                              ) : (
                                list?.artists?.map((artist) => (
                                  <Link
                                    to='/artist-detail'
                                    state={{
                                      artist_key: artist.artist_key,
                                    }}
                                    key={artist.artist_key}
                                  >
                                    {artist.name + " ၊ "}{" "}
                                  </Link>
                                ))
                              )
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.music_lists === null ? (
                              "N/A"
                            ) : (
                              <LightTooltip
                                placement='right-start'
                                title={
                                  <Box
                                    sx={{
                                      width: "200px",

                                      padding: "20px",
                                    }}
                                  >
                                    <Typography variant='h6'>Group</Typography>
                                    {list?.music_lists?.map((li) => (
                                      <Typography
                                        sx={{
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {li?.name}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <IconButton
                                  sx={{
                                    color: "#0277bd",
                                  }}
                                >
                                  <PianoIcon />
                                </IconButton>
                              </LightTooltip>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.contracts?.audio == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :{" "}
                                        {list.contracts?.audio?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.audio?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <HeadphonesIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.audioKaraoke == null ? (
                              "N/A"
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.audioKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :{" "}
                                        {list.contracts?.audioKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <InterpreterModeIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.video == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :{" "}
                                        {list.contracts?.video?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.video?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <PersonalVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.videoKaraoke == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.videoKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :{" "}
                                        {list.contracts?.videoKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <MusicVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {list?.producer == null ? (
                              "N/A"
                            ) : list?.producer?.length == 1 ? (
                              <Link
                                to='/producer-detail'
                                state={{
                                  producer_key: list?.producer?.producer_key,
                                }}
                              >
                                {list?.producer[0].name}
                              </Link>
                            ) : (
                              <Link
                                to='/producer-detail'
                                state={{
                                  producer_key: list?.producer?.producer_key,
                                }}
                              >
                                {list?.producer.name}
                              </Link>
                            )}
                            {list?.amount > -1 ? (
                              <Tooltip title={list.amount}>
                                <IconButton>
                                  <LocalAtmIcon
                                    sx={{
                                      color: "#fcd975",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                navigate("/song-list/edit", {
                                  state: {
                                    song_detail_data: list,
                                  },
                                })
                              }
                            >
                              <EditIcon color='info' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    : "Songs are coming..."}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20]}
              component='div'
              page={page}
              count={singerTotalSongs}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <Alert fullWidth severity='error'>
          No Song Here!
        </Alert>
        // <Typography
        //   sx={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //   }}
        //   variant='h3'
        //   component='div'
        // >
        //   Songs Comming Soon...
        // </Typography>
      )}
    </>
  );
};

export default Singer_Detail;
