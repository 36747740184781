import {
  Typography,
  Button,
  Paper,
  Stack,
  TextField,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { updateSinger } from "../api/SingerAddApi";

const Singer_Edit = () => {
  const [updateSingerName, setUpdateSingerName] = useState("");
  const [updateSingerNameAlias, setUpdateSingerNameAlias] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const singer_data = location.state.singer_data;

  useEffect(() => {
    setUpdateSingerName(singer_data.name);
    singer_data.alias == null
      ? setUpdateSingerNameAlias()
      : setUpdateSingerNameAlias(singer_data.alias);
    setSelected(singer_data.sex);
    singer_data.media == null
      ? setImage()
      : setImage(singer_data.media.media_link);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const selectHandler = (e) => {
    setSelected(e.target.value);
  };

  const updateSingerHandler = () => {
    const data = {
      name: updateSingerName,
      alias: updateSingerNameAlias,
      media: updateImage == undefined ? null : updateImage,
      sex: selected == singer_data.sex ? singer_data.sex : selected,
    };

    dispatch(
      updateSinger({
        admin_id,
        auth: token,
        singer_key: singer_data.singer_key,
        data,
      })
    );
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={updateSingerHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Update Singer Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Singer Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={updateSingerName}
              onChange={(e) => setUpdateSingerName(e.target.value)}
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Singer Name Alias</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={updateSingerNameAlias}
              onChange={(e) => setUpdateSingerNameAlias(e.target.value)}
              required
              fullWidth
            />
            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>
            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Singer Profile</Box>
            </Typography>
            <TextField
              required
              onChange={imageUploader}
              type='file'
              fullWidth
            />
          </Box>
          <Box>
            <FormControl fullWidth>
              <InputLabel id='singer-gender-select'>Gender</InputLabel>
              <Select
                value={selected}
                onChange={selectHandler}
                required
                labelId='singer-gender-select'
                id='singer-gender-select'
                label='Gender'
              >
                <MenuItem value={0}>Male</MenuItem>
                <MenuItem value={1}>Female</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Singer
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
      {/* <Paper
        variant='elevation'
        elevation={3}
        sx={{ p: 10, height: "100vh", width: 700, m: "8% auto" }}
        square
      >
        <Typography variant='h4' my={4}>
          Update Singer
        </Typography>

        <Stack spacing={2}>
          <Typography>Singer Name</Typography>
          <TextField
            value={updateSingerName}
            onChange={(e) => {
              setUpdateSingerName(e.target.value);
            }}
            placeholder='Enter Singer Name'
            type='text'
            fullWidth
            variant='outlined'
            required
          />
          <Typography>Singer Name Alias</Typography>
          <TextField
            value={updateSingerNameAlias}
            onChange={(e) => {
              setUpdateSingerNameAlias(e.target.value);
            }}
            placeholder='Enter Singe Name Alias'
            type='text'
            fullWidth
            variant='outlined'
            required
          />
          <Stack marginTop={3}>
            {image && !updateImage && (
              <Box
                sx={{
                  height: "25vh",
                  width: "100%",
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            )}
          </Stack>
          <Stack>
            {updateImage && (
              <Stack direction='column' spaicng={2}>
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${URL.createObjectURL(updateImage)})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Stack>
            )}
          </Stack>
          <Typography>Singer Profile</Typography>
          <TextField fullWidth type='file' onChange={imageUploader} />

          <Box>
            <FormControl required fullWidth>
              <InputLabel id='singer-gender-select'>Gender</InputLabel>
              <Select
                value={selected}
                onChange={selectHandler}
                labelId='singer-gender-select'
                id='singer-gender-select'
                label='Gender'
                // onChange={genderHandler}
              >
                <MenuItem value={0}>Male</MenuItem>
                <MenuItem value={1}>Female</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button
            onClick={updateSingerHandler}
            sx={{
              marginTop: "2rem",
            }}
            variant='contained'
            type='submit'
            fullWidth
          >
            Update
          </Button>
        </Stack>
      </Paper> */}
    </div>
  );
};

export default Singer_Edit;
