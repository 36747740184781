import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecordingList } from "../../Recording/api/RecordingApi";
import { setSelectedRecording } from "../../Recording/api/RecordingApiSlice";

const Song_Select_Recording = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [checkInput, setCheckInput] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { recordingListArr, selectedRecording } = useSelector(
    (state) => state.recording_list
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getRecordingList({
        admin_id,
        recording_name: actVal,
        auth: token,
        page,
        limit: rowsPerPage,
      })
    );
  }, [actVal]);

  return (
    <div>
      <Typography variant='h6' marginLeft={3} marginBottom={4} component='div'>
        Select Recording
      </Typography>
      <Autocomplete
        sx={{
          margin: "0 500px 300px",
        }}
        disablePortal
        id='combo-box-demo'
        options={recordingListArr}
        value={selectedRecording[0]}
        onChange={(event, newValue) => {
          setCheckInput(newValue);
          dispatch(setSelectedRecording(newValue));
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.recording_key === value.recording_key;
        }}
        renderInput={(params) => (
          <TextField
            key={params.recording_key}
            onChange={(event) => {
              setInputVal(event.target.value);
            }}
            sx={{
              width: "800px",
            }}
            {...params}
            label='Select Recording'
          />
        )}
      />
    </div>
  );
};

export default Song_Select_Recording;
