import { createSlice, current } from "@reduxjs/toolkit";
import { getSuggest } from "./SongsuggestionApi";

const initialState = {
  suggestData: [],
  error: null,
  suggestInput: null,
};

export const SongApi = createSlice({
  name: "song data",
  initialState,
  reducers: {
    setSuggestDefault: (state) => {
      state.suggestData = [];
    },
    setSuggestInput: (state, { payload }) => {
      state.suggestInput = null;
      state.suggestInput = payload;
    },
    setSuggestInputDefault: (state) => {
      state.suggestInput = null;
    },
    setSongDetailDefault: (state) => {
      state.songDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuggest.fulfilled, (state, { payload }) => {
      state.suggestData = payload;
    });
  },
});

export const {
  setSuggestDefault,
  setSuggestInput,
  setSuggestInputDefault,
  setSongDetailDefault,
} = SongApi.actions;

export default SongApi.reducer;
