import { createSlice } from "@reduxjs/toolkit";
import { getAlbumList } from "./AlbumListApi";

const initialState = {
  albumListAllData: [],
  albumTotal: 1,
  albumListArr: [],
  selectedAlbumList: [],
  loadingAnimation: false,
  selectedAlbumListMultiSelect: [],
};

export const AlbumApi = createSlice({
  name: "album list",
  initialState,
  reducers: {
    setSelectedAlbum: (state, { payload }) => {
      state.selectedAlbumList = [payload];
    },
    setSelectedAlbumDefault: (state) => {
      state.selectedAlbumList = [];
    },
    setSelectedAlbumMultiSelect: (state, { payload }) => {
      state.selectedAlbumListMultiSelect = payload;
    },
    setSelectedAlbumMultiSelectDefault: (state) => {
      state.selectedAlbumListMultiSelect = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAlbumList.fulfilled, (state, { payload }) => {
      state.albumListAllData = payload;
      state.albumTotal = payload.total;
      state.albumListArr = payload.data;
      state.loadingAnimation = true;
    });
  },
});

export const {
  setSelectedAlbum,
  setSelectedAlbumDefault,
  setSelectedAlbumMultiSelect,
  setSelectedAlbumMultiSelectDefault,
} = AlbumApi.actions;

export default AlbumApi.reducer;
