import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

import { onSetValid, localStorageHandler } from "./AuthenticationApiSlice";

export const getRegisterApi = createAsyncThunk(
  "user/getRegisterUser",
  async (data) => {
    console.log(data);
    const response = await fetch(REAL_API + "/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        credentials: "include",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  }
);

export const getLoginApi = createAsyncThunk(
  "user/getLoginApi",
  async (data, { dispatch }) => {
    console.log("Now Login Api");
    const response = await fetch(REAL_API + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        credentials: "include",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log(result);
    // return result;
    if (result.error == true) {
      console.log("Login Failed");
      // dispatch(onSetError(result));
      return result;
    } else if (result.error == false) {
      console.log("Login Success");
      dispatch(onSetValid(result));
      dispatch(localStorageHandler(result));
      return result;
    }
  }
);

// http://192.168.2.103:8008/api/admin/logout?admin_id=640847ab1557a0ef329d8d59
export const getLogoutApi = createAsyncThunk(
  "user/getLogoutApi",
  async ({ admin_id, auth }) => {
    console.log(REAL_API + "/logout?admin_id=" + admin_id);
    console.log({ admin_id, auth });

    const response = await fetch(REAL_API + "/logout?admin_id=" + admin_id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        credentials: "include",
        Authorization: "Bearer " + auth,
      },
    });
    const result = await response.json();
    console.log(result);
    // if (result.error === false) {
    //   console.log("Logout Success");
    //   localStorage.clear();
    //   return result;
    // } else {
    //   console.log("Logout Failed");
    // }
  }
);
