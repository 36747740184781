import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getBandList = createAsyncThunk(
  "bandList/getBandList",
  async ({ admin_id, auth, page, limit, band_name }) => {
    console.log({ admin_id, auth, page, limit, band_name });
    console.log(
      REAL_API +
        `/bands?admin_id=${admin_id}&page=${page}&limit=${limit}&band_name=${band_name}`
    );
    const response = await fetch(
      REAL_API +
        `/bands?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&band_name=${band_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const getBandDetail = createAsyncThunk(
  "bandDetail/getBandDetail",
  async ({ admin_id, auth, band_key }) => {
    console.log({ admin_id, auth, band_key });
    console.log(REAL_API + `/bands/${band_key}?admin_id=${admin_id}`);
    const response = await fetch(
      REAL_API + `/bands/${band_key}?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const deleteBand = createAsyncThunk(
  "band/deleteBand",
  async ({ admin_id, auth, band_key }) => {
    console.log({ admin_id, auth, band_key });
    console.log(REAL_API + `/bands/${band_key}?admin_id=${admin_id}`);
    // const response = await fetch(
    //   REAL_API + `/bands/${band_key}?admin_id=${admin_id}`,
    //   {
    //     method: "DELETE",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       "Access-Control-Allow-Credentials": true,
    //       credentials: "include",
    //       Authorization: `Bearer ${auth}`,
    //     },
    //   }
    // );
    // const result = await response.json();
    // console.log(result);
    // return result;
  }
);

// http://192.168.100.38:8001/api/admin/bands/640847ab1557a0ef329d8d59?admin_id=640847ab1557a0ef329d8d59&_method=PUT

export const updateBand = createAsyncThunk(
  "band/updateBand",
  async ({ admin_id, auth, band_key, data }) => {
    console.log({ data, admin_id, auth, band_key });
    const response = await axios.post(
      REAL_API + `/bands/${band_key}?admin_id=${admin_id}_method=PUT`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const addBand = createAsyncThunk(
  "add Band",
  async ({ admin_id, auth, data }) => {
    console.log({ admin_id, auth, data });
    const response = await axios.post(
      REAL_API + `/bands?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);
