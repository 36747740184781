import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addAlbum } from "../api/AlbumListApi";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Album_Add = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [albumName, setAlbumName] = useState("");
  const [frontCover, setFrontCover] = useState(null);
  const [backCover, setBackCover] = useState(null);
  const [poster, setPoster] = useState(null);
  const [releasedDate, setReleasedDate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const frontCoverUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setFrontCover(e.target.files[0]);
    }
  };

  const backCoverUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setBackCover(e.target.files[0]);
    }
  };

  const posterUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setPoster(e.target.files[0]);
    }
  };

  // Date Format Change Start

  const formattedDate = (date) => {
    const changeDate = new Date(date);
    const offsetMs = changeDate.getTimezoneOffset() * 60 * 1000;
    const adjustedDate = new Date(changeDate.getTime() - offsetMs);
    const formattedDate = adjustedDate.toISOString().slice(0, 10);
    return formattedDate;
  };
  const requiredDateFormat = formattedDate(releasedDate);

  // Date Format Change End
  const addAlbumHandler = (e) => {
    e.preventDefault();
    const data = {
      name: albumName,
      front_cover: frontCover,
      back_cover: backCover,
      poster,
      release_date: requiredDateFormat,
    };
    dispatch(addAlbum({ auth: token, admin_id, data }));
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={addAlbumHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
              }}
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Add Album Form</Box>
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Album Name</Box>
            </Typography>
            <TextField
              value={albumName}
              onChange={(e) => setAlbumName(e.target.value)}
              required
              fullWidth
              autoComplete='off'
            />
            <Stack marginTop={3}>
              {frontCover && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        frontCover
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Front Cover</Box>
            </Typography>
            <TextField
              type='file'
              onChange={frontCoverUploader}
              required
              fullWidth
            />

            <Stack marginTop={3}>
              {backCover && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(backCover)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Back Cover</Box>
            </Typography>
            <TextField
              type='file'
              required
              onChange={backCoverUploader}
              fullWidth
            />

            <Stack marginTop={3}>
              {poster && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(poster)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Poster</Box>
            </Typography>
            <TextField
              onChange={posterUploader}
              type='file'
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontsize: "8px", fontWeight: "bold", m: 1 }}>
                Released Date
              </Box>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={releasedDate}
                onChange={(newValue) => setReleasedDate(newValue)}
                sx={{
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                type='button'
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Album
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Album_Add;
