import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getRecordingList = createAsyncThunk(
  "recordingList/getRecordingList",
  async ({ admin_id, recording_name, auth, page, limit }) => {
    const response = await fetch(
      REAL_API +
        `/recordings?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&recording_name=${recording_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const getRecordingDetail = createAsyncThunk(
  "recordingDetail/getRecordingDetail",
  async ({ admin_id, auth, recording_key }) => {
    console.log({ admin_id, auth, recording_key });
    const response = await fetch(
      REAL_API + `/recordings/${recording_key}?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const deleteRecording = createAsyncThunk(
  "delete/deleteRecording",
  async ({ admin_id, auth, recording_key }) => {
    console.log({ admin_id, auth, recording_key });

    console.log(REAL_API + `/recordings/${recording_key}?admin_id=${admin_id}`);

    const response = await fetch(
      REAL_API + `/recordings/${recording_key}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const addRecording = createAsyncThunk(
  "add Record",
  async ({ admin_id, auth, data }) => {
    console.log({ admin_id, auth, data });

    console.log(REAL_API + `/recordings?admin_id=${admin_id}`);

    const response = await axios.post(
      REAL_API + `/recordings?admin_id=${admin_id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    console.log(response);
    return response.data;
  }
);

export const updateRecording = createAsyncThunk(
  "update Recording",
  async ({ admin_id, auth, recording_key, method, data }) => {
    console.log({ admin_id, auth, method, data });
    const response = await axios.post(
      REAL_API +
        `/recordings/${recording_key}?admin_id=${admin_id}&_method=${method}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    console.log(response);
    return response.data;
  }
);
