import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Stack,
  Button,
  Typography,
  Box,
  IconButton,
  Drawer,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getContract } from "../api/ProducerContractApi";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ProducerContractForm from "./ProducerContractForm";
import dayjs from "dayjs";

const ProducerContract = () => {
  const [contractDate, setContractDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [amount, setAmount] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [displayImg, setDisplayImg] = useState([]);
  const [oPhotoArr, setOPhotoArr] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { selectContractSongTitles, selectContractSongKeys } = useSelector(
    (state) => state.producer_data
  );
  const [audio, setAudio] = useState(false);
  const [audioKaraoke, setAudioKaraoke] = useState(false);
  const [video, setVideo] = useState(false);
  const [videoKaraoke, setVideoKaraoke] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const songTitle = selectContractSongTitles.join(",");
  // const producer_id = location.state.producer_id;

  // for current date

  // console.log(producer_id);
  const currentDate = new Date();
  const currentDateFormat =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1) +
    "-" +
    currentDate.getDate();

  useEffect(() => {
    setContractDate(dayjs(currentDateFormat));
  }, []);
  console.log(currentDateFormat);
  // for current date

  // Contract Date Start

  const formattedDate = (date) => {
    const changeDate = new Date(date).toISOString().substring(0, 10);
    return changeDate;
  };

  const contract = formattedDate(contractDate);
  const expire = formattedDate(expiredDate);

  const date_range = {
    start: contract,
    end: expire,
  };

  const audioContractHandler = (e) => {
    setAudio(e.target.checked);
  };
  const audioKaraokeContractHandler = (e) => {
    setAudioKaraoke(e.target.checked);
  };
  const videoContractHandler = (e) => {
    setVideo(e.target.checked);
  };
  const videoKaraokeContractHandler = (e) => {
    setVideoKaraoke(e.target.checked);
  };

  const audioContract = audio ? date_range : null;
  const audioKaraokeContract = audioKaraoke ? date_range : null;
  const videoContract = video ? date_range : null;
  const videoKaraokeContract = videoKaraoke ? date_range : null;

  // Contract Date End

  // Base64 Format Change Start
  const base64Photo = [];
  useEffect(() => {
    for (let index = 0; index < oPhotoArr.length; index++) {
      const reader = new FileReader();
      reader.onload = function () {
        const imageDataUrl = reader.result;
        // console.log(imageDataUrl);
        base64Photo.push(imageDataUrl);
        console.log(base64Photo);
      };
      reader.readAsDataURL(oPhotoArr[index]);
    }
  }, [oPhotoArr]);

  // Base64 Format Change End

  const imageUploader = (e) => {
    const allFile = e.target.files;
    console.log(allFile);
    setOPhotoArr([...allFile]);
    let url = oPhotoArr.map((item) => URL.createObjectURL(item));
    console.log(url);
    setDisplayImg(url);
  };

  const contractHandler = (e) => {
    e.preventDefault();
    console.log("Contract Done");
    let data = {
      auth: token,
      admin_id,
      song_id: selectContractSongKeys,
      images: base64Photo,
      // producer_key: producer_id,
      amount,
      contracts: {
        audio: audioContract,
        audioKaraoke: audioKaraokeContract,
        video: videoContract,
        videoKaraoke: videoKaraokeContract,
      },
    };
    console.log(data);

    dispatch(
      getContract({
        auth: token,
        admin_id,
        song_id: selectContractSongKeys,
        images: base64Photo,
        // producer_key: producer_id,
        amount,
        contracts: {
          audio: audioContract,
          audioKaraoke: audioKaraokeContract,
          video: videoContract,
          videoKaraoke: videoKaraokeContract,
        },
      })
    );
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={contractHandler}>
        <Grid>
          <Paper
            variant='elevation'
            my={4}
            elevation={3}
            sx={{
              p: 10,
              height: "100%",
              width: 800,
              m: "20px auto",
            }}
            square
          >
            <Grid align='center'>
              <Avatar
                sx={{
                  backgroundColor: "pink",
                  width: "50px",
                  height: "50px",
                }}
              >
                <LibraryMusicIcon color='secondary' />
              </Avatar>
              <Typography variant='h5' my={4}>
                Contract Songs
              </Typography>
            </Grid>
            <Stack spacing={2}>
              <Typography component='div'>Select Song</Typography>
              <Stack direction='row'>
                <TextField
                  // disabled
                  InputProps={{ value: songTitle }}
                  onClick={() => {
                    setIsDrawerOpen(true);
                  }}
                  type='text'
                  fullWidth
                  variant='outlined'
                  required
                />
              </Stack>
              <Stack>
                <Typography>Amount</Typography>
                <TextField
                  type='number'
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Typography marginTop='1rem'>Contract Type</Typography>
                <FormGroup>
                  <Stack direction='row' spacing={2}>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={audioContractHandler}
                      label='Audio'
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={audioKaraokeContractHandler}
                      label='Audio Karaoke'
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={videoContractHandler}
                      label='Video'
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={videoKaraokeContractHandler}
                      label='Video Karaoke'
                    />
                  </Stack>
                </FormGroup>
              </Stack>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} direction='column'>
                  <Typography>Start Date</Typography>
                  <DatePicker
                    label='Contract Date'
                    value={contractDate}
                    onChange={(newVal) => setContractDate(newVal)}
                  />
                  <Typography>End Date</Typography>
                  <DatePicker
                    label='Expired Date'
                    minDate={contractDate}
                    value={expiredDate}
                    onChange={(newVal) => setExpiredDate(newVal)}
                  />
                </Stack>
              </LocalizationProvider>
              <Typography>Contract Images</Typography>
              <input
                type='file'
                accept='image/png, image/jpg, image/jpeg, image/*'
                multiple
                onChange={imageUploader}
              />
            </Stack>

            <Stack direction='row' marginTop={4} spacing={2}>
              {displayImg.map((image) => (
                <img key={image} src={image} width='100px' height='100px' />
              ))}
            </Stack>
            <Box
              sx={{
                marginTop: "2rem",
              }}
            >
              <Button
                startIcon={<HandshakeIcon />}
                variant='contained'
                type='submit'
                fullWidth
              >
                Contract
              </Button>
            </Box>
          </Paper>
        </Grid>
      </form>
      <Drawer
        width={"100%"}
        anchor='left'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box width='1980px'>
          <Box
            sx={{
              display: "flex",
            }}
            justifyContent='space-between'
            p={1}
            textAlign='center'
            role='presentation'
          >
            <Typography variant='h6' component='div'>
              Search Music to Contract.
            </Typography>

            <Box
              sx={{
                display: "flex",
                marginRight: "2rem",
              }}
            >
              <IconButton onClick={() => setIsDrawerOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ProducerContractForm
            // producer_id={producer_id}
            drawerClose={setIsDrawerOpen}
          />
        </Box>
      </Drawer>
    </div>
  );
};

export default ProducerContract;
