import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser } from "../api/UserApi";

const User_Edit = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [updateUserName, setUpdateUserName] = useState("");
  const [updateUserPhone, setUpdateUserPhone] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();

  const navigate = useNavigate(-1);
  const location = useLocation();
  const dispatch = useDispatch();

  const user_data = location.state.user_data;
  console.log({ user_data });

  useEffect(() => {
    setUpdateUserName(user_data.name);
    setUpdateUserPhone(user_data.phone);
    setImage(user_data.profile);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const updateUserHandler = (e) => {
    e.preventDefault();
    const data = {
      name: updateUserName,
      phone: updateUserPhone,
      profile: updateImage === undefined ? null : updateImage,
    };

    dispatch(
      updateUser({ admin_id, auth: token, data, user_id: user_data._id })
    );

    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={updateUserHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Update User Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>User Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={updateUserName}
              onChange={(e) => setUpdateUserName(e.target.value)}
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>User Phone No</Box>
              <TextField
                type='tel'
                autoComplete='off'
                value={updateUserPhone}
                onChange={(e) => setUpdateUserPhone(e.target.value)}
                required
                fullWidth
              />
            </Typography>

            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>

            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>User Profile</Box>
            </Typography>
            <TextField onChange={imageUploader} type='file' fullWidth />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                type='button'
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Update User
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default User_Edit;
