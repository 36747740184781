import { createSlice } from "@reduxjs/toolkit";
import { getArtistList } from "./Artist_List_Api";

const initialState = {
  artistList: [],
  artistListArr: [],
  isLoading: true,
  artistTotal: 1,
  selectedArtistList: [],
  loadingAnimation: false,
};

export const ArtistListApi = createSlice({
  name: "artist list",
  initialState,
  reducers: {
    setSelectedArtist: (state, { payload }) => {
      state.selectedArtistList = payload;
    },
    setSelectedArtistDefault: (state) => {
      state.selectedArtistList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArtistList.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      state.artistList = payload;
      state.artistTotal = payload.total;
      state.artistListArr = payload.data;
      state.loadingAnimation = true;
    });
  },
});

export const { setSelectedArtist, setSelectedArtistDefault } =
  ArtistListApi.actions;

export default ArtistListApi.reducer;
