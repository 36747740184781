import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getProducerList = createAsyncThunk(
  "producerList/getProducerList",
  async ({ admin_id, auth, page, limit, producer_name }) => {
    const response = await fetch(
      REAL_API +
        `/producers?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&producer_name=${producer_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const getProducerDetail = createAsyncThunk(
  "producerDetail/getProducerDetail",
  async ({ admin_id, auth, limit, page, song_name, producer_key }) => {
    console.log(
      REAL_API +
        `/producers/${producer_key}?admin_id=${admin_id}&limit=${limit}&page=${
          page + 1
        }&song_name=`
    );
    const response = await fetch(
      REAL_API +
        `/producers/${producer_key}?admin_id=${admin_id}&limit=${limit}&page=${
          page + 1
        }&song_name=${song_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    return result;
  }
);

export const getProducerContractSong = createAsyncThunk(
  "contract songs/getProducerContractSong",
  async ({ admin_id, auth, filter_name, type, producer_id, page, limit }) => {
    console.log({
      admin_id,
      page,
      limit,
      producer_key: producer_id,
      auth,
      filter_name,
      type,
    });

    console.log(
      REAL_API +
        `/songs?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&producer_id=${producer_id}&filter_name=${filter_name}&type=${type}`
    );

    const response = await fetch(
      REAL_API +
        `/songs?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&producer_id=${producer_id}&filter_name=${filter_name}&type=${type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const addProducer = createAsyncThunk(
  "producers/addProducer",
  async ({ name, image, admin_id, auth }) => {
    console.log({ name, image, admin_id, auth });
    console.log(REAL_API + `/producers?admin_id=${admin_id}`);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);

    for (const [key, value] of formData.entries()) {
      console.log(key + ": " + value);
    }

    const response = await fetch(REAL_API + `/producers?admin_id=${admin_id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth}`,
      },
      body: formData,
    });
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const updateProducer = createAsyncThunk(
  "producers/updateProducer",
  async ({ producer_key, auth, admin_id, data }) => {
    console.log({ producer_key, auth, admin_id, data });
    console.log(
      REAL_API + `/producers/${producer_key}?admin_id=${admin_id}&_method='PUT'`
    );

    const response = await axios.post(
      REAL_API + `/producers/${producer_key}?admin_id=${admin_id}&_method=PUT`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const deleteProducer = createAsyncThunk(
  "delete/deleteProducer",
  async ({ admin_id, auth, producer_key }) => {
    const response = await fetch(
      REAL_API + `/producers/${producer_key}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
