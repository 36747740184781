import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadLyric } from "../api/ContractUploadApi";
import { uploadTrack } from "../api/ContractUploadApi";
import JSZip from "jszip";

const ContractUpload = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [lyric, setLyric] = useState(null);
  const [track1, setTrack1] = useState(null);
  const [track2, setTrack2] = useState(null);
  const [uploadFlag, setUploadFlag] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contract_song_data = location.state.contract_song_data;

  console.log({ contract_song_data });

  // Change lrc file to base 64 format start
  const getLyricBase64 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log({ base64: reader.result });
      setLyric(reader.result);
    };

    // Change lrc file to base 64 format end
  };

  // Change zip file to base 64 format start
  const getTrack1Base64 = async (event) => {
    if (track1 == null) {
      setUploadFlag(true);
      const file = event.target.files[0];
      console.log(file);
      const zip = await JSZip.loadAsync(file);
      const base64 = await zip.generateAsync({ type: "base64" });
      console.log({ base64 });
      setTrack1(base64);
      setUploadFlag(false);
    }
  };
  // Change zip file to base 64 format end

  // Change zip file to base 64 format start
  const getTrack2Base64 = async (event) => {
    if (track2 == null) {
      setUploadFlag(true);
      const file = event.target.files[0];
      console.log(file);
      const zip = await JSZip.loadAsync(file);
      const base64 = await zip.generateAsync({ type: "base64" });
      console.log({ base64 });
      setTrack2(base64);
      setUploadFlag(false);
    }
    // const file = event.target.files[0];

    // console.log(file);

    // const zip = await JSZip.loadAsync(file);
    // const base64 = await zip.generateAsync({ type: "base64" });
    // console.log({ base64 });
    // setTrack2(base64);
  };
  // Change zip file to base 64 format end

  const uploadHandler = (e) => {
    e.preventDefault();
    console.log("Clicked");

    const lyricData = {
      song_key: contract_song_data?.song_key,
      language: "mm",
      lyric,
    };

    const track1Data = {
      song_key: contract_song_data?.song_key,
      language: "mm",
      track_1: track1,
    };

    const track2Data = {
      song_key: contract_song_data?.song_key,
      language: "mm",
      track_2: track2,
    };

    dispatch(uploadLyric({ lyric: "files", auth: token, data: lyricData }));
    dispatch(uploadTrack({ track: "track1", auth: token, data: track1Data }));
    dispatch(uploadTrack({ track: "track2", auth: token, data: track2Data }));
    setUploadFlag(true);
    setTimeout(() => {
      setUploadFlag(false);
    }, 5000);
  };

  return (
    <>
      <Paper
        sx={{
          marginTop: "32px",
          padding: "20px",
        }}
      >
        <Stack spacing={1}>
          <Typography>{contract_song_data?.title}</Typography>

          <Stack spacing={2} direction='row'>
            {contract_song_data?.singers.map((singer) => (
              <Typography key={singer.singer_key} variant='span'>
                {singer.name} ၊{" "}
              </Typography>
            ))}
          </Stack>

          <Typography>Album: {contract_song_data?.album?.name}</Typography>

          <Stack spacing={2} direction='row'>
            {contract_song_data?.mr_file?.track1 == null ? (
              <Typography color='warning.main'>track1 ၊</Typography>
            ) : (
              <Typography color='info.main'>track1 ၊</Typography>
            )}
            {contract_song_data?.mr_file?.track2 == null ? (
              <Typography color='warning.main'> track2 ၊</Typography>
            ) : (
              <Typography color='info.main'> track2 ၊</Typography>
            )}
            {contract_song_data?.mr_file?.lrc == null ? (
              <Typography color='warning.main'> lrc ၊</Typography>
            ) : (
              <Typography color='info.main'> lrc ၊</Typography>
            )}
            {contract_song_data?.mr_file?.zip == null ? (
              <Typography color='warning.main'> zip</Typography>
            ) : (
              <Typography color='info.main'> zip </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>

      <Paper
        sx={{
          marginTop: "32px",
          padding: "20px",
        }}
      >
        <form onSubmit={uploadHandler}>
          <Typography fontSize={20} fontWeight='bold'>
            Lyrics
          </Typography>
          <TextField onChange={getLyricBase64} fullWidth type='file' />
          <Typography my={2} fontSize={20} fontWeight='bold'>
            Music File
          </Typography>
          <TextField onChange={getTrack1Base64} fullWidth type='file' />
          <Typography my={2} fontSize={20} fontWeight='bold'>
            Karaoke
          </Typography>
          <TextField onChange={getTrack2Base64} fullWidth type='file' />
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                type='button'
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>

              {uploadFlag ? (
                <Button disabled type='submit' variant='outlined'>
                  Uploading...
                </Button>
              ) : (
                <Button type='submit' variant='outlined'>
                  Upload
                </Button>
              )}
            </Stack>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ContractUpload;
