import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Stack,
  IconButton,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { getAlbumDetail } from "../api/AlbumDetailApi";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import PianoIcon from "@mui/icons-material/Piano";

const Album_Detail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { albumDetail } = useSelector((state) => state.album_detail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const album_key = location.state.album_key;
  console.log({ album_key });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const titles = [
    { id: "song-title", label: "Track Title" },
    { id: "artist-name", align: "right", label: "Artist" },
    { id: "group", align: "right", label: "Group" },
    { id: "contract", align: "right", label: "Contract" },
    { id: "producer-name", align: "right", label: "Producer" },
    { id: "action", label: "Action", align: "right" },
  ];

  useEffect(() => {
    dispatch(getAlbumDetail({ admin_id, auth: token, album_key }));
  }, []);

  console.log({ albumDetail });

  return (
    <>
      <Box>
        <Paper
          sx={{
            padding: "20px",
            margin: "0 0 30px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              width='100px'
              src={
                albumDetail?.album?.front_cover
                  ? albumDetail?.album.front_cover.media_link
                  : "https://images.unsplash.com/photo-1680199994489-22b5f4ccc620?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
              }
            />
            <Stack marginLeft={4}>
              <Typography
                gutterBottom
                variant='h5'
                component='span'
                color='text.primary'
              >
                {albumDetail?.album?.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InterpreterModeIcon
                  sx={{
                    margin: "0 5px 0 0",
                  }}
                />
                <Box>
                  {albumDetail?.singers?.length == 0
                    ? "N/A"
                    : albumDetail?.singers?.length > 1
                    ? albumDetail?.singers?.map((singer) => (
                        <Link
                          to='/singer-detail'
                          state={{
                            singer_key: singer.singer_key,
                          }}
                          key={singer.singer_key}
                        >
                          <Typography
                            variant='h6'
                            color='palette.secondary.light'
                            component='span'
                          >
                            {(" ", singer.name + " ၊ ")}
                          </Typography>
                        </Link>
                      ))
                    : albumDetail?.singers?.map((singer) => (
                        <Link
                          to='/singer-detail'
                          state={{
                            singer_key: singer.singer_key,
                          }}
                          key={singer.singer_key}
                        >
                          <Typography
                            variant='h6'
                            color='palette.secondary.light'
                            component='span'
                          >
                            {(" ", singer.name)}
                          </Typography>
                        </Link>
                      ))}
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Paper>
      </Box>

      <Paper
        sx={{
          padding: "10px",
        }}
      >
        <Typography>
          <Box
            sx={{
              userSelect: "none",
              fontWeight: "bold",
            }}
          >
            Song Lists
          </Box>
        </Typography>
      </Paper>

      <Paper sx={{ width: "100%", marginTop: "3rem", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label='song-list-table'>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    bgcolor: "#d7ccc8",
                    color: "#424242",
                  },
                }}
              >
                {titles.map((title) => (
                  <TableCell align={title.align} key={title.id}>
                    {title.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {albumDetail?.songs?.map((list) => (
                <TableRow key={list.song_key}>
                  <TableCell>
                    <Link to='/song-detail' state={{ song_key: list.song_key }}>
                      {list.title}
                    </Link>
                    <br />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContents: "center",
                        alignItems: "center",
                      }}
                    >
                      <InterpreterModeIcon
                        sx={{
                          margin: "0 5px 0 0",
                        }}
                      />
                      {list?.singers.length == 0
                        ? "N/A"
                        : list?.singers?.length > 1
                        ? list?.singers.map((singer) => (
                            <Link
                              to='/singer-detail'
                              state={{
                                singer_key: singer.singer_key,
                              }}
                              key={singer.singer_key}
                            >
                              {singer?.name + " ၊ "}
                            </Link>
                          ))
                        : list?.singers.map((singer) => (
                            <Link
                              to='/singer-detail'
                              state={{
                                singer_key: singer.singer_key,
                              }}
                              key={singer.singer_key}
                            >
                              {singer?.name}
                            </Link>
                          ))}
                    </Box>
                  </TableCell>
                  <TableCell align='right'>
                    {list?.artists == null
                      ? "N/A"
                      : list?.artists.length > 0
                      ? list.artists[0].name
                      : "N/A"}
                  </TableCell>
                  <TableCell align='right'>
                    {list?.music_lists === null ? (
                      "N/A"
                    ) : (
                      <LightTooltip
                        placement='right-start'
                        title={
                          <Box
                            sx={{
                              width: "200px",

                              padding: "20px",
                            }}
                          >
                            <Typography variant='h6'>Group</Typography>
                            {list?.music_lists?.map((li) => (
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                              >
                                {li?.name}
                              </Typography>
                            ))}
                          </Box>
                        }
                      >
                        <IconButton
                          sx={{
                            color: "#0277bd",
                          }}
                        >
                          <PianoIcon />
                        </IconButton>
                      </LightTooltip>
                    )}
                  </TableCell>

                  <TableCell align='right'>
                    {list?.contracts?.audio == null ? (
                      ""
                    ) : (
                      <Tooltip
                        title={
                          <Card sx={{ maxWidth: "200px" }}>
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  fontWeight: "bold",
                                }}
                                color='text.secondary'
                                gutterBottom
                              >
                                Audio Contract Timeline
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                Start Date : {list.contracts?.audio?.start}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                End Date : {list.contracts?.audio?.end}
                              </Typography>
                            </CardContent>
                          </Card>
                        }
                      >
                        <IconButton>
                          <HeadphonesIcon
                            sx={{
                              color: "#9FC490",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {list?.contracts?.audioKaraoke == null ? (
                      "N/A"
                    ) : (
                      <Tooltip
                        title={
                          <Card sx={{ minWidth: 150 }}>
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  fontWeight: "bold",
                                }}
                                color='text.secondary'
                                gutterBottom
                              >
                                Audio Karaoke Contract Timeline
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                Start Date :
                                {list.contracts?.audioKaraoke?.start}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                End Date :{list.contracts?.audioKaraoke?.end}
                              </Typography>
                            </CardContent>
                          </Card>
                        }
                      >
                        <IconButton>
                          <InterpreterModeIcon
                            sx={{
                              color: "#9FC490",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {list?.contracts?.video == null ? (
                      ""
                    ) : (
                      <Tooltip
                        title={
                          <Card sx={{ minWidth: 150 }}>
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  fontWeight: "bold",
                                }}
                                color='text.secondary'
                                gutterBottom
                              >
                                Video Contract Timeline
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                Start Date : {list.contracts?.video?.start}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                End Date : {list.contracts?.video?.end}
                              </Typography>
                            </CardContent>
                          </Card>
                        }
                      >
                        <IconButton>
                          <PersonalVideoIcon
                            sx={{
                              color: "#9FC490",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {list?.contracts?.videoKaraoke == null ? (
                      ""
                    ) : (
                      <Tooltip
                        title={
                          <Card sx={{ minWidth: 150 }}>
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  fontWeight: "bold",
                                }}
                                color='text.secondary'
                                gutterBottom
                              >
                                Video Karaoke Contract Timeline
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                Start Date :
                                {list.contracts?.videoKaraoke?.start}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                End Date :{list.contracts?.videoKaraoke?.end}
                              </Typography>
                            </CardContent>
                          </Card>
                        }
                      >
                        <IconButton>
                          <MusicVideoIcon
                            sx={{
                              color: "#9FC490",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell align='right'>
                    {list.producer == null ? (
                      "N/A"
                    ) : (
                      <>
                        {list.producer.name}
                        <Tooltip title={list?.amount ? list.amount : "N/A"}>
                          <IconButton>
                            <LocalAtmIcon
                              sx={{
                                color: "#fcd975",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton
                      onClick={() =>
                        navigate("/song-list/edit", {
                          state: {
                            song_detail_data: list,
                          },
                        })
                      }
                      aria-label='edit album detail song'
                      size='small'
                      color='info'
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Album_Detail;
