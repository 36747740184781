import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducerList } from "../../Producer/api/ProducerApi";
import { setSelectedProducer } from "../../Producer/api/ProducerApiSlice";

const Song_Select_Producer = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [checkInput, setCheckInput] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { producerListArr, selectedProducer } = useSelector(
    (state) => state.producer_data
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getProducerList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        producer_name: actVal,
      })
    );
  }, [actVal]);

  console.log({ producerListArr });
  console.log({ selectedProducer });

  return (
    <div>
      <Typography variant='h6' marginLeft={3} marginBottom={4} component='div'>
        Select Producer
      </Typography>
      <Autocomplete
        sx={{
          margin: "0 500px 300px",
        }}
        disablePortal
        id='combo-box-demo'
        options={producerListArr}
        value={selectedProducer[0]}
        onChange={(event, newValue) => {
          dispatch(setSelectedProducer(newValue));
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.producer_key === value.producer_key;
        }}
        renderInput={(params) => (
          <TextField
            key={params.producer_key}
            onChange={(event) => {
              setInputVal(event.target.value);
            }}
            sx={{
              width: "800px",
            }}
            {...params}
            label='Select Producer'
          />
        )}
      />
    </div>
  );
};

export default Song_Select_Producer;
