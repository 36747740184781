import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducerList } from "../api/ProducerApi";
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Avatar,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import ProducerAdd from "./ProducerAdd";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import { BarLoader } from "react-spinners";

const ProducerList = () => {
  const dispatch = useDispatch();
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [inputVal, setInputVal] = useState("");
  const { producerList, producerTotal, loadingAnimation } = useSelector(
    (state) => state.producer_data
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchProducer, setSearchProducer] = useState("");
  const navigate = useNavigate();

  const titles = [
    { id: "producer-image", label: "Trademark", minWidth: 100 },
    {
      id: "producer-title",
      label: "Producer Name",
      minWidth: 100,
      align: "right",
    },
    {
      id: "producer-action",
      label: "Action",
      minWidth: 100,
      align: "right",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const timeoutInput = setTimeout(() => {
      setSearchProducer(inputVal);
    }, 400);
    return () => clearInterval(timeoutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getProducerList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        producer_name: searchProducer,
      })
    );
  }, [page, rowsPerPage, searchProducer]);

  useEffect(() => {
    if (searchProducer.length < 1)
      dispatch(
        getProducerList({
          admin_id,
          auth: token,
          page,
          limit: rowsPerPage,
          producer_name: searchProducer,
        })
      );
  }, [searchProducer]);

  const searchProducerHandler = (e) => {
    e.preventDefault();
    dispatch(
      getProducerList({
        admin_id,
        auth: token,
        page: 0,
        limit: rowsPerPage,
        producer_name: searchProducer,
      })
    );
  };

  console.log({ producerList, producerTotal });

  return (
    <>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              onSubmit={searchProducerHandler}
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Stack alignItems='center' direction='row' spacing={2}>
                  <Typography component='span'>
                    <Box
                      sx={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Producer List
                    </Box>
                  </Typography>
                  <TextField
                    width='5rem'
                    label='Search Producer Name'
                    type='text'
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </Stack>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    navigate("/producer-list/add");
                  }}
                  aria-label='create-producer'
                  sx={{
                    margin: "0 10rem",
                    width: "200px",
                  }}
                  variant='outlined'
                >
                  Create
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              width: "100%",

              marginTop: "3rem",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='producer-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align} key={title.id}>
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {producerList?.data?.length > 0 ? (
                    producerList?.data?.map((list) => (
                      <TableRow key={list.producer_key}>
                        <TableCell key={list.producer_key}>
                          <Avatar
                            sx={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={
                              list?.media?.media_link
                                ? list.media.media_link
                                : "https://v.totamedia.com/static/media/pngegg.fa07ad29e3475803cc76.png"
                            }
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Link
                            to='/producer-detail'
                            state={{ producer_key: list.producer_key }}
                          >
                            {list?.name}
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          <Stack direction='row' justifyContent='flex-end'>
                            <IconButton
                              onClick={() => {
                                navigate("/producer-list/edit", {
                                  state: { producer_data: list },
                                });
                              }}
                              aria-label='edit producer'
                              size='small'
                              color='info'
                            >
                              <EditIcon />
                            </IconButton>

                            <ConfirmDelete producer_key={list.producer_key} />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Alert fullWidth severity='error'>
                          No Producer Here!
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component='div'
              count={producerTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <>
          <BarLoader width='100%' color='#36d7b7' />
        </>
      )}
    </>
  );
};

export default ProducerList;
