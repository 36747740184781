import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { paperDesign, avatarDesign } from "./ReuseDesign";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getLoginApi } from "../api/AuthenticationApi";

const LoginLog = () => {
  const [errorMesg, setErrorMesg] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailLog, setEmailLog] = useState("");
  const [passwordLog, setPasswordLog] = useState("");
  const [loginReturnData, setLoginReturnData] = useState(null);
  const { error } = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);

  const errorFlagClose = () => {
    setTimeout(() => {
      setErrorFlag(false);
    }, 2000);
  };

  useEffect(() => {
    console.log(error);
    if (error == false) {
      navigate("/");
    } else if (error == true) {
      setErrorFlag(true);
      errorFlagClose();
    }
  }, [error]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let expiredDate = new Date();
  let currentDate = new Date();

  if (checked == false) {
    expiredDate.setDate(currentDate.getDate() + 7);
  } else if (checked == true) {
    expiredDate.setDate(currentDate.getDate() + 30);
  }

  if (loginReturnData) {
    console.log("useState login data:", loginReturnData);
  }

  const loginHandler = (e) => {
    e.preventDefault();
    console.log("Login Clicked.");
    expiredDate = expiredDate.toISOString().slice(0, 19).replace("T", " ");

    const reqLoginData = {
      email: emailLog,
      password: passwordLog,
      expired_date: expiredDate,
    };
    console.log(reqLoginData);
    dispatch(getLoginApi(reqLoginData));
  };

  return (
    <div>
      {errorFlag && (
        <Stack sx={{ width: "400px" }} my={4}>
          <Alert severity='error'>Username or Password is wrong.</Alert>
        </Stack>
      )}
      <form onSubmit={loginHandler}>
        <Grid>
          <Paper
            variant='elevation'
            my={4}
            elevation={10}
            sx={paperDesign}
            square
          >
            <Grid align='center'>
              <Avatar sx={avatarDesign}>
                <LockIcon />
              </Avatar>
              <Typography variant='h5' my={4}>
                System Admin Panel
              </Typography>
            </Grid>
            <Stack spacing={2}>
              <Typography>Email Address</Typography>
              <TextField
                placeholder='Email Address'
                type='email'
                fullWidth
                variant='outlined'
                required
                onChange={(e) => setEmailLog(e.target.value)}
              />

              <Typography>Enter Password</Typography>
              <FormControl sx={{ m: 1 }} variant='outlined' fullWidth>
                <InputLabel htmlFor='outlined-adornment-password'>
                  Password
                </InputLabel>
                <OutlinedInput
                  required
                  id='outlined-adornment-password'
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                  onChange={(e) => setPasswordLog(e.target.value)}
                />
              </FormControl>
            </Stack>
            <Grid
              sx={{
                marginTop: "1rem",
              }}
            >
              <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} />}
                label='Remember Me'
              />
            </Grid>

            <Button
              sx={{
                marginTop: "2rem",
              }}
              startIcon={<LockOpenIcon />}
              variant='contained'
              type='submit'
              fullWidth
            >
              Login
            </Button>
          </Paper>
        </Grid>
      </form>
    </div>
  );
};

export default LoginLog;
