import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addSinger } from "../api/SingerAddApi";

const Singer_Add = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [selected, setSelected] = useState("");
  const [singerName, setSingerName] = useState("");
  const [singerAlias, setSingerAlias] = useState("");
  const [image, setImage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectHandler = (e) => {
    setSelected(e.target.value);
  };
  console.log({ selected });

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const addSingerHandler = (e) => {
    e.preventDefault();

    const data = {
      name: singerName,
      alias: singerAlias,

      media: image,
      sex: selected,
    };

    dispatch(addSinger({ auth: token, admin_id, data }));
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={addSingerHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Add Singer Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Singer Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={singerName}
              onChange={(e) => setSingerName(e.target.value)}
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Singer Name Alias</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={singerAlias}
              onChange={(e) => setSingerAlias(e.target.value)}
              required
              fullWidth
            />
            <Stack marginTop={3}>
              {image && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(image)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Singer Profile</Box>
            </Typography>
            <TextField
              required
              onChange={imageUploader}
              type='file'
              fullWidth
            />
          </Box>
          <Box>
            <FormControl fullWidth>
              <InputLabel id='singer-gender-select'>Gender</InputLabel>
              <Select
                value={selected}
                onChange={selectHandler}
                required
                labelId='singer-gender-select'
                id='singer-gender-select'
                label='Gender'
              >
                <MenuItem value={0}>Male</MenuItem>
                <MenuItem value={1}>Female</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Singer
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Singer_Add;
