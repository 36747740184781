import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAlbumList } from "../../Album_List/api/AlbumListApi";
import { setSelectedAlbum } from "../../Album_List/api/AlbumListApiSlice";

const Song_Select_Album = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [checkInput, setCheckInput] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { albumListArr, selectedAlbumList } = useSelector(
    (state) => state.album_list
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getAlbumList({
        admin_id,
        page,
        limit: rowsPerPage,
        auth: token,
        album_name: actVal,
      })
    );
  }, [actVal]);

  console.log({ albumListArr });
  console.log({ checkInput });
  console.log({ selectedAlbumList });

  return (
    <div>
      <Autocomplete
        sx={{
          margin: "60px 500px 300px",
        }}
        disablePortal
        id='combo-box-demo'
        options={albumListArr}
        value={selectedAlbumList[0]}
        onChange={(event, newValue) => {
          setCheckInput(newValue);
          dispatch(setSelectedAlbum(newValue));
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.album_key === value.album_key;
        }}
        renderInput={(params) => (
          <TextField
            onChange={(event) => {
              setInputVal(event.target.value);
            }}
            sx={{
              width: "800px",
            }}
            {...params}
            label='Slect Album'
          />
        )}
      />
    </div>
  );
};

export default Song_Select_Album;
