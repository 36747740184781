import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getProducerList } from "../../Producer/api/ProducerApi";
import { setSelectedProducer } from "../../Producer/api/ProducerApiSlice";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateContractApi } from "../../Producer/api/ProducerContractApi";

const ContractSongUpdate = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [contractSongName, setContractSongName] = useState("");
  const [updateAmount, setUpdateAmount] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [audioDate, setAudioDate] = useState(null);
  const [audioEndDate, setAudioEndDate] = useState(null);
  const [audioKDate, setAudioKDate] = useState(null);
  const [audioKEndDate, setAudioKEndDate] = useState(null);
  const [videoDate, setVideoDate] = useState(null);
  const [videoEndDate, setVideoEndDate] = useState(null);
  const [videoKDate, setVideoKDate] = useState(null);
  const [videoKEndDate, setVideoKEndDate] = useState(null);
  const [contractFlag, setContractFlag] = useState(false);
  const [currentFlag, setCurrentFlag] = useState(1);
  const [oPhotoArr, setOPhotoArr] = useState([]);
  const [attachments, setAttachments] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const contract_song_data = location.state.contract_song_data;
  const { selectContractSongTitles, selectContractSongKeys } = useSelector(
    (state) => state.producer_data
  );
  const { producerListArr, selectedProducer } = useSelector(
    (state) => state.producer_data
  );

  const audioContract = {
    start: audioDate,
    end: audioEndDate,
  };
  const audioKaraokeContract = {
    start: audioKDate,
    end: audioKEndDate,
  };
  const videoContract = {
    start: videoDate,
    end: videoEndDate,
  };
  const videoKaraokeContract = {
    start: videoKDate,
    end: videoKEndDate,
  };

  const updateContract = {
    audio:
      audioContract.start == null || audioContract.end == null
        ? null
        : audioContract,
    audioKaraoke:
      audioKaraokeContract.start == null || audioKaraokeContract.end == null
        ? null
        : audioKaraokeContract,
    video:
      videoContract.start == null || videoContract.end == null
        ? null
        : videoContract,
    videoKaraoke:
      videoKaraokeContract.start == null || videoKaraokeContract.end == null
        ? null
        : videoKaraokeContract,
  };

  const buttonHandler = (val) => {
    if (val == 1) {
      setCurrentFlag(1);
    } else if (val == 2) {
      setCurrentFlag(2);
    } else if (val == 3) {
      setCurrentFlag(3);
    } else if (val == 4) {
      setCurrentFlag(4);
    }
  };

  useEffect(() => {
    if (audioDate == "" || audioEndDate == "") {
      setAudioDate(null);
      setAudioEndDate(null);
    }
    if (audioKDate == "" || audioKEndDate == "") {
      setAudioKDate(null);
      setAudioKEndDate(null);
    }
    if (videoDate == "" || videoEndDate == "") {
      setVideoDate(null);
      setVideoEndDate(null);
    }
    if (videoKDate == "" || videoKEndDate == "") {
      setVideoKDate(null);
      setVideoKEndDate(null);
    }
  }, [
    audioDate,
    audioEndDate,
    audioKDate,
    audioKEndDate,
    videoDate,
    videoEndDate,
    videoKDate,
    videoKEndDate,
  ]);

  useEffect(() => {
    setContractSongName(contract_song_data.title);
    setUpdateAmount(contract_song_data.amount);
    dispatch(setSelectedProducer(contract_song_data.producer));

    if (contract_song_data.contracts.audio !== null) {
      setAudioDate(contract_song_data.contracts.audio.start);
      setAudioEndDate(contract_song_data.contracts.audio.end);
    }
    if (contract_song_data.contracts.audioKaraoke !== null) {
      setAudioKDate(contract_song_data.contracts.audioKaraoke.start);
      setAudioKEndDate(contract_song_data.contracts.audioKaraoke.end);
    }
    if (contract_song_data.contracts.video !== null) {
      setVideoDate(contract_song_data.contracts.video.start);
      setVideoEndDate(contract_song_data.contracts.video.end);
    }
    if (contract_song_data.contracts.videoKaraoke !== null) {
      setVideoKDate(contract_song_data.contracts.videoKaraoke.start);
      setVideoKEndDate(contract_song_data.contracts.videoKaraoke.end);
    }

    setAttachments(contract_song_data.attachments);
  }, []);

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getProducerList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        producer_name: actVal,
      })
    );
  }, [actVal]);

  //Contract Date Start

  console.log({ contract_song_data });

  // Base64 Format Change Start

  const deleteAttachment = (id) => {
    const newAttachment = attachments.filter((item) => item.id !== id);

    setAttachments(newAttachment);
  };

  const imageUploader = (e) => {
    const allFile = e.target.files;
    console.log(allFile);
    setOPhotoArr([...allFile]);
    let url = oPhotoArr.map((item) => URL.createObjectURL(item));
    // console.log(url);
    // setDisplayImg(url);
  };

  const images = [];
  useEffect(() => {
    for (let index = 0; index < oPhotoArr.length; index++) {
      const reader = new FileReader();
      reader.onload = function () {
        const imageDataUrl = reader.result;
        // console.log(imageDataUrl);
        images.push(imageDataUrl);
        console.log(images);
      };
      reader.readAsDataURL(oPhotoArr[index]);
    }
  }, [oPhotoArr]);

  // Base64 Format Change End

  const updateContractSong = (e) => {
    e.preventDefault();
    dispatch(
      updateContractApi({
        auth: token,
        admin_id,
        song_id: [contract_song_data.song_key],
        producer_key: selectedProducer[0].producer_key,
        amount: updateAmount,
        contracts: updateContract,
        images: images.length > 0 ? images : [],
        attachments,
      })
    );

    // const data = {
    //   auth: token,
    //   admin_id,
    //   song_id: [contract_song_data.song_key],
    //   producer_key: selectedProducer[0].producer_key,
    //   amount: updateAmount,
    //   contracts: updateContract,
    //   images: images.length > 0 ? images : [],
    //   attachments,
    // };

    // console.log({ data });
    navigate(-1);
  };

  // console.log({
  //   audioDate,
  //   audioEndDate,
  //   audioKDate,
  //   audioKEndDate,
  //   videoDate,
  //   videoEndDate,
  //   videoKDate,
  //   videoKEndDate,
  // });

  return (
    <div>
      <form onSubmit={updateContractSong}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 10,
            height: "100%",
            width: 800,
            m: "20px auto",
          }}
          square
        >
          <Typography variant='h5' my={4}>
            Update Contract Song +
          </Typography>
          <Stack spacing={2}>
            <Typography component='div'>Select Song</Typography>
            <Stack direction='row'>
              <TextField
                disabled
                value={contractSongName}
                type='text'
                fullWidth
                variant='outlined'
                required
              />
            </Stack>
            <Typography>Select Producer</Typography>
            <Autocomplete
              sx={{
                margin: "0 500px 300px",
              }}
              disablePortal
              id='combo-box-demo'
              options={producerListArr}
              value={selectedProducer[0]}
              onChange={(event, newValue) => {
                dispatch(setSelectedProducer(newValue));
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.producer_key === value.producer_key
              }
              renderInput={(params) => (
                <TextField
                  onChange={(event) => {
                    setInputVal(event.target.value);
                    console.log({ params });
                  }}
                  fullWidth
                  {...params}
                  label='Select Producer'
                />
              )}
            />
            <Stack>
              <Typography>Amount</Typography>
              <TextField
                type='number'
                value={updateAmount}
                onChange={(e) => setUpdateAmount(e.target.value)}
              />
              <Typography marginTop='1rem'>Contract Type</Typography>

              <Stack direction='row' spacing={2}>
                <Button
                  onClick={() => buttonHandler(1)}
                  startIcon={<HeadphonesIcon />}
                  size='small'
                  variant='filled'
                >
                  audio
                </Button>
                <Button
                  onClick={() => buttonHandler(2)}
                  startIcon={<InterpreterModeIcon />}
                  size='small'
                  variant='filled'
                >
                  audioKaraoke
                </Button>
                <Button
                  onClick={() => buttonHandler(3)}
                  startIcon={<PersonalVideoIcon />}
                  size='small'
                  variant='filled'
                >
                  video
                </Button>
                <Button
                  onClick={() => buttonHandler(4)}
                  startIcon={<MusicVideoIcon />}
                  size='small'
                  variant='filled'
                >
                  videoKaraoke
                </Button>
              </Stack>
            </Stack>

            {currentFlag == 1 && (
              <Paper
                sx={{
                  padding: "10px",
                }}
              >
                <Typography>Audio</Typography>
                <Stack spacing={2}>
                  <Typography>Start Date</Typography>

                  <input
                    value={audioDate}
                    onChange={(e) => setAudioDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />

                  <Typography>End Date</Typography>
                  <input
                    value={audioEndDate}
                    onChange={(e) => setAudioEndDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />
                </Stack>
              </Paper>
            )}
            {currentFlag == 2 && (
              <Paper
                sx={{
                  padding: "10px",
                }}
              >
                <Typography>Audio Karaoke</Typography>
                <Stack spacing={2}>
                  <Typography>Start Date</Typography>

                  <input
                    value={audioKDate}
                    onChange={(e) => setAudioKDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />

                  <Typography>End Date</Typography>
                  <input
                    value={audioKEndDate}
                    onChange={(e) => setAudioKEndDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />
                </Stack>
              </Paper>
            )}
            {currentFlag == 3 && (
              <Paper
                sx={{
                  padding: "10px",
                }}
              >
                <Typography>Video</Typography>
                <Stack spacing={2}>
                  <Typography>Start Date</Typography>

                  <input
                    value={videoDate}
                    onChange={(e) => setVideoDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />

                  <Typography>End Date</Typography>
                  <input
                    value={videoEndDate}
                    onChange={(e) => setVideoEndDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />
                </Stack>
              </Paper>
            )}

            {currentFlag == 4 && (
              <Paper
                sx={{
                  padding: "10px",
                }}
              >
                <Typography>Video Karaoke</Typography>
                <Stack spacing={2}>
                  <Typography>Start Date</Typography>

                  <input
                    value={videoKDate}
                    onChange={(e) => setVideoKDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />
                  <Typography>End Date</Typography>
                  <input
                    value={videoKEndDate}
                    onChange={(e) => setVideoKEndDate(e.target.value)}
                    style={{ padding: "12px", width: "100%" }}
                    type='date'
                  />
                </Stack>
              </Paper>
            )}

            <Typography>Add New Contract Images</Typography>

            <input
              onChange={imageUploader}
              type='file'
              multiple
              accept='image/png, image/jpg, image/jpeg, image/*'
            />
          </Stack>

          {attachments &&
            attachments.map((att) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      console.log(att.id);
                      deleteAttachment(att.id);
                    }}
                  >
                    <DeleteIcon color='error' />
                  </IconButton>
                </Box>

                <Stack marginTop={3}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${att.media_link})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              </>
            ))}

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack my={2} direction='row' spacing={3}>
              <Button
                type='button'
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button
                startIcon={<HandshakeIcon />}
                variant='contained'
                type='submit'
                fullWidth
              >
                Update Contract
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default ContractSongUpdate;
