import { createSlice } from "@reduxjs/toolkit";
import { getMusicList, getMusicListDetail } from "./MusicListApi";

const initialState = {
  musicList: [],
  selectedMusicList: [],
  loadingAnimation: false,
  musicListDetail: [],
  musicListDetailSong: [],
};

export const MusicListApiSlice = createSlice({
  name: "music list",
  initialState,
  reducers: {
    setSelectedMusicList: (state, { payload }) => {
      console.log(payload);
      state.selectedMusicList = payload;
    },
    setSelectedMusicListDefault: (state) => {
      state.selectedMusicList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMusicList.fulfilled, (state, { payload }) => {
      state.musicList = payload.data;
      state.loadingAnimation = true;
    });
    builder.addCase(getMusicListDetail.fulfilled, (state, { payload }) => {
      state.musicListDetail = payload.musiclist;
      state.musicListDetailSong = payload.songs;
    });
  },
});

export const { setSelectedMusicList, setSelectedMusicListDefault } =
  MusicListApiSlice.actions;

export default MusicListApiSlice.reducer;
