import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getBandDetail } from "../api/BandApi";

const Band_Detail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { bandDetail } = useSelector((state) => state.band_list);

  const location = useLocation();
  const dispatch = useDispatch();
  const band_key = location.state.band_key;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBandDetail({ auth: token, admin_id, band_key }));
  }, []);

  console.log(bandDetail);

  return (
    <>
      <Paper
        sx={{
          padding: "20px",
        }}
      >
        <Box
          component='form'
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Stack spacing={2} direction='row' alignItems='center'>
            <img
              width='100px'
              src={
                bandDetail?.media
                  ? bandDetail?.media
                  : "https://images.unsplash.com/photo-1680199994489-22b5f4ccc620?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
              }
            />
            <Typography>{bandDetail?.band?.name}</Typography>
          </Stack>
          <Box>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default Band_Detail;
