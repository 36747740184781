import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { musicListUpdate } from "../api/MusicListApi";

const Music_List_Edit = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [updateMusicList, setUpdateMusicList] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const music_list_data = location.state.music_list_data;
  console.log({ music_list_data });

  useEffect(() => {
    setUpdateMusicList(music_list_data.name);
    setUpdateDescription(music_list_data.description);
    setImage(music_list_data.photo.media_link);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const updateMusicListHandler = (e) => {
    e.preventDefault();
    const data = {
      name: updateMusicList,
      description: updateDescription,
      photo: updateImage === undefined ? null : updateImage,
    };
    console.log(data);
    dispatch(
      musicListUpdate({ admin_id, auth: token, id: music_list_data._id, data })
    );
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={updateMusicListHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
              }}
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Add Music List +</Box>
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Music List Title</Box>
            </Typography>
            <TextField
              value={updateMusicList}
              onChange={(e) => setUpdateMusicList(e.target.value)}
              placeholder='Enter Update Music List Title'
              required
              fullWidth
              autoComplete='off'
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Description</Box>
            </Typography>
            <TextField
              value={updateDescription}
              onChange={(e) => setUpdateDescription(e.target.value)}
              placeholder='Enter Description'
              multiline
              rows={4}
              required
              fullWidth
            />
            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>
            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Music List Cover</Box>
            </Typography>

            <TextField onChange={imageUploader} type='file' fullWidth />
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Update Music List
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Music_List_Edit;
