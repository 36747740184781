import React, { useState, useEffect } from "react";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getArtistList } from "../../Artist/api/Artist_List_Api";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedArtist } from "../../Artist/api/ArtistListApiSlice";

const Song_Select_Artist = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [checkInput, setCheckInput] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { artistListArr, selectedArtistList } = useSelector(
    (state) => state.artist_list
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getArtistList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        filter_name: actVal,
      })
    );
  }, [actVal]);

  console.log({ artistListArr });
  console.log({ checkInput });

  return (
    <div>
      <Typography variant='h6' component='div'>
        Select Artist
      </Typography>
      <Autocomplete
        multiple
        sx={{
          margin: "60px 500px 300px",
        }}
        id='checkboxes-artist'
        value={selectedArtistList}
        options={artistListArr}
        onChange={(event, newValue) => {
          setCheckInput(newValue);
          dispatch(setSelectedArtist(newValue));
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.artist_key === value.artist_key;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.artist_key}>
              <Checkbox
                icon={icon}
                key={option.artist_key}
                label={option.name}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.name}
            </li>
          );
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            sx={{
              width: "800px",
            }}
            onChange={(event) => {
              setInputVal(event.target.value);
              console.log("Input Changes");
            }}
            {...params}
            label='Artist Checkbox'
            placeholder='Select Artist'
          />
        )}
      />
    </div>
  );
};

// const top100Films = [
//   { label: "The Shawshank Redemption", year: 1994 },
//   { label: "The Godfather", year: 1972 },
//   { label: "The Godfather: Part II", year: 1974 },
//   { label: "The Dark Knight", year: 2008 },
//   { label: "12 Angry Men", year: 1957 },
//   { label: "Schindler's List", year: 1993 },
//   { label: "Pulp Fiction", year: 1994 },
//   {
//     label: "The Lord of the Rings: The Return of the King",
//     year: 2003,
//   },
// ];

export default Song_Select_Artist;
