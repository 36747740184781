import { createAsyncThunk } from "@reduxjs/toolkit";

import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getUserList = createAsyncThunk(
  "user list/ get user list",
  async ({ admin_id, page, limit, username, auth }) => {
    const response = await fetch(
      REAL_API +
        `/users?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&username=${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const getUserDetail = createAsyncThunk(
  "user detail/get user detail",
  async ({ admin_id, auth, user_id }) => {
    const response = await fetch(
      REAL_API + `/users/${user_id}?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const addUser = createAsyncThunk(
  "add user",
  async ({ admin_id, auth, data }) => {
    console.log({ admin_id, auth, data });
    const response = await axios.post(
      REAL_API + `/users?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + auth,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    return response.data;
  }
);

// {{localPath}}/api/admin/users/MQ==6440aa153846a$2y$10$SOrkIQhxzitLezsE.JijW.xvwrfUspAQFlyWjUYH9IGxyV5QZ0dam?admin_id={{adminID}}

export const disableUser = createAsyncThunk(
  "disable user",
  async ({ admin_id, auth, user_id }) => {
    console.log({ admin_id, auth, user_id });
    console.log(REAL_API + `/users/${user_id}?admin_id=${admin_id}`);
    const response = await fetch(
      REAL_API + `/users/${user_id}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    // console.log(result);
    return result;
  }
);

export const updateUser = createAsyncThunk(
  "update user",
  async ({ admin_id, auth, data, user_id }) => {
    console.log({ admin_id, auth, data, user_id });
    console.log(
      REAL_API + `/users/${user_id}?admin_id=${admin_id}&_method=PUT`
    );
    const response = await axios.post(
      REAL_API + `/users/${user_id}?admin_id=${admin_id}&_method=PUT`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);
