import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { getSuggest } from "../api/SongsuggestionApi";

import {
  setSuggestDefault,
  setSuggestInput,
  setSuggestInputDefault,
  setSongDetailDefault,
} from "../api/SongApiSlice";
import _ from "lodash";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";

const Search_SearchBar = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const { suggestData } = useSelector((state) => state.suggest);
  const [song, setSong] = useState(false);
  const [singer, setSinger] = useState(false);
  const [album, setAlbum] = useState(false);
  const [producer, setProducer] = useState(false);
  const dispatch = useDispatch();
  const [clearFlag, setClearFlag] = useState(false);
  const navigate = useNavigate();
  const { token, admin_id } = useSelector((state) => state.authentication);

  const songType = !song ? "" : "Song";
  const singerType = !singer ? "" : "Singer";
  const albumType = !album ? "" : "Album";
  const producerType = !producer ? "" : "Producer";

  const DEFAULT_TYPE = "Song,Album,Singer,Producer";

  let type;

  // Type Selector Start

  const songTypeHandler = (e) => {
    setSong(e.target.checked);
  };
  const singerTypeHandler = (e) => {
    setSinger(e.target.checked);
  };
  const albumTypeHandler = (e) => {
    setAlbum(e.target.checked);
  };
  const producerTypeHandler = (e) => {
    setProducer(e.target.checked);
  };

  const typeLength = songType + singerType + albumType + producerType;

  if (typeLength.length < 1) {
    type = DEFAULT_TYPE;
  } else {
    type = [songType, singerType, albumType, producerType];
    const filter = _.compact(type);
    type = filter.join(",");
  }

  // Type Selector End

  // const musicData = JSON.parse(localStorage?.getItem("music_data"));
  // const auth = musicData?.data.auth_token;
  // const id = musicData?.data.id;

  useEffect(() => {
    dispatch(setSongDetailDefault());
  }, []);

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(
    (event) => {
      if (actVal.length > 0) {
        dispatch(setSuggestInput(inputVal));
        setClearFlag(true);
        const data = { auth: token, id: admin_id, input: actVal, type };
        dispatch(getSuggest(data));
      } else {
        dispatch(setSuggestInputDefault());
        setClearFlag(false);
        dispatch(setSuggestDefault());
      }
    },
    [actVal, type]
  );

  const routeHandler = (item) => {
    const keyArr = Object.keys(item);

    if (keyArr.includes("producer_key")) {
      navigate("/producer-detail", {
        state: { producer_id: item.producer_key },
      });
    } else if (keyArr.includes("singer_key")) {
      navigate("/singer-detail", {
        state: {
          singer_key: item.singer_key,
        },
      });
    } else {
      navigate("/album-detail", {
        state: {
          album_key: item.album_key,
        },
      });
    }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <FormGroup>
        <Stack direction='row' spacing={2}>
          <FormControlLabel
            control={<Checkbox onChange={songTypeHandler} />}
            label='Song'
          />
          <FormControlLabel
            control={<Checkbox onChange={singerTypeHandler} />}
            label='Singer'
          />
          <FormControlLabel
            control={<Checkbox onChange={albumTypeHandler} />}
            label='Album'
          />
          <FormControlLabel
            control={<Checkbox onChange={producerTypeHandler} />}
            label='Producer'
          />
        </Stack>
      </FormGroup>

      <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
        <OutlinedInput
          autoFocus
          id='outlined-adornment-password'
          type='text'
          onChange={(e) => {
            setInputVal(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter" && actVal.length > 0) {
              e.preventDefault();
              console.log("Enter Pressed");
              navigate("/suggest");
            }
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  dispatch(setSuggestInputDefault());
                  setClearFlag(false);
                  setInputVal("");
                }}
                aria-label='toggle password visibility'
                edge='end'
              >
                {clearFlag && <ClearIcon size='small' />}
              </IconButton>
            </InputAdornment>
          }
          placeholder='Search'
          value={inputVal}
        />
      </FormControl>

      <List>
        {suggestData?.map((item) => (
          <div>
            <ListItemButton>
              <>
                <Typography component='span' key={item.song_key} variant='h5'>
                  <Link to='/song-detail' state={{ song_key: item.song_key }}>
                    {item.title}
                  </Link>
                </Typography>

                {item?.singers?.length > 1
                  ? item?.singers?.map((singer) => (
                      <Typography
                        variant='h5'
                        key={singer.singer_key}
                        component='span'
                      >
                        <Link
                          to='/singer-detail'
                          state={{
                            singer_key: singer.singer_key,
                          }}
                        >
                          ( {singer.name} ) ၊
                        </Link>
                      </Typography>
                    ))
                  : item?.singers?.map((singer) => (
                      <Typography
                        variant='h5'
                        key={singer.singer_key}
                        component='span'
                      >
                        <Link
                          to='/singer-detail'
                          state={{
                            singer_key: singer.singer_key,
                          }}
                        >
                          ( {singer.name} )
                        </Link>
                      </Typography>
                    ))}
              </>

              <Typography variant='h5' key={item.singer_key} component='span'>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    routeHandler(item);
                  }}
                >
                  {item.name}
                </Link>
              </Typography>
            </ListItemButton>
          </div>
        ))}
      </List>
    </Stack>
  );
};

export default Search_SearchBar;
