import React, { useEffect, useState } from "react";
import { getMusicList } from "../api/MusicListApi";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import { BarLoader } from "react-spinners";

const Music_List = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { musicList, loadingAnimation } = useSelector(
    (state) => state.music_list
  );
  const [musicListDelete, setMusicListDelete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMusicList({ auth: token, admin_id }));
  }, [musicListDelete]);

  console.log({ musicList });

  return (
    <>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Typography component='span'>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Music Lists
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    navigate("/music-list/add");
                  }}
                  aria-label='create-music-list'
                  sx={{
                    width: "150px",
                  }}
                  variant='outlined'
                >
                  Add Music List
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              width: "100%",
              marginTop: "3rem",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='music-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell key={title.id} align={title.align}>
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {musicList?.length > 0 ? (
                    musicList?.map((list) => (
                      <TableRow key={list._id}>
                        <TableCell align='right'>
                          <Avatar
                            sx={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={list?.photo?.media_link}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Link
                            to='/music-list/detail'
                            state={{
                              music_list_key: list._id,
                            }}
                          >
                            {list?.name}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Stack direction='row' justifyContent='flex-end'>
                            <IconButton
                              onClick={() => {
                                navigate("/music-list/edit", {
                                  state: {
                                    music_list_data: list,
                                  },
                                });
                              }}
                            >
                              <EditIcon color='primary' />
                            </IconButton>
                            <ConfirmDelete
                              setMusicListDelete={setMusicListDelete}
                              music_list_key={list._id}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Alert fullWidth severity='error'>
                          No Music List Here!
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </>
  );
};

export default Music_List;

const titles = [
  {
    id: "image",
    label: "Image",
    minWidth: 170,
    // align: "right",
  },
  {
    id: "music-list-title",
    label: "Music List Title",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Music-List-action",
    label: "Action",
    align: "right",
    minWidth: 100,
  },
];
