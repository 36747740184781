import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

// {{localPath}}/api/admin/addcontractsongs?admin_id={{adminID}}
export const getContract = createAsyncThunk(
  "producerContract/getProducerContractSong",
  async ({
    auth,
    admin_id,
    producer_key,
    song_id,
    amount,
    contracts,
    images,
  }) => {
    console.log({
      auth,
      admin_id,
      producer_key,
      song_id,
      amount,
      contracts,
      images,
    });
    console.log(REAL_API + `/addcontractsongs?admin_id=${admin_id}`);
    const response = await fetch(
      REAL_API + `/addcontractsongs?admin_id=${admin_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
        body: JSON.stringify({
          song_id,
          producer_key,
          amount,
          contracts,
          images,
        }),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

// export const deleteContractSong = createAsyncThunk(
//   "delete contract song",
//   async({
//     auth,
//     admin_id,
//   })
// );

export const updateContractApi = createAsyncThunk(
  "update Contract Song",
  async ({
    auth,
    attachments,
    admin_id,
    producer_key,
    song_id,
    amount,
    contracts,
    images,
  }) => {
    console.log({
      attachments,
      auth,
      admin_id,
      producer_key,
      song_id,
      amount,
      contracts,
      images,
    });

    console.log(REAL_API + `/updatecontractsongs?admin_id=${admin_id}`);

    const response = await fetch(
      REAL_API + `/updatecontractsongs?admin_id=${admin_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
        body: JSON.stringify({
          attachments,
          song_id,
          producer_key,
          amount,
          contracts,
          images,
        }),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
