import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Search_SearchBar from "../search_components/Search_SearchBar";
import Search_AppBar from "../search_components/Search_AppBar";

const SearchSong = () => {
  const handleKeyDown = (event) => {
    console.log("User pressed: ", event.key);
  };

  const clickHandler = (event) => {
    event.preventDefault();
    console.log("Clicked");
  };
  return (
    <>
      <Paper
        component='form'
        sx={{
          margin: "3rem auto",
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "70%",
        }}
      >
        <Search_SearchBar />
      </Paper>
    </>
  );
};

export default SearchSong;
