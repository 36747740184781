import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Song_Select_Artist from "../songadd_components/Song_Select_Artist";
import Song_Select_MusicList from "../songadd_components/Song_Select_MusicList";
import Song_Select_Singer from "../songadd_components/Song_Select_Singer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSelectedSingerDefault } from "../../Singer_List/api/SingerListApiSlice";
import { setSelectedArtistDefault } from "../../Artist/api/ArtistListApiSlice";
import { setSelectedMusicListDefault } from "../../Music_List/api/MusicListApiSlice";
import { setSelectedAlbumDefault } from "../../Album_List/api/AlbumListApiSlice";
import { setSeletedProducerDefault } from "../../Producer/api/ProducerApiSlice";
import { setSelectedBandDefault } from "../../Band/api/BandApiSlice";
import { setSelectedRecordingDefault } from "../../Recording/api/RecordingApiSlice";
import Song_Select_Album from "../songadd_components/Song_Select_Album";
import Song_Select_Producer from "../songadd_components/Song_Select_Producer";
import Song_Select_Band from "../songadd_components/Song_Select_Band";
import Song_Select_Recording from "../songadd_components/Song_Select_Recording";
import { addSong } from "../api/SongAddApi";

const Song_Add = () => {
  const [songName, setSongName] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerSinger, setDrawerSinger] = useState(false);
  const [drawerMusicList, setDrawerMusicList] = useState(false);
  const [drawerArtist, setDrawerArtist] = useState(false);
  const [drawerAlbum, setDrawerAlbum] = useState(false);
  const [drawerProducer, setDrawerProducer] = useState(false);
  const [drawerBand, setDrawerBand] = useState(false);
  const [drawerRecording, setDrawerRecording] = useState(false);
  const { selectedSingerList } = useSelector((state) => state.singer_list);
  const { selectedMusicList } = useSelector((state) => state.music_list);
  const { selectedArtistList } = useSelector((state) => state.artist_list);
  const { selectedAlbumList } = useSelector((state) => state.album_list);
  const { selectedProducer } = useSelector((state) => state.producer_data);
  const { selectedBand } = useSelector((state) => state.band_list);
  const { selectedRecording } = useSelector((state) => state.recording_list);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const { token, admin_id } = useSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(setSelectedSingerDefault());
    dispatch(setSelectedArtistDefault());
    dispatch(setSelectedMusicListDefault());
    dispatch(setSelectedAlbumDefault());
    dispatch(setSeletedProducerDefault());
    dispatch(setSelectedBandDefault());
    dispatch(setSelectedRecordingDefault());
  }, []);

  // Changing Singer Format Start
  const requiredSingerFormat = selectedSingerList?.map((singer) => {
    return {
      name: singer?.name,
      singer_key: singer?.singer_key,
    };
  });

  const singerValue = selectedSingerList?.map((item) => {
    return item?.name;
  });
  // console.log(requiredSingerFormat);
  // Changing Singer Format End

  // Changing Music List Format Start

  const musicListValue = selectedMusicList?.map((item) => {
    return item?.name;
  });

  const requiredMusicListFormat = selectedMusicList?.map((item) => {
    return {
      id: item?._id,
      name: item?.name,
    };
  });

  // Changing Music List Format End

  // Changing Artist List Format Start
  const artistListValue = selectedArtistList?.map((item) => {
    return item?.name;
  });

  const requiredArtistListFormat = selectedArtistList?.map((item) => {
    return {
      name: item?.name,
      artist_key: item?.artist_key,
    };
  });
  // Changing Artist List Format End

  // Changing Album List Format Start
  const albumListValue = selectedAlbumList?.map((item) => {
    return item?.name;
  });

  const requiredAlbumListFormat = selectedAlbumList?.map((item) => {
    return {
      name: item?.name,
      album_key: item?.album_key,
    };
  });

  // Changing Album List Format End

  // Changing Producer Format Start

  const producerValue = selectedProducer?.map((item) => {
    return item?.name;
  });

  const requiredProducerFormat = selectedProducer?.map((item) => {
    return {
      name: item?.name,
      producer_key: item?.producer_key,
    };
  });
  // Changing Producer Format End

  // Changing Band Format Start

  const bandValue = selectedBand?.map((item) => {
    return item.name;
  });

  const requiredBandFormat = selectedBand?.map((item) => {
    return {
      name: item.name,
      band_key: item.band_key,
    };
  });

  // Changing Band Format End

  // Changing Recording Format Start
  console.log({ selectedRecording });

  const recordingValue = selectedRecording?.map((item) => {
    return item.name;
  });

  const requiredRecordingFormat = selectedRecording?.map((item) => {
    return {
      name: item.name,
      recording_key: item.recording_key,
    };
  });
  // Changing Recording Format End

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const songAddHandler = (e) => {
    e.preventDefault();
    console.log("Clicked");
    const data = {
      title: songName,
      singers: requiredSingerFormat,
      music_lists: requiredMusicListFormat,
      artists: requiredArtistListFormat,
      album: requiredAlbumListFormat[0],
      producer: requiredProducerFormat[0],
      band: requiredBandFormat[0],
      recording: requiredRecordingFormat[0],
      lyric: null,
      melody: null,
      media: image,
    };

    dispatch(addSong({ admin_id, auth: token, data }));
  };

  return (
    <div>
      <form onSubmit={songAddHandler}>
        <Grid>
          <Paper
            variant='elevation'
            my={4}
            elevation={10}
            sx={{
              p: 10,
              height: "100%",
              width: 1100,
              m: "20px auto",
            }}
            square
          >
            <Typography
              align='center'
              sx={{
                userSelect: "none",
              }}
              variant='h4'
              my={4}
            >
              Add Song
            </Typography>

            <Stack spacing={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    sx={{
                      userSelect: "none",
                    }}
                    variant='h6'
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Song Name</Box>
                  </Typography>
                  <TextField
                    onChange={(e) => setSongName(e.target.value)}
                    autoComplete='off'
                    placeholder='Enter Song Name'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                    required
                    name='title'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    sx={{
                      userSelect: "none",
                    }}
                    variant='h6'
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Singer</Box>
                  </Typography>
                  <TextField
                    value={singerValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerSinger(true);
                      setDrawerMusicList(false);
                      setDrawerArtist(false);
                      setDrawerProducer(false);
                      setDrawerBand(false);
                      setDrawerRecording(false);
                      setDrawerAlbum(false);
                    }}
                    placeholder='Select Singer`'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                    required
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Select Music List
                    </Box>
                  </Typography>
                  <TextField
                    required
                    type='text'
                    value={musicListValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerMusicList(true);
                      setDrawerSinger(false);
                      setDrawerArtist(false);
                      setDrawerProducer(false);
                      setDrawerBand(false);
                      setDrawerRecording(false);
                      setDrawerAlbum(false);
                    }}
                    placeholder='Select Music List'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Artist</Box>
                  </Typography>
                  <TextField
                    required
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerArtist(true);
                      setDrawerSinger(false);
                      setDrawerMusicList(false);
                      setDrawerProducer(false);
                      setDrawerBand(false);
                      setDrawerRecording(false);
                      setDrawerAlbum(false);
                    }}
                    value={artistListValue}
                    placeholder='Select Artist'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Album</Box>
                  </Typography>
                  <TextField
                    required
                    value={albumListValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerAlbum(true);
                      setDrawerSinger(false);
                      setDrawerMusicList(false);
                      setDrawerArtist(false);
                      setDrawerProducer(false);
                      setDrawerBand(false);
                      setDrawerRecording(false);
                    }}
                    placeholder='Select Album'
                    autoComplete='off'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Producer</Box>
                  </Typography>
                  <TextField
                    required
                    value={producerValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerProducer(true);
                      setDrawerSinger(false);
                      setDrawerMusicList(false);
                      setDrawerArtist(false);
                      setDrawerBand(false);
                      setDrawerRecording(false);
                      setDrawerAlbum(false);
                    }}
                    autoComplete='off'
                    placeholder='Select Producer'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Select Band</Box>
                  </Typography>
                  <TextField
                    required
                    value={bandValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerBand(true);
                      setDrawerSinger(false);
                      setDrawerMusicList(false);
                      setDrawerArtist(false);
                      setDrawerProducer(false);
                      setDrawerRecording(false);
                      setDrawerAlbum(false);
                    }}
                    autoComplete='off'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    placeholder='Select Band'
                    variant='outlined'
                  />
                </Stack>
                <Stack spacing={2} direction='column'>
                  <Typography
                    variant='h6'
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Select Recording
                    </Box>
                  </Typography>
                  <TextField
                    required
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerRecording(true);
                      setDrawerSinger(false);
                      setDrawerMusicList(false);
                      setDrawerArtist(false);
                      setDrawerProducer(false);
                      setDrawerBand(false);
                      setDrawerAlbum(false);
                    }}
                    value={recordingValue}
                    autoComplete='off'
                    placeholder='Select Recording'
                    type='text'
                    sx={{
                      width: "420px",
                    }}
                    variant='outlined'
                  />
                </Stack>
              </Box>
              <Stack
                spacing={2}
                direction='column'
                sx={{
                  margin: "30px 0",
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    userSelect: "none",
                  }}
                >
                  <Box sx={{ fontWeight: "bold", m: 1 }}>Upload Poster</Box>
                </Typography>

                <TextField type='file' onChange={imageUploader} />
                {/* <input name='media' type='file' onChange={imageUploader} /> */}
              </Stack>
            </Stack>
            <Box
              sx={{
                margin: "10px 0 0 0",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Stack direction='row' spacing={3}>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: "#f44336",
                    "&:hover, &:focus": {
                      bgcolor: "#d32f2f",
                    },
                  }}
                  variant='contained'
                >
                  Cancel
                </Button>
                <Button variant='outlined' type='submit'>
                  Add
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </form>
      <Drawer
        width={"100%"}
        anchor='left'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box width='1980px'>
          <Box
            sx={{
              display: "flex",
            }}
            justifyContent='space-between'
            p={1}
            textAlign='center'
            role='presentation'
          >
            <Typography variant='h6' component='div'>
              Add Song
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginRight: "2rem",
              }}
            >
              <IconButton
                size='large'
                sx={{
                  margin: "0 30px 0 0",
                }}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#ED474A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {drawerSinger && <Song_Select_Singer />}
          {drawerMusicList && <Song_Select_MusicList />}
          {drawerArtist && <Song_Select_Artist />}
          {drawerAlbum && <Song_Select_Album />}
          {drawerProducer && <Song_Select_Producer />}
          {drawerBand && <Song_Select_Band />}
          {drawerRecording && <Song_Select_Recording />}
        </Box>
      </Drawer>
    </div>
  );
};

export default Song_Add;
