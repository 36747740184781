export const GLOBAL_BASE_API = "http://192.168.100.12:8000/api/admin/";

// export const REAL_API = "http://167.99.67.43/api/admin";
// export const REAL_API = "http://192.168.100.38:8003/api/admin";

export const REAL_API = "https://core.totamedia.com/api/admin";
// export const REAL_API = "http://192.168.100.7:8001/api/admin";
// https://www.totamedia.com/assets/default_songs-f50cff11.png

export const FILE_UPLOAD_API =
  "https://tota.aungthawdar.workers.dev/api/v1/upload/";
