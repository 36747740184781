export const paperDesign = {
  p: 10,
  height: "50vh",
  width: 350,
  m: "20px auto",
};

export const avatarDesign = {
  backgroundColor: "green",
};
