import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { BarLoader } from "react-spinners";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { getUserList } from "../api/UserApi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { disableUser } from "../api/UserApi";
import AddIcon from "@mui/icons-material/Add";

const activeStatus = (
  <div
    style={{
      display: "flex",
      height: "20px",
      marginLeft: "80%",
      width: "20px",
      border: "0.5px solid #00e676",
      backgroundColor: "#00e676",
      borderRadius: "50%",
    }}
  ></div>
);

const deactiveStatus = (
  <div
    style={{
      height: "20px",
      width: "20px",
      marginLeft: "80%",
      border: "0.5px solid #dd2c00",
      backgroundColor: "#dd2c00",
      borderRadius: "50%",
    }}
  ></div>
);

const titles = [
  { id: "user-profile", label: "User Profile", minWidth: 150 },
  { id: "user-name-title", label: "User Name", minWidth: 150 },
  { id: "user-phone", label: "Phone", align: "right", minWidth: 170 },
  { id: "user-version", label: "Version", align: "right", minWidth: 170 },
  { id: "user-status", label: "Status", align: "right", minWidth: 170 },
  { id: "user-action", label: "Action", align: "right", minWidth: 170 },
];

const User_List = () => {
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { userList, userTotal } = useSelector((state) => state.user_list);
  const [disableFlag, setDisableFlag] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      getUserList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        username: actVal,
      })
    );
  }, [page, rowsPerPage, actVal, disableFlag]);

  console.log({ userList, userTotal });

  return (
    <>
      {userList?.data ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Stack alignItems='center' direction='row' spacing={2}>
                  <Typography component='span'>
                    <Box
                      sx={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Users List
                    </Box>
                  </Typography>
                  <TextField
                    width='5rem'
                    label='Search User Name'
                    type='text'
                    onChange={(e) => {
                      setInputVal(e.target.value);
                    }}
                  />
                </Stack>
              </Box>
              <Box>
                <Button
                  onClick={() => navigate("/user-list/add")}
                  type='submit'
                  aria-label='add-song'
                  sx={{
                    margin: "0 10rem",
                    width: "200px",
                  }}
                  variant='outlined'
                  endIcon={<AddIcon />}
                >
                  Add User
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              width: "100%",
              marginTop: "3rem",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='singer-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align} key={title.id}>
                        <Typography
                          variant='body2'
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {title.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList?.data?.length > 0 ? (
                    userList?.data?.map((list) => (
                      <TableRow key={list._id}>
                        <TableCell>
                          {list?.profile == null ? (
                            <Avatar
                              sx={{
                                width: "100px",
                                height: "100px",
                              }}
                              src='https://v.totamedia.com/static/media/pngwing.com%20(1).2e5a2f8f1da62b3b2c6e.png'
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: "100px",
                                height: "100px",
                              }}
                              src={list.profile}
                            />
                          )}
                        </TableCell>
                        <TableCell key={list._id}>
                          <Link
                            to='/user-detail'
                            state={{
                              user_id: list._id,
                            }}
                          >
                            {list.name}
                          </Link>
                        </TableCell>
                        <TableCell align='right'>{list.phone}</TableCell>
                        <TableCell align='right'>
                          {list.version == null ? "N/A" : list.version}
                        </TableCell>
                        <TableCell>
                          {list.status == 1 ? [activeStatus] : deactiveStatus}
                        </TableCell>
                        <TableCell align='right'>
                          <Stack
                            justifyContent='flex-end'
                            direction='row'
                            spacing={2}
                          >
                            <IconButton
                              onClick={() => {
                                navigate("/user-list/edit", {
                                  state: {
                                    user_data: list,
                                  },
                                });
                              }}
                              color='info'
                            >
                              <EditIcon />
                            </IconButton>

                            <Switch
                              value={list._id}
                              checked={list.status == 1 ? false : true}
                              onChange={(event, value) => {
                                dispatch(
                                  disableUser({
                                    admin_id,
                                    auth: token,
                                    user_id: event.target.value,
                                  })
                                );
                                setDisableFlag(!disableFlag);
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Alert fullWidth severity='error'>
                          No User Here!
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component='div'
              count={userTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </>
  );
};

export default User_List;
