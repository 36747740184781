import { createSlice } from "@reduxjs/toolkit";
import { getUserDetail, getUserList } from "./UserApi";

const initialState = {
  userList: [],
  userTotal: 1,
  userDetail: [],
};

export const UserApi = createSlice({
  name: "user data",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.userList = payload;
      state.userTotal = payload.total;
    });
    builder.addCase(getUserDetail.fulfilled, (state, { payload }) => {
      state.userDetail = payload;
    });
  },
});

export default UserApi.reducer;
