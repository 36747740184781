import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Main from "../views/Search/pages/Main";
import Song_List from "../views/Song_List/pages/Song_List";
import Song_Detail from "../views/Song_List/pages/Song_Detail";
import Singer_List from "../views/Singer_List/pages/Singer_List";
import Singer_Detail from "../views/Singer_List/pages/Singer_Detail";
import Singer_Add from "../views/Singer_List/pages/Singer_Add";
import Singer_Edit from "../views/Singer_List/pages/Singer_Edit";
import Album_List from "../views/Album_List/pages/Album_List";
import Album_Detail from "../views/Album_List/pages/Album_Detail";
import Album_Edit from "../views/Album_List/pages/Album_Edit";
import Album_Add from "../views/Album_List/pages/Album_Add";
import Artist_List from "../views/Artist/pages/Artist_List";
import Artist_Detail from "../views/Artist/pages/Artist_Detail";
import Artist_Add from "../views/Artist/pages/Artist_Add";
import Artist_Edit from "../views/Artist/pages/Artist_Edit";
import ProducerList from "../views/Producer/pages/ProducerList";
import ProducerDetail from "../views/Producer/pages/ProducerDetail";
import ProducerContract from "../views/Producer/pages/ProducerContract";
import ProducerAdd from "../views/Producer/pages/ProducerAdd";
import ProducerUpdate from "../views/Producer/pages/ProducerUpdate";
import Band_List from "../views/Band/pages/Band_List";
import Band_Detail from "../views/Band/pages/Band_Detail";
import Band_Add from "../views/Band/pages/Band_Add";
import Band_Update from "../views/Band/pages/Band_Update";
import Recording_List from "../views/Recording/pages/Recording_List";
import Recording_Add from "../views/Recording/pages/Recording_Add";
import Recording_Detail from "../views/Recording/pages/Recording_Detail";
import Recording_Update from "../views/Recording/pages/Recording_Update";
import Song_Add from "../views/Song_List/pages/Song_Add";
import Song_List_Edit from "../views/Song_List/pages/Song_List_Edit";
import ContractList from "../views/Contract/pages/ContractList";
import ContractSong from "../views/Contract/pages/ContractSong";
import ContractUpload from "../views/Contract/pages/ContractUpload";
import User_List from "../views/Users/pages/User_List";
import User_Detail from "../views/Users/pages/User_Detail";
import User_Add from "../views/Users/pages/User_Add";
import User_Edit from "../views/Users/pages/User_Edit";
import Music_List from "../views/Music_List/pages/Music_List";
import Group_List from "../views/Group_List/pages/Group_List";
import Group_List_Add from "../views/Group_List/pages/Group_List_Add";
import Group_List_Edit from "../views/Group_List/pages/Group_List_Edit";
import Music_List_Edit from "../views/Music_List/pages/Music_List_Edit";
import Music_List_Detail from "../views/Music_List/pages/Music_List_Detail";
import Music_List_Add from "../views/Music_List/pages/Music_List_Add";
import ContractSongUpdate from "../views/Contract/pages/ContractSongUpdate";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/main' />} />
      <Route path='/main' element={<Main />} />
      <Route exact path='/song-list' element={<Song_List />} />
      <Route path='/song-detail' element={<Song_Detail />} />
      <Route path='/song-list/add' element={<Song_Add />} />
      <Route path='/song-list/edit' element={<Song_List_Edit />} />
      <Route exact path='/singer-list' element={<Singer_List />} />
      <Route path='/singer-detail' element={<Singer_Detail />} />
      <Route path='/singer-list/add' element={<Singer_Add />} />
      <Route path='/singer-list/edit' element={<Singer_Edit />} />
      <Route path='/album-list' element={<Album_List />} />
      <Route path='/album-detail' element={<Album_Detail />} />
      <Route path='/album-list/edit' element={<Album_Edit />} />
      <Route path='/album-list/add' element={<Album_Add />} />
      <Route path='/artist-list' element={<Artist_List />} />
      <Route path='/artist-detail' element={<Artist_Detail />} />
      <Route path='artist-list/add' element={<Artist_Add />} />
      <Route path='artist-list/edit' element={<Artist_Edit />} />
      <Route path='/producer-list' element={<ProducerList />} />
      <Route path='/producer-list/add' element={<ProducerAdd />} />
      <Route path='/producer-list/edit' element={<ProducerUpdate />} />
      <Route path='/producer-detail' element={<ProducerDetail />} />
      <Route path='/producer-detail/contract' element={<ProducerContract />} />
      <Route path='/band-list' element={<Band_List />} />
      <Route path='/band-detail' element={<Band_Detail />} />
      <Route path='/band-list/edit' element={<Band_Update />} />
      <Route path='/band-list/add' element={<Band_Add />} />
      <Route path='/recording-list' element={<Recording_List />} />
      <Route path='/recording-detail' element={<Recording_Detail />} />
      <Route path='/recording-list/add' element={<Recording_Add />} />
      <Route path='/recording-list/edit' element={<Recording_Update />} />
      <Route path='/contract/song-list' element={<ContractList />} />
      <Route path='/contract/contract-song' element={<ContractSong />} />
      <Route
        path='/contract/update-song-list'
        element={<ContractSongUpdate />}
      />
      <Route
        path='/contract/contract-song/contract-upload'
        element={<ContractUpload />}
      />
      <Route path='/user-list' element={<User_List />} />
      <Route path='/user-detail' element={<User_Detail />} />
      <Route path='/user-list/add' element={<User_Add />} />
      <Route path='/user-list/edit' element={<User_Edit />} />
      <Route path='/music-list' element={<Music_List />} />
      <Route path='/music-list/detail' element={<Music_List_Detail />} />
      <Route path='/music-list/add' element={<Music_List_Add />} />
      <Route path='/music-list/edit' element={<Music_List_Edit />} />
      <Route path='/group-list' element={<Group_List />} />
      <Route path='/group-list/add' element={<Group_List_Add />} />
      <Route path='/group-list/edit' element={<Group_List_Edit />} />
      <Route path='/*' element={<Navigate to='/main' />} />
    </Routes>
  );
};

export default PrivateRoutes;

// element={<ProducerContract />}
