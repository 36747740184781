import {
  Box,
  Button,
  Paper,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { updateAlbum } from "../api/AlbumListApi";

const Album_Edit = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [updateAlbumName, setUpdateAlbumName] = useState("");
  const [front, setFront] = useState(null);
  const [frontCover, setFrontCover] = useState();
  const [back, setBack] = useState(null);
  const [backCover, setBackCover] = useState();
  const [post, setPost] = useState(null);
  const [poster, setPoster] = useState();
  const [releasedDate, setReleasedDate] = useState(null);
  const [attachDate, setAttachDate] = useState("");
  const [dateFlag, setDateFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const album_data = location.state.album_data;

  useEffect(() => {
    setUpdateAlbumName(album_data?.name);

    if (album_data?.front_cover?.media_link)
      setFront(album_data?.front_cover.media_link);

    if (album_data?.back_cover?.media_link)
      setBack(album_data?.back_cover?.media_link);

    if (album_data?.poster?.media_link) setPost(album_data?.poster.media_link);
    setAttachDate(album_data?.release_date);
  }, []);

  const frontCoverUploader = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFrontCover(e.target.files[0]);
    }
  };

  const backCoverUploader = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setBackCover(e.target.files[0]);
    }
  };

  const posterUploader = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPoster(e.target.files[0]);
    }
  };

  // Date Format Change Start

  const formattedDate = (date) => {
    const changeDate = new Date(date);
    const offsetMs = changeDate.getTimezoneOffset() * 60 * 1000;
    const adjustedDate = new Date(changeDate.getTime() - offsetMs);
    const formattedDate = adjustedDate.toISOString().slice(0, 10);
    return formattedDate;
  };
  const requiredDateFormat = formattedDate(releasedDate);

  // Date Format Change End

  const updateAlbumHandler = (e) => {
    e.preventDefault();

    const data = {
      name: updateAlbumName,
      front_cover: frontCover === undefined ? null : frontCover,
      back_cover: backCover === undefined ? null : backCover,
      poster: poster === undefined ? null : poster,
      release_date: dateFlag ? requiredDateFormat : attachDate,
    };

    dispatch(
      updateAlbum({
        admin_id,
        auth: token,
        data,
        album_id: album_data.album_key,
        method: "PUT",
      })
    );

    navigate(-1);
  };

  console.log({ album_data });
  return (
    <div>
      <form onSubmit={updateAlbumHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
              }}
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Update Album Form</Box>
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Album Name</Box>
            </Typography>
            <TextField
              value={updateAlbumName}
              onChange={(e) => setUpdateAlbumName(e.target.value)}
              required
              fullWidth
              autoComplete='off'
            />
            <Stack marginTop={3}>
              {front && !frontCover && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${front})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>

            <Stack marginTop={3}>
              {frontCover && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        frontCover
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Front Cover</Box>
            </Typography>
            <TextField type='file' onChange={frontCoverUploader} fullWidth />

            <Stack marginTop={3}>
              {back && !backCover && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${back})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Box>
              )}
            </Stack>

            <Stack marginTop={3}>
              {backCover && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(backCover)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Back Cover</Box>
            </Typography>
            <TextField type='file' onChange={backCoverUploader} fullWidth />

            <Stack marginTop={3}>
              {post && !poster && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${post})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Box>
              )}
            </Stack>

            <Stack marginTop={3}>
              {poster && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(poster)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Poster</Box>
            </Typography>
            <TextField onChange={posterUploader} type='file' fullWidth />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontsize: "8px", fontWeight: "bold", m: 1 }}>
                Released Date
              </Box>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={releasedDate}
                onChange={(newValue) => {
                  setReleasedDate(newValue);
                  setDateFlag(true);
                }}
                sx={{
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Album
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Album_Edit;
