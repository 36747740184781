import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAlbumList } from "../api/AlbumListApi";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import { BarLoader } from "react-spinners";

import {
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const Album_List = () => {
  const [albumName, setAlbumName] = useState("");
  const [inputVal, setInputVal] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { albumListAllData, albumTotal, loadingAnimation } = useSelector(
    (state) => state.album_list
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteAlbum, setDeleteAlbum] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const timeoutInput = setTimeout(() => {
      setAlbumName(inputVal);
    }, 400);
    return () => clearInterval(timeoutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getAlbumList({
        admin_id,
        page: page,
        limit: rowsPerPage,
        auth: token,
        album_name: albumName,
      })
    );
  }, [page, rowsPerPage, deleteAlbum, albumName]);

  useEffect(() => {
    if (albumName.length < 1)
      dispatch(
        getAlbumList({
          auth: token,
          admin_id,
          page,
          limit: rowsPerPage,
          album_name: albumName,
        })
      );
  }, [albumName]);

  console.log({ albumListAllData });

  return (
    <div>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Stack alignItems='center' direction='row' spacing={2}>
                  <Typography component='span'>
                    <Box
                      sx={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Album List
                    </Box>
                  </Typography>
                  <TextField
                    width='5rem'
                    label='Search Album Name'
                    type='text'
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </Stack>
              </Box>
              <Box>
                <Button
                  onClick={() => navigate("/album-list/add")}
                  type='submit'
                  aria-label='add-album'
                  sx={{
                    margin: "0 10rem",
                    width: "200px",
                  }}
                  variant='outlined'
                  endIcon={<AddIcon />}
                >
                  Add Album
                </Button>
              </Box>
            </Box>
          </Paper>

          <Paper
            sx={{
              width: "100%",
              marginTop: "3rem",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='album-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align} key={title.id}>
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {albumListAllData?.data?.length > 0 ? (
                    albumListAllData?.data?.map((list) => (
                      <TableRow key={list.album_key}>
                        <TableCell key={list.album_key}>
                          <Avatar
                            sx={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={
                              list?.front_cover == null
                                ? "https://v.totamedia.com/static/media/pngegg%20(1).bf6eca7e0232e0f9baf1.png"
                                : list?.front_cover?.media_link
                            }
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Link
                            to='/album-detail'
                            state={{ album_key: list.album_key }}
                          >
                            <Typography variant='h6'>{list?.name}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          <Stack justifyContent='flex-end' direction='row'>
                            <IconButton
                              onClick={() => {
                                navigate("/album-list/edit", {
                                  state: { album_data: list },
                                });
                              }}
                              aria-label='edit producer'
                              size='small'
                              color='info'
                            >
                              <EditIcon />
                            </IconButton>

                            <ConfirmDelete
                              setDeleteAlbum={setDeleteAlbum}
                              album_key={list.album_key}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Alert fullWidth severity='error'>
                          No Album Here!
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={albumTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </div>
  );
};

export default Album_List;

const titles = [
  { id: "album-poster", label: "Album Poster", minWidth: 150 },
  { id: "album-name", label: "Album Name", align: "right", minWidth: 170 },
  { id: "album-action", label: "Action", align: "right", minWidth: 170 },
];
