import { createSlice, current } from "@reduxjs/toolkit";
import { getLoginApi, getLogoutApi, getRegisterApi } from "./AuthenticationApi";

const initialState = {
  token: null,
  error: null,
  admin_id: null,
  user_data: [],
};

export const AuthenticationApi = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    onSetValid: (state, { payload }) => {
      state.token = payload.data.auth_token;
      state.admin_id = payload.data._id;
      state.user_data = payload.data;
    },
    localStorageHandler: (state, { payload }) => {
      localStorage.setItem("music_data", JSON.stringify(payload));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegisterApi.fulfilled, (action) => {
      console.log("Register Successfully.");
    });
    builder.addCase(getLoginApi.fulfilled, (state, { payload }) => {
      console.log("LoginApi Fullfilled.");
      state.error = payload.error;
    });
    builder.addCase(getLogoutApi.fulfilled, (state, { payload }) => {
      console.log("Logout Fulfilled.");
      state.token = null;
      state.error = null;
      state.admin_id = null;
    });
  },
});

export const { onSetValid, localStorageHandler, onSetError } =
  AuthenticationApi.actions;

export default AuthenticationApi.reducer;
