import { createSlice } from "@reduxjs/toolkit";
import { getSingerDetail, getSingerList } from "./SingerListApi";

const initialState = {
  singerListArray: [],
  selectedSingerList: [],
  singerList: [],
  singerTotal: 1,
  singerDetail: [],
};

export const SingerApi = createSlice({
  name: "singer data",
  initialState,
  reducers: {
    setSelectedSinger: (state, { payload }) => {
      state.selectedSingerList = payload;
    },
    setSelectedSingerDefault: (state, { payload }) => {
      state.selectedSingerList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingerList.fulfilled, (state, { payload }) => {
      state.singerList = payload;
      state.singerTotal = payload.total;
      state.singerListArray = payload.data;
    });
  },
});

export const { setSelectedSinger, setSelectedSingerDefault } =
  SingerApi.actions;

export default SingerApi.reducer;
