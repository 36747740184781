import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSongDetail } from "../api/SongListApi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Paper, Stack } from "@mui/material";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import { BarLoader } from "react-spinners";

const Song_Detail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const song_key = location.state.song_key;
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { songDetail, isLoading } = useSelector((state) => state.song_list);

  useEffect(() => {
    dispatch(getSongDetail({ auth: token, admin_id, song_key }));
  }, []);

  console.log(songDetail);

  console.log("Token:", token, "Id:", admin_id, "Song Key:", song_key);

  return (
    <>
      {!isLoading && <BarLoader width='100%' color='#36d7b7' />}

      {Object.keys(songDetail).length > 0 && (
        <Box>
          <Paper
            sx={{
              width: "1000px",
              padding: "10px",
              margin: "60px auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack direction='row'>
                <img
                  width='100px'
                  src='https://images.unsplash.com/photo-1680199994489-22b5f4ccc620?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
                />
                {Object.keys(songDetail).length > 0 && (
                  <Stack marginLeft={4}>
                    <Typography>{songDetail?.title}</Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InterpreterModeIcon
                        sx={{
                          margin: "0 5px 0 0",
                        }}
                      />
                      <Typography component='div'>
                        {songDetail?.singers
                          ? songDetail?.singers.length == 1
                            ? songDetail?.singers?.map((singer) => (
                                <Link
                                  key={singer.singer_key}
                                  to='/singer-detail'
                                  state={{ singer_key: singer?.singer_key }}
                                >
                                  {singer.name}
                                </Link>
                              ))
                            : songDetail?.singers?.map((singer) => (
                                <Link
                                  key={singer.singer_key}
                                  to='/singer-detail'
                                  state={{ singer_key: singer?.singer_key }}
                                >
                                  {singer.name + " ၊ "}
                                </Link>
                              ))
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </Stack>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Box>
          </Paper>
        </Box>
      )}

      {isLoading && Object.keys(songDetail).length > 0 && (
        <Box>
          <Paper
            sx={{
              width: "1000px",
              padding: "10px",
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Stack
                sx={
                  {
                    // marginRight: "10rem",
                  }
                }
              >
                <Typography variant='h6' gutterBottom>
                  Album
                </Typography>

                <img
                  width='200px'
                  src={
                    songDetail?.media?.media_link
                      ? songDetail?.media?.media_link
                      : "https://images.unsplash.com/photo-1680199994489-22b5f4ccc620?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  }
                />

                <Typography marginTop={3} variant='h5' component='div'>
                  {songDetail?.album == null ? (
                    "N/A"
                  ) : (
                    <Link
                      to='/album-detail'
                      state={{ album_key: songDetail?.album.album_key }}
                    >
                      {songDetail?.album?.name}
                    </Link>
                  )}
                </Typography>
              </Stack>
              <Stack spacing={3}>
                <Typography>
                  Band :{" "}
                  {songDetail?.band == null ? (
                    "N/A"
                  ) : (
                    <Link>{songDetail?.band.name}</Link>
                  )}
                </Typography>
                <Typography>
                  Recording :{" "}
                  {songDetail?.recording == null ? (
                    "N/A"
                  ) : (
                    <Link>{songDetail?.recording.name}</Link>
                  )}
                </Typography>
                <Typography>
                  Artist :
                  {songDetail?.artists == null
                    ? "N/A"
                    : songDetail?.artists?.length == 1
                    ? songDetail?.artists?.map((artist) => (
                        <Link
                          key={artist.artist_key}
                          to='/artist-detail'
                          state={{ artist_key: artist?.artist_key }}
                        >
                          {" "}
                          {artist.name}
                        </Link>
                      ))
                    : songDetail?.artists?.map((artist) => (
                        <Link
                          key={artist.artist_key}
                          to='/artist-detail'
                          state={{ artist_key: artist?.artist_key }}
                        >
                          {" "}
                          {artist.name + " ၊ "}
                        </Link>
                      ))}
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default Song_Detail;
