import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getGroupList } from "../api/Group_List_Api";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import ListIcon from "@mui/icons-material/List";
import { BarLoader } from "react-spinners";
import AddIcon from "@mui/icons-material/Add";
import { setSelectedMusicListDefault } from "../../Music_List/api/MusicListApiSlice";

const Group_List = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { groupList, loadingAnimation } = useSelector(
    (state) => state.group_list
  );
  const [groupListDelete, setGroupListDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedMusicListDefault());
  }, []);

  useEffect(() => {
    dispatch(getGroupList({ admin_id, auth: token }));
  }, [groupListDelete]);

  console.log({ groupList });

  return (
    <>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Typography component='span'>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Group Lists
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    navigate("/group-list/add");
                  }}
                  aria-label='create-group-list'
                  sx={{
                    width: "150px",
                  }}
                  variant='outlined'
                >
                  Add Group <AddIcon />
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              width: "100%",
              marginTop: "3rem",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='group-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align}>{title.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupList?.length > 0
                    ? groupList?.map((list) => (
                        <TableRow key={list._id}>
                          <TableCell>
                            <Avatar
                              sx={{
                                width: "100px",
                                height: "100px",
                              }}
                              src={list?.group_image?.media_link}
                            />
                          </TableCell>
                          <TableCell align='right'>{list.group_name}</TableCell>
                          <TableCell align='right'>{list.group_type}</TableCell>
                          <TableCell align='right'>
                            <Tooltip
                              placement='right-start'
                              title={
                                <ul>
                                  {list.musiclists.map((li) => (
                                    <li>{li.name}</li>
                                  ))}
                                </ul>
                              }
                            >
                              <IconButton>
                                <ListIcon
                                  sx={{
                                    color: "#2196f3",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Stack direction='row' justifyContent='flex-end'>
                              <IconButton
                                onClick={() =>
                                  navigate("/group-list/edit", {
                                    state: {
                                      group_list_data: list,
                                    },
                                  })
                                }
                              >
                                <EditIcon color='primary' />
                              </IconButton>
                              <ConfirmDelete
                                setGroupListDelete={setGroupListDelete}
                                group_id={list._id}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    : groupList?.map((list) => (
                        <TableRow key={list._id}>
                          <TableCell colSpan={5}>
                            <Alert fullWidth severity='error'>
                              No Group List Here!
                            </Alert>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </>
  );
};

export default Group_List;

const titles = [
  {
    id: "image",
    label: "Image",
    minWidth: 170,
  },
  {
    id: "group-name",
    label: "Group Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Music-List",
    label: "Music List",
    align: "right",
    minWidth: 170,
  },
  {
    id: "action",
    label: "Action ",
    align: "right",
    minWidth: 100,
  },
];
