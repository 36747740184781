import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

export const getSingerDetail = createAsyncThunk(
  "singerDetail/getSingerDetail",
  async ({ admin_id, limit, page, song_name, singer_key, auth }) => {
    console.log({ admin_id, limit, page, song_name, auth, singer_key });

    console.log(
      REAL_API +
        `/singers/${singer_key}?admin_id=${admin_id}&limit=${limit}&page=${
          page + 1
        }&song_name=${song_name}`
    );

    // http://167.99.67.43/api/admin/singers/R5BGCLQEFN47UWN3Y306H?admin_id=22&limit=10&page=1&song_name=

    const response = await fetch(
      REAL_API +
        `/singers/${singer_key}?admin_id=${admin_id}&limit=${limit}&page=${
          page + 1
        }&song_name=${song_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
