import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getProducerDetail } from "../api/ProducerApi";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-material-ui-carousel";
import {
  Alert,
  Drawer,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";
import EditIcon from "@mui/icons-material/Edit";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import PianoIcon from "@mui/icons-material/Piano";

const ProducerDetail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [inputVal, setInputVal] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [attachment, setAttachment] = useState([]);

  const {
    producerDetail,
    producerDetailSongs,
    producerDetailTotalSong,
    producerId,
  } = useSelector((state) => state.producer_data);

  const [searchSong, setSearchSong] = useState("");
  const producer_key = location.state.producer_key;

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const titles = [
    { id: "song-title", label: "Song Title", minWidth: 170 },
    // { id: "singer-name", label: "Singer Name", minWidth: 170, align: "left" },
    { id: "album-name", label: "Album Title", align: "left", minWidth: 170 },
    { id: "artist", label: "Artist", align: "left", minWidth: 170 },
    { id: "group", label: "Group", align: "left", minWidth: 170 },
    { id: "contract", label: "Contract", align: "left", minWidth: 170 },
    {
      id: "contract-action",
      label: "Contract Action",
      align: "left",
      minWidth: 170,
    },
  ];

  console.log({ producerDetailSongs });
  console.log({ producerDetail });

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setSearchSong(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getProducerDetail({
        auth: token,
        admin_id,
        producer_key,
        page,
        limit: rowsPerPage,
        song_name: searchSong,
      })
    );
  }, [page, rowsPerPage, searchSong]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (searchSong.length < 1)
      dispatch(
        getProducerDetail({
          auth: token,
          admin_id,
          producer_key,
          page,
          limit: rowsPerPage,
          song_name: searchSong,
        })
      );
  }, [searchSong]);

  return (
    <div>
      <Box>
        <Paper
          sx={{
            padding: "20px",
            margin: "0 0 30px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              width='100px'
              src={producerDetail?.producer?.media?.media_link}
            />
            <Stack marginLeft={4}>
              <Typography
                component='span'
                variant='h6'
                sx={{
                  color: "#757575",
                  fontWeight: "bold",
                }}
              >
                {producerDetail?.producer?.name}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Paper>
      </Box>

      <Paper
        sx={{
          padding: "10px",
        }}
      >
        <Typography>
          <Stack direction='row' spacing={4} alignItems='center'>
            <Box sx={{ userSelect: "none", fontWeight: "bold" }}>
              Song Lists
            </Box>
            <Box component='form'>
              <Stack direction='row' spacing={2}>
                <TextField
                  width='5rem'
                  label='Search Song Name'
                  type='text'
                  onChange={(e) => setInputVal(e.target.value)}
                />
              </Stack>
            </Box>
          </Stack>
        </Typography>
      </Paper>

      {producerDetailSongs.length > 0 ? (
        <>
          <Paper sx={{ width: "100%", marginTop: "3rem", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label='song-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                        align={title.align}
                        key={title.id}
                      >
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {producerDetailSongs?.map((list) => (
                    <TableRow key={list?.song_key}>
                      <TableCell>
                        <Typography variant='body1' component='div'>
                          <Link
                            to='/song-detail'
                            state={{
                              song_key: list.song_key,
                            }}
                          >
                            {list?.title}
                          </Link>
                        </Typography>
                        <br />
                        <Box
                          sx={{
                            display: "flex",
                            alginItems: "center",
                          }}
                        >
                          <InterpreterModeIcon
                            size='small'
                            sx={{
                              margin: "0 4px",
                              color: "#1769aa",
                            }}
                          />
                          {list?.singers == null
                            ? "N/A"
                            : list?.singers?.length > 1
                            ? list?.singers.map((singer) => (
                                <Link
                                  to='/singer-detail'
                                  state={{
                                    singer_key: singer.singer_key,
                                  }}
                                  key={singer.singer_key}
                                >
                                  {singer?.name + " ၊ "}
                                </Link>
                              ))
                            : list?.singers.map((singer) => (
                                <Link
                                  to='/singer-detail'
                                  state={{
                                    singer_key: singer.singer_key,
                                  }}
                                  key={singer.singer_key}
                                >
                                  {singer?.name}
                                </Link>
                              ))}
                        </Box>
                      </TableCell>

                      <TableCell align='left'>
                        {list?.album == null ? (
                          "N/A"
                        ) : (
                          <Link
                            to='/album-detail'
                            state={{ album_key: list.album.album_key }}
                          >
                            {list?.album.name}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {list?.artists ? (
                          list?.artists?.length == 1 ? (
                            <Link
                              to='/artist-detail'
                              state={{
                                artist_key: list?.artists[0].artist_key,
                              }}
                            >
                              {list?.artists[0]?.name}
                            </Link>
                          ) : (
                            list?.artists?.map((artist) => (
                              <Link
                                to='/artist-detail'
                                state={{
                                  artist_key: artist.artist_key,
                                }}
                                key={artist.artist_key}
                              >
                                {artist.name + " ၊ "}{" "}
                              </Link>
                            ))
                          )
                        ) : (
                          "N/A"
                        )}
                        {list?.artists?.length == 0 ? "N/A" : ""}
                      </TableCell>
                      <TableCell>
                        {list?.music_lists === null ? (
                          "N/A"
                        ) : (
                          <LightTooltip
                            placement='right-start'
                            title={
                              <Box
                                sx={{
                                  width: "200px",

                                  padding: "20px",
                                }}
                              >
                                <Typography variant='h6'>Group</Typography>
                                {list?.music_lists?.map((li) => (
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {li?.name}
                                  </Typography>
                                ))}
                              </Box>
                            }
                          >
                            <IconButton
                              sx={{
                                color: "#0277bd",
                              }}
                            >
                              <PianoIcon />
                            </IconButton>
                          </LightTooltip>
                        )}
                      </TableCell>

                      {list?.contracts == null ? (
                        <TableCell>N/A</TableCell>
                      ) : (
                        <TableCell>
                          {" "}
                          {list?.contracts?.audio == null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <Card sx={{ maxWidth: "200px" }}>
                                  <CardContent>
                                    <Typography
                                      sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                      }}
                                      color='text.secondary'
                                      gutterBottom
                                    >
                                      Audio Contract Timeline
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      Start Date :{list.contracts?.audio?.start}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      End Date : {list.contracts?.audio?.end}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton>
                                <HeadphonesIcon
                                  sx={{
                                    color: "#9FC490",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {list?.contracts?.audioKaraoke == null ? (
                            "N/A"
                          ) : (
                            <Tooltip
                              title={
                                <Card sx={{ maxWidth: "200px" }}>
                                  <CardContent>
                                    <Typography
                                      sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                      }}
                                      color='text.secondary'
                                      gutterBottom
                                    >
                                      Audio Karaoke Contract Timeline
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      Start Date :
                                      {list.contracts?.audioKaraoke?.start}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      End Date :
                                      {list.contracts?.audioKaraoke?.end}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton>
                                <InterpreterModeIcon
                                  sx={{
                                    color: "#9FC490",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {list?.contracts?.video == null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <Card sx={{ maxWidth: "200px" }}>
                                  <CardContent>
                                    <Typography
                                      sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                      }}
                                      color='text.secondary'
                                      gutterBottom
                                    >
                                      Video Contract Timeline
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      Start Date :{list.contracts?.video?.start}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      End Date : {list.contracts?.video?.end}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton>
                                <PersonalVideoIcon
                                  sx={{
                                    color: "#9FC490",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {list?.contracts?.videoKaraoke == null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <Card sx={{ maxWidth: "200px" }}>
                                  <CardContent>
                                    <Typography
                                      sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                      }}
                                      color='text.secondary'
                                      gutterBottom
                                    >
                                      Video Karaoke Contract Timeline
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      Start Date :
                                      {list.contracts?.videoKaraoke?.start}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                      End Date :
                                      {list.contracts?.videoKaraoke?.end}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton>
                                <MusicVideoIcon
                                  sx={{
                                    color: "#9FC490",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}

                      <TableCell align='left'>
                        <Stack direction='row'>
                          <IconButton
                            onClick={() =>
                              navigate("/song-list/edit", {
                                state: {
                                  song_detail_data: list,
                                },
                              })
                            }
                          >
                            <EditIcon color='info' />
                          </IconButton>
                          <ConfirmDelete song_key={list?.song_key} />
                          <IconButton
                            onClick={() => {
                              setIsDrawerOpen(true);
                              setAttachment(list?.attachments);
                            }}
                          >
                            <PhotoLibraryIcon sx={{ color: "#8bc34a" }} />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              navigate(
                                "/contract/contract-song/contract-upload",
                                {
                                  state: {
                                    contract_song_data: list,
                                  },
                                }
                              )
                            }
                          >
                            <FileUploadIcon sx={{ color: "#827717" }} />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={producerDetailTotalSong}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <Alert my={3} fullWidth severity='error'>
          No Song For this search...
        </Alert>
      )}

      <Drawer
        variant='temporary'
        sx={{
          zIndex: "100",
        }}
        width={"100%"}
        anchor='top'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box
          sx={{
            width: "1980px",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
            justifyContent='space-between'
            p={1}
            textAlign='center'
            role='presentation'
          >
            <Typography variant='h6' component='div'>
              Contract Photos
            </Typography>

            <Box
              sx={{
                display: "flex",
                margin: "4rem 4rem",
              }}
            >
              <IconButton onClick={() => setIsDrawerOpen(false)}>
                <CloseIcon color='error' />
              </IconButton>
            </Box>
          </Box>

          <Carousel
            navButtonsAlwaysVisible
            sx={{
              width: "1000px",
              backgroundColor: "#fffefa",
              margin: "0 auto",
            }}
          >
            {attachment?.map((item, i) => (
              <Stack alignItems='center'>
                <img
                  textAlign='center'
                  width='700px'
                  height='700px'
                  src={item?.media_link}
                />
              </Stack>
              // </Box>
            ))}
          </Carousel>
        </Box>
      </Drawer>
    </div>
  );
};

export default ProducerDetail;
