import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Group_List_Drawer_Edit_Music_List from "../group_list_components/Group_List_Drawer_Edit_Music_List";
import Group_List_Drawer_Edit_Album_List from "../group_list_components/Group_List_Drawer_Edit_Album_List";
import { setSelectedMusicList } from "../../Music_List/api/MusicListApiSlice";
import { setSelectedAlbumMultiSelect } from "../../Album_List/api/AlbumListApiSlice";
import { editGroup } from "../api/Group_List_Api";

const Group_List_Edit = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [type, setType] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [drawerMusicList, setDrawerMusicList] = useState(false);
  const [drawerAlbumList, setDrawerAlbumList] = useState(false);
  const { selectedMusicList } = useSelector((state) => state.music_list);
  const { setSelectedAlbumMultiSelectDefault, selectedAlbumListMultiSelect } =
    useSelector((state) => state.album_list);
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const [updateGroup, setUpdateGroup] = useState("");
  const [updateType, setUpdateType] = useState("");
  const [musicListOriginal, setMusicListOriginal] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const group_list_data = location.state.group_list_data;

  console.log({ selectedMusicList });
  console.log({ group_list_data });

  useEffect(() => {
    setUpdateGroup(group_list_data.group_name);
    setUpdateType(group_list_data.group_type);
    setImage(group_list_data.group_image.media_link);
    setMusicListOriginal(group_list_data.musiclists);
    if (group_list_data.group_type == "playlist") {
      dispatch(setSelectedMusicList(group_list_data.musiclists));
    } else {
      dispatch(setSelectedAlbumMultiSelect(group_list_data.musiclists));
    }
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const musicListValue = selectedMusicList?.map((item) => {
    return item?.name;
  });

  const albumListValue = selectedAlbumListMultiSelect?.map(
    (item) => item?.name
  );

  const handleChange = (event) => {
    setType(event.target.value);
    setShowFlag(true);
  };

  // Base64 Format Change Start
  const update64Img = [];
  useEffect(() => {
    if (updateImage) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        update64Img.push(imageDataUrl);
        console.log(update64Img);
      };
      reader.readAsDataURL(updateImage);
    }
  }, [updateImage]);
  // Base64 Format Change End

  let musiclists;
  if (group_list_data.group_type === "playlist") {
    musiclists =
      selectedMusicList == undefined ? musicListOriginal : selectedMusicList;
  } else {
    musiclists =
      selectedAlbumListMultiSelect == undefined
        ? musicListOriginal
        : selectedAlbumListMultiSelect;
  }

  const updateGroupListHandler = (e) => {
    e.preventDefault();
    console.log("Clicked");

    const data = {
      group_name: updateGroup,
      group_image: updateImage === undefined ? null : update64Img[0],
      musiclists,
    };

    dispatch(
      editGroup({
        admin_id,
        auth: token,
        group_id: group_list_data._id,
        data,
      })
    );
    navigate(-1);
  };

  console.log({ musicListOriginal });
  console.log({ musicListValue, albumListValue });
  console.log({ selectedAlbumListMultiSelect });
  console.log({ albumListValue });

  return (
    <div>
      <form onSubmit={updateGroupListHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Add Group Form +
            </Typography>
            <Typography
              required
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Group Name</Box>
            </Typography>
            <TextField
              onChange={(e) => setUpdateGroup(e.target.value)}
              value={updateGroup}
              autoComplete='off'
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Select Type</Box>
            </Typography>
            <FormControl disabled required fullWidth>
              <InputLabel id='demo-simple-select-label'>Type</InputLabel>
              <Select
                value={updateType}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Type'
                onChange={handleChange}
              >
                <MenuItem value='playlist'>Music List</MenuItem>
                <MenuItem value='albumlist'>Album List</MenuItem>
              </Select>
            </FormControl>

            <Box>
              {updateType == "playlist" ? (
                <>
                  <Typography
                    sx={{
                      userSelect: "none",
                    }}
                    variant='h6'
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Select Music List
                    </Box>
                  </Typography>
                  <TextField
                    value={musicListValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerMusicList(true);
                      setDrawerAlbumList(false);
                    }}
                    required
                    placeholder='Select Music List'
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      userSelect: "none",
                    }}
                    variant='h6'
                  >
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Select Album List
                    </Box>
                  </Typography>
                  <TextField
                    value={albumListValue}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setDrawerMusicList(false);
                      setDrawerAlbumList(true);
                    }}
                    placeholder='Select Album List'
                    fullWidth
                  />
                </>
              )}
            </Box>
            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>

            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Group Image</Box>
            </Typography>
            <TextField onChange={imageUploader} type='file' fullWidth />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Group
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
      <Drawer
        width={"100%"}
        anchor='button'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box width='1980px'>
          <Box
            sx={{
              display: "flex",
              margin: "80px 0 0 0 ",
            }}
            justifyContent='space-between'
            p={1}
            textAlign='center'
            role='presentation'
          >
            <Typography variant='h6' component='div'>
              Select your music lists
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginRight: "2rem",
              }}
            >
              <IconButton
                size='large'
                sx={{
                  margin: "0 30px 0 0",
                }}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#ED474A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {drawerMusicList && <Group_List_Drawer_Edit_Music_List />}
          {drawerAlbumList && <Group_List_Drawer_Edit_Album_List />}
        </Box>
      </Drawer>
    </div>
  );
};

export default Group_List_Edit;
