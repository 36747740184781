import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Avatar, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import { deleteProducer } from "../views/Producer/api/ProducerApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBand } from "../views/Band/api/BandApi";
import { deleteRecording } from "../views/Recording/api/RecordingApi";
import { deleteSinger } from "../views/Singer_List/api/SingerAddApi";
import { deleteArtist } from "../views/Artist/api/Artist_List_Api";
import { deleteAlbum } from "../views/Album_List/api/AlbumListApi";
import { deleteMusicList } from "../views/Music_List/api/MusicListApi";
import { deleteGroup } from "../views/Group_List/api/Group_List_Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 20,
  p: 4,
};

export default function ConfirmDelete({
  song_key,
  producer_key,
  band_key,
  recording_key,
  singer_key,
  setSingerDelete,
  artist_key,
  artistDelete,
  setArtistDelete,
  album_key,
  setDeleteAlbum,
  music_list_key,
  setMusicListDelete,
  group_id,
  setGroupListDelete,
}) {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { producerDetail } = useSelector((state) => state.producer_data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteHandler = (e) => {
    e.preventDefault();
    if (song_key) {
      console.log({
        song_key,
        token,
        admin_id,
        producer_key: producerDetail.producer.producer_key,
      });
    } else if (producer_key) {
      console.log({
        producer_key,
        token,
        admin_id,
      });
      dispatch(deleteProducer({ producer_key, auth: token, admin_id }));
      handleClose();
      navigate("/producer-list");
    } else if (band_key) {
      console.log({
        band_key,
        token,
        admin_id,
      });
      dispatch(deleteBand({ admin_id, auth: token, band_key }));
      // navigate("/band-list");
    } else if (recording_key) {
      console.log({ recording_key, token, admin_id });
      dispatch(deleteRecording({ recording_key, auth: token, admin_id }));
    } else if (singer_key) {
      console.log({ singer_key, token, admin_id });
      dispatch(deleteSinger({ admin_id, singer_key, auth: token }));
      setSingerDelete(true);
      handleClose(false);
    } else if (artist_key) {
      console.log({ artist_key, token, admin_id });
      dispatch(deleteArtist({ artist_key, admin_id, auth: token }));
      setArtistDelete(!artistDelete);
      handleClose(false);
    } else if (album_key) {
      console.log({ album_key, token, admin_id });
      dispatch(deleteAlbum({ album_key, admin_id, auth: token }));
      setDeleteAlbum(true);
      handleClose(false);
    } else if (music_list_key) {
      console.log({ music_list_key, token, admin_id });
      dispatch(deleteMusicList({ admin_id, auth: token, id: music_list_key }));
      setMusicListDelete(true);
      handleClose(false);
    } else if (group_id) {
      dispatch(deleteGroup({ admin_id, auth: token, group_id }));
      setGroupListDelete(true);
      handleClose(false);
    }
  };

  return (
    <div>
      <IconButton onClick={handleOpen} color='error' aria-label='view contract'>
        <DeleteIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Avatar
            sx={{
              width: "150px",
              height: "150px",
              margin: "0 auto",
              bgcolor: "#fff",
              borderRadius: "1px solid #000",
            }}
          >
            <CancelIcon
              sx={{
                width: "80px",
                height: "80px",
              }}
              color='error'
            />
          </Avatar>

          <Stack
            spacing={2}
            direction='column'
            sx={{
              display: "flex",
              alignItem: "center",
            }}
          >
            <Typography
              id='modal-modal-title'
              sx={{ margin: "0 auto" }}
              variant='h4'
              component='div'
            >
              Are you sure?
            </Typography>
            <Typography
              variant='h5'
              component='div'
              sx={{ margin: "0 auto" }}
              id='modal-modal-description'
            >
              You Won't be able to revert this.
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              alginItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                handleClose(false);
              }}
              sx={{
                backgroundColor: "#8388A4",
                color: "#fff",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteHandler}
              sx={{
                padding: "20px",
                borderRadius: "8px",
              }}
              color='error'
              variant='contained'
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
