import { createAsyncThunk } from "@reduxjs/toolkit";

import { REAL_API } from "../../../globalApi";

export const getArtistDetail = createAsyncThunk(
  "artistDetial/getArtistDetail",
  async ({ admin_id, auth, artist_key }) => {
    console.log({ admin_id, auth, artist_key });
    console.log(REAL_API + `/artists/${artist_key}?admin_id=${admin_id}`);
    const response = await fetch(
      REAL_API + `/artists/${artist_key}?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
