import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  FormGroup,
  FormControlLabel,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Stack,
  RadioGroup,
  Radio,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getProducerContractSong } from "../api/ProducerApi";
import { useDispatch, useSelector } from "react-redux";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { selectContractSong } from "../api/ProducerApiSlice";
import _ from "lodash";

const ProducerContractForm = ({ drawerClose, producer_id }) => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { producerSearchSong } = useSelector((state) => state.producer_data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [actVal, setActVal] = useState("");
  const dispatch = useDispatch();
  const [checkInput, setCheckInput] = useState(null);
  const [showChecked, setShowChecked] = useState(false);
  const [song, setSong] = useState(false);
  const [singer, setSinger] = useState(false);
  const [album, setAlbum] = useState(false);
  const [value, setValue] = useState("Song");

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  const changeHandler = (e) => {
    setValue(e.target.value);
  };

  console.log({ value });
  console.log({ producerSearchSong });

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setActVal(inputVal);
    }, 600);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getProducerContractSong({
        producer_id,
        page,
        limit: rowsPerPage,
        auth: token,
        admin_id,
        filter_name: actVal,
        type: value,
      })
    );
  }, [actVal, value]);
  console.log({ checkInput });

  const removeHandler = (song_key) => {
    let checkRemainInput = checkInput?.filter(
      (item) => item.song_key !== song_key
    );
    setCheckInput(checkRemainInput);
  };

  const showCheckedHandler = () => {
    setShowChecked(!showChecked);
  };

  const hideCheckHandler = () => {
    setShowChecked(!showChecked);
  };

  const addHandler = () => {
    dispatch(selectContractSong(checkInput));
    drawerClose(false);
  };

  return (
    <Box
      backgroundColor='#86BBD8'
      height='70vh'
      display='flex'
      width='80%'
      justifyContent='space-between'
    >
      {!showChecked && (
        <Box margin={3}>
          <FormGroup>
            <RadioGroup
              row
              value={value}
              onChange={changeHandler}
              name='radio-buttons-group'
            >
              <FormControlLabel value='Song' control={<Radio />} label='Song' />
              <FormControlLabel
                value='Singer'
                control={<Radio />}
                label='Singer'
              />
              <FormControlLabel
                value='Album'
                control={<Radio />}
                label='Album'
              />
            </RadioGroup>
          </FormGroup>
          <Stack spacing={2} direction='row'>
            <Autocomplete
              multiple
              id='checkboxes-tags-demo'
              options={producerSearchSong}
              onChange={(event, newValue) => {
                setCheckInput(newValue);
              }}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => {
                return option.song_key === value.song_key;
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.song_key}>
                    <Checkbox
                      icon={icon}
                      key={option.song_key}
                      label={option.title}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </li>
                );
              }}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  onChange={(event) => {
                    console.log("textfield change");
                    setInputVal(event.target.value);
                  }}
                  {...params}
                  label='Song Checkbox'
                  placeholder='Songs'
                />
              )}
            />
            <Button
              onClick={showCheckedHandler}
              endIcon={<LibraryAddIcon />}
              variant='contained'
              sx={{ height: "7%", margin: "10px 10px" }}
            >
              Add
            </Button>
          </Stack>
        </Box>
      )}

      <Box
        marginRight={4}
        sx={{
          width: "50%",
          height: "100%",
          // backgroundColor: "pink",
        }}
      >
        {showChecked && (
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              margin: "10px 10px",
            }}
            subheader={<ListSubheader>Contract Song List</ListSubheader>}
          >
            {checkInput?.map((item) => (
              <ListItem key={item.song_key}>
                <ListItemText id='contract song list' primary={item.title} />
                <IconButton onClick={() => removeHandler(item.song_key)}>
                  <RemoveCircleIcon color='error' />
                </IconButton>
              </ListItem>
            ))}
            <Stack
              display='flex'
              margin={2}
              justifyContent='space-between'
              direction='row'
            >
              <Button onClick={hideCheckHandler} variant='text' color='error'>
                Cancel
              </Button>
              <Button
                onClick={addHandler}
                variant='contained'
                color='info'
                sx={{
                  borderRadius: "5px",
                }}
              >
                Add
              </Button>
            </Stack>
          </List>
        )}
      </Box>
    </Box>
  );
};

export default ProducerContractForm;
