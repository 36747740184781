import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import PrivateRoutes from "../routes/PrivateRoutes";
import LoginLog from "../views/Authentication/pages/LoginLog";
import { onSetValid } from "../views/Authentication/api/AuthenticationApiSlice";
import { getLogoutApi } from "../views/Authentication/api/AuthenticationApi";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import AlbumIcon from "@mui/icons-material/Album";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LyricsIcon from "@mui/icons-material/Lyrics";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import ArticleIcon from "@mui/icons-material/Article";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { token, user_data, admin_id } = useSelector(
    (state) => state.authentication
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTime = new Date().getTime();
  const [clicked, setClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const homeButtonStyle = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    bgcolor: clicked ? "transparent" : "#1976d2",
    color: clicked ? "#000" : "#fff",
    "&:hover, &:focus": {
      bgcolor: "#2196f3",
      color: "#fff",
    },
  };

  const otherButtonStyle = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    bgcolor: clicked ? "#1976d2" : "transparent",
    color: clicked ? "#fff" : "#000",
    "&:hover, &:focus": {
      bgcolor: "#2196f3",
      color: "#fff",
    },
  };

  useEffect(() => {
    if (localStorage.getItem("music_data")) {
      const localData = JSON.parse(localStorage.getItem("music_data"));
      let localExpiredTime = localData?.data.token_expired_at;
      localExpiredTime = new Date(localExpiredTime).getTime();

      if (currentTime > localExpiredTime) {
        console.log("current Time Passed");
      } else if (currentTime < localExpiredTime) {
        console.log("Current Time did not pass.");
        dispatch(onSetValid(localData));
      }
    }
  }, [token]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    console.log("Clicked");

    localStorage.clear();

    window.location.reload();

    // dispatch(getLogoutApi({ admin_id, auth: token }));
  };

  return (
    <div>
      {token ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position='fixed' open={open}>
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Stack direction='row' alignItems='center'>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleDrawerOpen}
                  edge='start'
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant='h6' noWrap component='div'>
                  Tota Media Contract
                </Typography>
              </Stack>
              <Box>
                <IconButton
                  color='inherit'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                >
                  <AccountCircle
                    sx={{
                      fontSize: "50px",
                    }}
                  />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <PersonIcon
                      sx={{
                        color: "#0288d1",
                        margin: "0 5px 0 0",
                      }}
                    />
                    {user_data?.name}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      bgcolor: "#e64a19",
                      "&:hover, &:focus": {
                        bgcolor: "#bf360c",
                      },
                    }}
                    onClick={(e) => {
                      handleClose();
                      logoutHandler(e);
                    }}
                  >
                    <LogoutIcon />
                    Log Out
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer variant='permanent' open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem key={"Home"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/main");
                  }}
                  sx={homeButtonStyle}
                  disableRipple
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: clicked ? "inhertit" : "#fff",
                    }}
                  >
                    <HomeIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Home"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem key={"Songs"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/song-list");
                  }}
                  sx={otherButtonStyle}
                  disableRipple
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: clicked ? "#fff" : "inhertit",
                    }}
                  >
                    <LibraryMusicIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Song"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                key={"Singers"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/singer-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <InterpreterModeIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Singer"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem key={"Albums"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/album-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AlbumIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Album"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"Producers"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/producer-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <GroupWorkIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Producer"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"Artists"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/artist-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <LyricsIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Artist"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Bands"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/band-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <GroupsIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Band"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"Recordings"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/recording-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <KeyboardVoiceIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Recording"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"Music List"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/music-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <QueueMusicIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Music List"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"Group List"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/group-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PlaylistPlayIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Group List"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                key={"Contract"}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/contract/song-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ArticleIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Contract"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem key={"User"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    navigate("/user-list");
                  }}
                  sx={otherButtonStyle}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AccountCircleIcon size='small' />
                  </ListItemIcon>
                  <ListItemText
                    primary={"User"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </List>
          </Drawer>
          <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <PrivateRoutes />
          </Box>
        </Box>
      ) : (
        <>
          <Routes>
            <Route path='/login' element={<LoginLog />} />
            <Route path='*' element={<Navigate to='/login' />} />
          </Routes>
        </>
      )}
    </div>
  );
};
export default SideLayout;
