import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getAlbumList = createAsyncThunk(
  "albumList/getAlbumList",
  async ({ admin_id, page, limit, auth, album_name }) => {
    const response = await fetch(
      REAL_API +
        `/albums?page=${
          page + 1
        }&limit=${limit}&album_name=${album_name}&admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const addAlbum = createAsyncThunk(
  "album list/add album",
  async ({ admin_id, auth, data }) => {
    console.log({
      admin_id,
      auth,
      data,
    });
    const response = await axios.post(
      REAL_API + `/albums?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const deleteAlbum = createAsyncThunk(
  "delete album",
  async ({ admin_id, album_key, auth }) => {
    console.log({ admin_id, album_key, auth });
    const response = await fetch(
      REAL_API + `/albums/${album_key}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

// {{localPath}}/api/admin/albums/UPLJL5B8Q1G7UNF8BMZ93?admin_id={{adminID}}&_method=PUT

export const updateAlbum = createAsyncThunk(
  "update album",
  async ({ admin_id, auth, data, album_id, method }) => {
    console.log({ admin_id, auth, data, album_id, method });
    const response = await axios.post(
      REAL_API + `/albums/${album_id}?admin_id=${admin_id}&_method=${method}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);
