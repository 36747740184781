import React, { useState, useEffect } from "react";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getMusicList } from "../../Music_List/api/MusicListApi";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMusicList } from "../../Music_List/api/MusicListApiSlice";

const Song_Edit_MusicList = () => {
  const [inputVal, setInputVal] = useState("");
  const [checkInput, setCheckInput] = useState(null);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { musicList, selectedMusicList } = useSelector(
    (state) => state.music_list
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMusicList({ auth: token, admin_id }));
  }, []);

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  console.log({ musicList });
  console.log({ checkInput });

  return (
    <div>
      <Autocomplete
        sx={{
          margin: "60px 500px 300px",
        }}
        multiple
        id='checkboxes-tags-demo'
        options={musicList}
        value={selectedMusicList}
        onChange={(event, newValue) => {
          setCheckInput(newValue);
          dispatch(setSelectedMusicList(newValue));
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option._id === value._id || option._id === value.id;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option._id}>
              <Checkbox
                icon={icon}
                key={option._id}
                label={option.description}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          );
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            sx={{
              width: "800px",
            }}
            {...params}
            label='Music List Checkbox'
            placeholder='Select Music Lists'
          />
        )}
      />
    </div>
  );
};
export default Song_Edit_MusicList;
