import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

export const getSuggest = createAsyncThunk(
  "song data/getSuggest",
  async ({ id, input, auth, type }) => {
    const response = await fetch(
      REAL_API + `/search?admin_id=${id}&filter_name=${input}&type=${type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
