import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserDetail } from "../api/UserApi";
import { useDispatch, useSelector } from "react-redux";

const User_Detail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { userDetail } = useSelector((state) => state.user_list);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const user_id = location.state.user_id;

  useEffect(() => {
    dispatch(getUserDetail({ admin_id, auth: token, user_id }));
  }, []);

  console.log({ user_id });
  console.log({ userDetail });

  return (
    <div>
      <Box>
        <Paper
          sx={{
            width: "1000px",
            padding: "10px",
            margin: "60px auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction='row' alignItems='center' spacing={4}>
              <img
                width='100px'
                src={
                  userDetail?.user?.profile
                    ? userDetail?.user?.profile
                    : "https://v.totamedia.com/static/media/pngwing.com%20(1).2e5a2f8f1da62b3b2c6e.png"
                }
                alt='user profile'
              />
              <Stack direction='column' spacing={2}>
                <Typography variant='h5'>{userDetail?.user?.name}</Typography>

                <Typography variant='body1'>
                  Phone: {userDetail?.user?.phone}
                </Typography>
              </Stack>
            </Stack>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default User_Detail;
