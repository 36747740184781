import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const getArtistList = createAsyncThunk(
  "artistList/getArtistList",
  async ({ admin_id, auth, page, limit, filter_name }) => {
    console.log(
      REAL_API +
        `/artists?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&artist_name=${filter_name}`
    );
    const response = await fetch(
      REAL_API +
        `/artists?admin_id=${admin_id}&page=${
          page + 1
        }&limit=${limit}&artist_name=${filter_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

export const addArtist = createAsyncThunk(
  "add Artist",
  async ({ admin_id, auth, data }) => {
    console.log({ admin_id, auth, data });

    const response = await axios.post(
      REAL_API + `/artists?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const updateArtist = createAsyncThunk(
  "update Artist",
  async ({ admin_id, auth, artist_key, data }) => {
    console.log({ admin_id, auth, artist_key, data });
    const response = await axios.post(
      REAL_API + `/artists/${artist_key}?admin_id=${admin_id}&_method=PUT`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const deleteArtist = createAsyncThunk(
  "delete Artist",
  async ({ admin_id, auth, artist_key }) => {
    console.log({ admin_id, auth, artist_key });

    const response = await fetch(
      REAL_API + `/artists/${artist_key}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
