import { createSlice } from "@reduxjs/toolkit";
import { getSongList, getSongDetail } from "./SongListApi";

const initialState = {
  songList: [],
  isLoading: true,
  songDetail: [],
  songTotal: 1,
  loadingAnimation: false,
};

export const Song_Api = createSlice({
  name: "song list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSongList.fulfilled, (state, action) => {
      state.isLoading = true;
      state.songList = action.payload;
      state.songTotal = action.payload.total;
      state.loadingAnimation = true;
    });
    builder.addCase(getSongDetail.pending, (state) => {
      state.isLoading = false;
      state.songDetail = [];
    });
    builder.addCase(getSongDetail.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      state.songDetail = payload.songs;
    });
  },
});

export default Song_Api.reducer;
