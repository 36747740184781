import React, { useState, useEffect } from "react";
import { getRecordingDetail } from "../api/RecordingApi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";

const Recording_Detail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { recordingDetail } = useSelector((store) => store.recording_list);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const recording_key = location.state.recording_key;

  useEffect(() => {
    dispatch(getRecordingDetail({ admin_id, auth: token, recording_key }));
  }, []);

  console.log({ recording_key });
  console.log({ recordingDetail });

  return (
    <div>
      <Box>
        <Paper
          sx={{
            padding: "20px",
            margin: "0 0 30px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              width='100px'
              alt='recording logo'
              src={
                recordingDetail?.media?.media_link
                  ? recordingDetail?.media?.media_link
                  : "https://images.unsplash.com/photo-1680199994489-22b5f4ccc620?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
              }
            />
            <Stack marginLeft={4}>
              <Typography
                gutterBottom
                variant='h5'
                component='span'
                sx={{
                  fontWeight: "bold",
                  color: "#8d6e63",
                }}
              >
                {recordingDetail?.name}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default Recording_Detail;
