import { createSlice } from "@reduxjs/toolkit";
import { getProducerDetail, getProducerList } from "./ProducerApi";
import { getProducerContractSong } from "./ProducerApi";
import { getContract } from "./ProducerContractApi";

const initialState = {
  producerList: [],
  producerDetail: [],
  producerDetailSongs: [],
  producerTotal: 1,
  producerId: 0,
  producerDetailTotalSong: 1,
  producerSearchSong: [],
  selectContractSongData: [],
  selectContractSongIds: [],
  selectContractSongTitles: [],
  doneContract: [],
  producerListArr: [],
  selectedProducer: [],
  loadingAnimation: false,
};

export const ProducerApi = createSlice({
  name: "producer list",
  initialState,
  reducers: {
    selectContractSong: (state, { payload }) => {
      const song_key = payload.map((item) => item.song_key);
      const titles = payload.map((item) => item.title);
      state.selectContractSongData = payload;
      state.selectContractSongKeys = song_key;
      state.selectContractSongTitles = titles;
    },
    setSelectedProducer: (state, { payload }) => {
      state.selectedProducer = [payload];
      console.log(state.selectedProducer);
    },
    setSeletedProducerDefault: (state) => {
      state.selectedProducer = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducerList.fulfilled, (state, { payload }) => {
      state.producerList = payload;
      state.producerTotal = payload.total;
      state.producerListArr = payload.data;
      state.loadingAnimation = true;
    });
    builder.addCase(getProducerDetail.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.producerDetail = payload;
      state.producerDetailTotalSong = payload.songs.total;
      state.producerDetailSongs = payload.songs.data;
      // state.producerId = payload.producer.media.id;
    });
    builder.addCase(getProducerContractSong.fulfilled, (state, { payload }) => {
      state.producerSearchSong = payload.data;
    });
    builder.addCase(getContract.fulfilled, (state, { payload }) => {
      state.doneContract = payload;
      console.log(state.doneContract);
    });
  },
});

export const {
  selectContractSong,
  setSeletedProducerDefault,
  setSelectedProducer,
} = ProducerApi.actions;

export default ProducerApi.reducer;
