import { createSlice } from "@reduxjs/toolkit";
import { getSingerDetail } from "./SingerDetailApi";

const initialState = {
  isLoading: true,
  singerDetail: [],
  singerDetailSong: [],
  singerTotalSongs: 1,
};

export const SingerDetailApi = createSlice({
  name: "singer detail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSingerDetail.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSingerDetail.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      state.singerDetail = payload.singer;
      state.singerDetailSong = payload.songs;
      state.singerTotalSongs = payload.songs.total;
    });
  },
});

export default SingerDetailApi.reducer;
