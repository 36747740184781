import React, { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Typography,
  CardActionArea,
  Avatar,
  Box,
  TextField,
  Button,
  Grid,
  Alert,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSingerList } from "../api/SingerListApi";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";

const Singer_List = () => {
  const [singerName, setSingerName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { singerList, singerTotal } = useSelector((state) => state.singer_list);
  const [singerDelete, setSingerDelete] = useState(false);
  const [inputVal, setInputVal] = useState("");

  console.log({ token, admin_id });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setSingerName(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getSingerList({
        admin_id,
        page,
        limit: rowsPerPage,
        singer_name: singerName,
        auth: token,
      })
    );
  }, [page, rowsPerPage, singerDelete, singerName]);

  useEffect(() => {
    if (singerName.length < 1)
      dispatch(
        getSingerList({
          auth: token,
          admin_id,
          page,
          limit: rowsPerPage,
          singer_name: singerName,
        })
      );
  }, [singerName]);

  console.log({ singerList });

  return (
    <div>
      {singerList?.data ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Stack alignItems='center' direction='row' spacing={2}>
                  <Typography component='span'>
                    <Box
                      sx={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Singer List
                    </Box>
                  </Typography>
                  <TextField
                    width='5rem'
                    label='Search Singer Name'
                    type='text'
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </Stack>
              </Box>
              <Box>
                <Button
                  onClick={() => navigate("/singer-list/add")}
                  type='submit'
                  aria-label='add-song'
                  sx={{
                    display: "span",
                    margin: "0 10rem",
                    width: "200px",
                  }}
                  variant='outlined'
                  endIcon={<AddIcon />}
                >
                  Add Singer
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              width: "100%",
              marginTop: "3rem",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='singer-list-table'>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        bgcolor: "#d7ccc8",
                        color: "#424242",
                      },
                    }}
                  >
                    {titles.map((title) => (
                      <TableCell align={title.align} key={title.id}>
                        {title.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {singerList?.data?.length > 0 ? (
                    singerList?.data?.map((list) => (
                      <TableRow key={list.singer_key}>
                        <TableCell key={list.singer_key}>
                          <Avatar
                            sx={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={
                              list?.media?.media_link
                                ? list.media.media_link
                                : "https://v.totamedia.com/static/media/pngegg.fa07ad29e3475803cc76.png"
                            }
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Link
                            to='/singer-detail'
                            state={{ singer_key: list.singer_key }}
                          >
                            <Typography variant='h6'> {list?.name}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          <Stack justifyContent='flex-end' direction='row'>
                            <IconButton
                              onClick={() => {
                                navigate("/singer-list/edit", {
                                  state: { singer_data: list },
                                });
                              }}
                              aria-label='edit producer'
                              size='small'
                              color='info'
                            >
                              <EditIcon />
                            </IconButton>
                            {/* <ConfirmDelete
                              singerDelete={singerDelete}
                              setSingerDelete={setSingerDelete}
                              singer_key={list.singer_key}
                            /> */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Alert fullWidth severity='error'>
                          No Singer Here!
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={singerTotal}
              // count={Math.ceil(songTotal / rowsPerPage) - 1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </div>
  );
};

export default Singer_List;

const titles = [
  { id: "singer-image", label: "Singer Image", minWidth: 150 },
  { id: "singer-name", label: "Singer Name", align: "right", minWidth: 170 },
  { id: "singer-action", label: "Action", align: "right", minWidth: 170 },
];
