import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateRecording } from "../api/RecordingApi";

const Recording_Update = () => {
  const [updateRecordingName, setUpdateRecordingName] = useState("");
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const recording_data = location.state.recording_data;

  console.log({ recording_data });

  useEffect(() => {
    setUpdateRecordingName(recording_data.name);
    setImage(recording_data?.media?.media_link);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const updateRecordingHandler = (e) => {
    e.preventDefault();

    const data = {
      name: updateRecordingName,
      image: updateImage === undefined ? null : updateImage,
    };

    dispatch(
      updateRecording({
        admin_id,
        auth: token,
        method: "PUT",
        data,
        recording_key: recording_data?.recording_key,
      })
    );
    navigate(-1);
  };
  return (
    <div>
      <form onSubmit={updateRecordingHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
              }}
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Update Recording Form</Box>
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Recording Name</Box>
            </Typography>
            <TextField
              placeholder='Enter Recording Name'
              value={updateRecordingName}
              onChange={(e) => setUpdateRecordingName(e.target.value)}
              required
              fullWidth
              autoComplete='off'
            />

            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>

            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Recording Cover</Box>
            </Typography>
            <TextField type='file' onChange={imageUploader} fullWidth />
            <Box
              sx={{
                display: "flex",
                marginTop: "2rem",
                justifyContent: "flex-end",
              }}
            >
              <Stack direction='row' spacing={3}>
                <Button
                  type='button'
                  onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: "#f44336",
                    "&:hover, &:focus": {
                      bgcolor: "#d32f2f",
                    },
                  }}
                  variant='contained'
                >
                  Cancel
                </Button>
                <Button type='submit' variant='outlined'>
                  Update Recording
                </Button>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Recording_Update;
