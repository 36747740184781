import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMusicListDetail } from "../api/MusicListApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PianoIcon from "@mui/icons-material/Piano";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

const Music_List_Detail = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [inputVal, setInputVal] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [songName, setSongName] = useState("");
  const { musicListDetail, musicListDetailSong } = useSelector(
    (store) => store.music_list
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const music_list_key = location.state.music_list_key;

  console.log({ music_list_key });
  console.log({ musicListDetail });
  console.log({ musicListDetailSong });

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setSongName(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getMusicListDetail({
        admin_id,
        auth: token,
        music_list_key,
        song_name: songName,
        limit: rowsPerPage,
      })
    );
  }, [songName]);

  return (
    <>
      <Box>
        <Paper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack
              spacing={4}
              alignItems='center'
              direction='row'
              marginLeft={4}
            >
              <img width='100px' src={musicListDetail?.photo?.media_link} />

              <Stack spacing={1}>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='span'
                  color='text.primary'
                >
                  {musicListDetail?.name}
                </Typography>
                <Typography gutterBottom variant='body1'>
                  Total Songs : {musicListDetailSong?.length}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Paper>
      </Box>
      <Paper
        sx={{
          marginTop: "30px",
          padding: "20px",
        }}
      >
        <Box
          component='form'
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Stack alignItems='center' direction='row' spacing={2}>
              <Typography component='span'>
                <Box
                  sx={{
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  Song Lists
                </Box>
              </Typography>
              <TextField
                width='5rem'
                label='Search Songs'
                type='text'
                onChange={(e) => setInputVal(e.target.value)}
              />
            </Stack>
          </Box>
        </Box>
      </Paper>

      <Paper sx={{ width: "100%", marginTop: "3rem", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label='song-list-table'>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    bgcolor: "#d7ccc8",
                    color: "#424242",
                  },
                }}
              >
                {titles.map((title) => (
                  <TableCell align={title.align} key={title.id}>
                    {title.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {musicListDetailSong.length > 0 ? (
                musicListDetailSong.map((list) => (
                  <TableRow>
                    <TableCell>{list.title}</TableCell>
                    <TableCell>{list?.album.name}</TableCell>

                    <TableCell>
                      {list?.artists ? (
                        list?.artists?.length == 1 ? (
                          <Link
                            to='/artist-detail'
                            state={{
                              artist_key: list?.artists[0].artist_key,
                            }}
                          >
                            {list?.artists[0]?.name}
                          </Link>
                        ) : (
                          list?.artists?.map((artist) => (
                            <Link
                              to='/artist-detail'
                              state={{
                                artist_key: artist.artist_key,
                              }}
                              key={artist.artist_key}
                            >
                              {artist.name + " ၊ "}{" "}
                            </Link>
                          ))
                        )
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {list.music_list === null ? (
                        "N/A"
                      ) : (
                        <Tooltip
                          placement='right-start'
                          sx={{
                            background: "transparent",
                          }}
                          title={
                            <Box
                              sx={{
                                backgroundColor: "white",
                                width: "200px",
                                padding: "20px",
                                color: "#000",
                              }}
                            >
                              <Typography variant='h6'>Group</Typography>

                              {list?.music_lists?.map((li) => (
                                <Typography
                                  sx={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {li?.name}
                                </Typography>
                              ))}
                            </Box>
                          }
                        >
                          <IconButton
                            sx={{
                              color: "#0277bd",
                            }}
                          >
                            <PianoIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell>
                      {" "}
                      {list?.contracts?.audio == null ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <Card sx={{ maxWidth: "200px" }}>
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                  }}
                                  color='text.secondary'
                                  gutterBottom
                                >
                                  Audio Contract Timeline
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  Start Date :{list.contracts?.audio?.start}
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  End Date : {list.contracts?.audio?.end}
                                </Typography>
                              </CardContent>
                            </Card>
                          }
                        >
                          <IconButton>
                            <HeadphonesIcon
                              sx={{
                                color: "#9FC490",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {list?.contracts?.audioKaraoke == null ? (
                        "N/A"
                      ) : (
                        <Tooltip
                          title={
                            <Card sx={{ maxWidth: "200px" }}>
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                  }}
                                  color='text.secondary'
                                  gutterBottom
                                >
                                  Audio Karaoke Contract Timeline
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  Start Date :
                                  {list.contracts?.audioKaraoke?.start}
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  End Date :{list.contracts?.audioKaraoke?.end}
                                </Typography>
                              </CardContent>
                            </Card>
                          }
                        >
                          <IconButton>
                            <InterpreterModeIcon
                              sx={{
                                color: "#9FC490",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {list?.contracts?.video == null ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <Card sx={{ maxWidth: "200px" }}>
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                  }}
                                  color='text.secondary'
                                  gutterBottom
                                >
                                  Video Contract Timeline
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  Start Date :{list.contracts?.video?.start}
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  End Date : {list.contracts?.video?.end}
                                </Typography>
                              </CardContent>
                            </Card>
                          }
                        >
                          <IconButton>
                            <PersonalVideoIcon
                              sx={{
                                color: "#9FC490",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {list?.contracts?.videoKaraoke == null ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <Card sx={{ maxWidth: "200px" }}>
                              <CardContent>
                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                  }}
                                  color='text.secondary'
                                  gutterBottom
                                >
                                  Video Karaoke Contract Timeline
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  Start Date :
                                  {list.contracts?.videoKaraoke?.start}
                                </Typography>
                                <Typography sx={{ fontSize: 12 }}>
                                  End Date :{list.contracts?.videoKaraoke?.end}
                                </Typography>
                              </CardContent>
                            </Card>
                          }
                        >
                          <IconButton>
                            <MusicVideoIcon
                              sx={{
                                color: "#9FC490",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <Link
                        to='/producer-detail'
                        state={{
                          producer_key: list?.producer?.producer_key,
                        }}
                      >
                        {list.producer.name}
                      </Link>
                      <Tooltip title={list.amount}>
                        <IconButton>
                          <LocalAtmIcon
                            sx={{
                              color: "#fcd975",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => navigate("/song-list/edit")}>
                        <EditIcon color='info' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Alert fullWidth severity='error'>
                  No Songs Here!
                </Alert>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Music_List_Detail;

const titles = [
  { id: "music-title", label: "Music Title", minWidth: 150 },
  { id: "album-name", label: "Album", align: "left", minWidth: 170 },
  { id: "artist-name", label: "Artist", align: "left", minWidth: 170 },
  { id: "group-name", label: "Group", align: "left", minWidth: 170 },
  { id: "contract-type", label: "Contract", align: "left", minWidth: 170 },
  {
    id: "producer-name",
    label: "Producer",
    align: "left",
    minWidth: 170,
  },
  {
    id: "contract action",
    label: "Contract Action",
    align: "left",
    minWidth: 170,
  },
];
