import { configureStore } from "@reduxjs/toolkit";
import AuthenticationApiSlice from "../views/Authentication/api/AuthenticationApiSlice";
import SongApiSlice from "../views/Search/api/SongApiSlice";
import SongListApiSlice from "../views/Song_List/api/SongListApiSlice";
import SingerListApiSlice from "../views/Singer_List/api/SingerListApiSlice";
import SingerDetailApiSlice from "../views/Singer_List/api/SingerDetailApiSlice";
import AlbumListApiSlice from "../views/Album_List/api/AlbumListApiSlice";
import AlbumDetailApiSlice from "../views/Album_List/api/AlbumDetailApiSlice";
import ArtistListApiSlice from "../views/Artist/api/ArtistListApiSlice";
import ArtistDetailApiSlice from "../views/Artist/api/ArtistDetailApiSlice";
import ProducerApiSlice from "../views/Producer/api/ProducerApiSlice";
import BandApiSlice from "../views/Band/api/BandApiSlice";
import RecordingApiSlice from "../views/Recording/api/RecordingApiSlice";
import MusicListApiSlice from "../views/Music_List/api/MusicListApiSlice";
import GroupListApiSlice from "../views/Group_List/api/Group_List_ApiSlice";
import UserApiSlice from "../views/Users/api/UserApiSlice";

const store = configureStore({
  reducer: {
    authentication: AuthenticationApiSlice,
    suggest: SongApiSlice,
    song_list: SongListApiSlice,
    singer_list: SingerListApiSlice,
    singer_detail: SingerDetailApiSlice,
    album_list: AlbumListApiSlice,
    album_detail: AlbumDetailApiSlice,
    artist_list: ArtistListApiSlice,
    artist_detail: ArtistDetailApiSlice,
    producer_data: ProducerApiSlice,
    band_list: BandApiSlice,
    recording_list: RecordingApiSlice,
    music_list: MusicListApiSlice,
    group_list: GroupListApiSlice,
    user_list: UserApiSlice,
  },
});

export default store;
