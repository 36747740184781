import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateArtist } from "../api/Artist_List_Api";

const Artist_Edit = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [updateArtistName, setUpdateArtistName] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const artist_data = location.state.artist_data;

  console.log({ artist_data });

  useEffect(() => {
    setUpdateArtistName(artist_data.name);
    setImage(artist_data.media.media_link);
  }, []);

  const imageUploader = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUpdateImage(e.target.files[0]);
    }
  };

  const updateArtistHandler = (e) => {
    e.preventDefault();

    const data = {
      name: updateArtistName,
      image: updateImage === undefined ? null : updateImage,
    };

    dispatch(
      updateArtist({
        admin_id,
        auth: token,
        artist_key: artist_data.artist_key,
        data,
      })
    );

    // navigate(-1);
  };

  return (
    <div>
      <form onSubmit={updateArtistHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Update Artist Form
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Artist Name</Box>
            </Typography>
            <TextField
              autoComplete='off'
              value={updateArtistName}
              onChange={(e) => setUpdateArtistName(e.target.value)}
              required
              fullWidth
            />
            <Stack marginTop={3}>
              {image && !updateImage && (
                <Box
                  sx={{
                    height: "25vh",
                    width: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              )}
            </Stack>

            <Stack marginTop={3}>
              {updateImage && (
                <Stack direction='column' spaicng={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(
                        updateImage
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Artist Profile</Box>
            </Typography>

            <TextField onChange={imageUploader} type='file' fullWidth />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Update Artist
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
    </div>
  );
};

export default Artist_Edit;
