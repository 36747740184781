import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

// http://167.99.67.43/api/admin/albums/EIG070YR23VDB5P8RTQFF?admin_id=22

export const getAlbumDetail = createAsyncThunk(
  "albumDetail/getAlbumDetail",
  async ({ admin_id, auth, album_key }) => {
    console.log(REAL_API + `/albums/${album_key}?admin_id=${admin_id}`);

    const response = await fetch(
      REAL_API + `/albums/${album_key}?admin_id=${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
