import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getSongList } from "../api/SongListApi";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import { BarLoader } from "react-spinners";
import AddIcon from "@mui/icons-material/Add";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PianoIcon from "@mui/icons-material/Piano";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { setSelectedSingerDefault } from "../../Singer_List/api/SingerListApiSlice";
import { setSelectedMusicListDefault } from "../../Music_List/api/MusicListApiSlice";
import { setSelectedArtistDefault } from "../../Artist/api/ArtistListApiSlice";
import { setSelectedAlbumDefault } from "../../Album_List/api/AlbumListApiSlice";
import { setSeletedProducerDefault } from "../../Producer/api/ProducerApiSlice";

const titles = [
  { id: "music-title", label: "Music Title", minWidth: 150 },
  { id: "album-name", label: "Album", align: "left", minWidth: 170 },
  { id: "artist-name", label: "Artist", align: "left", minWidth: 170 },
  { id: "group-name", label: "Group", align: "left", minWidth: 170 },
  { id: "contract-type", label: "Contract", align: "left", minWidth: 170 },
  {
    id: "producer-name",
    label: "Producer",
    align: "left",
    minWidth: 170,
  },
  { id: "action", label: "Action", align: "left", minWidth: 170 },
];

const Song_List = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { songList, isLoading, songTotal, loadingAnimation } = useSelector(
    (state) => state.song_list
  );
  const [inputVal, setInputVal] = useState("");
  const [searchSong, setSearchSong] = useState("");
  const [selectedValue, setSelectedValue] = useState("Song");
  const [selected, setSelected] = useState("All");
  const [checked, setChecked] = useState(false);
  const [contract, setContract] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  useEffect(() => {
    dispatch(setSelectedSingerDefault());
    dispatch(setSelectedMusicListDefault());
    dispatch(setSelectedArtistDefault());
    // dispatch(setSelectedAlbumDefault());
    // dispatch(setSeletedProducerDefault());
  }, []);

  useEffect(() => {
    const timeOutInput = setTimeout(() => {
      setSearchSong(inputVal);
    }, 400);
    return () => clearInterval(timeOutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getSongList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        searchSong,
        type: selectedValue,
        contract,
      })
    );
  }, [page, rowsPerPage, selectedValue, searchSong, contract]);

  useEffect(() => {
    if (searchSong.length < 1)
      dispatch(
        getSongList({
          admin_id,
          auth: token,
          page,
          limit: rowsPerPage,
          searchSong,
          type: selectedValue,
          contract,
        })
      );
  }, [searchSong]);

  const handleToggle = () => {
    setChecked(!checked);
    setSelected(checked ? "All" : "Contracted");
    setContract(checked ? "" : "&contract=1");
  };

  return (
    <>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack alignItems='center' direction='row' spacing={2}>
                <Typography component='span'>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Song Lists
                  </Box>
                </Typography>
                <TextField
                  width='5rem'
                  label='Search Song Name'
                  type='text'
                  onChange={(e) => setInputVal(e.target.value)}
                />
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id='song-filter-select-label'>
                      Filter
                    </InputLabel>
                    <Select
                      labelId='song-filter-label'
                      id='song-filter-select'
                      value={selectedValue}
                      label='Filter'
                      onChange={handleChange}
                    >
                      <MenuItem value={"Song"}>Song</MenuItem>
                      <MenuItem value={"Singer"}>Singer</MenuItem>
                      <MenuItem value={"Producer"}>Producer</MenuItem>
                      <MenuItem value={"Artist"}>Artist</MenuItem>
                      <MenuItem value={"Album"}>Album</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <FormControlLabel
                  sx={{
                    width: "130px",
                  }}
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleToggle}
                      inputProps={{ "aria-label": "Switch" }}
                    />
                  }
                  label={selected}
                />
              </Stack>

              <Button
                onClick={() => navigate("/song-list/add")}
                aria-label='add-song'
                sx={{
                  margin: "0 10rem",
                  width: "150px",
                }}
                variant='outlined'
                endIcon={<AddIcon />}
              >
                Add Song
              </Button>
            </Box>
          </Paper>

          {isLoading && songList && (
            <Paper
              sx={{ width: "100%", marginTop: "3rem", overflow: "hidden" }}
            >
              <TableContainer>
                <Table stickyHeader aria-label='song-list-table'>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          bgcolor: "#d7ccc8",
                          color: "#424242",
                        },
                      }}
                    >
                      {titles.map((title) => (
                        <TableCell align={title.align} key={title.id}>
                          {title.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {songList?.data?.length > 0 ? (
                      songList?.data?.map((list) => (
                        <TableRow key={list.song_key}>
                          <TableCell>
                            <Typography variant='body1' component='div'>
                              <Link
                                to='/song-detail'
                                state={{
                                  song_key: list.song_key,
                                }}
                              >
                                {list?.title}
                              </Link>
                            </Typography>
                            <br />
                            <Box
                              sx={{
                                display: "flex",
                                alginItems: "center",
                              }}
                            >
                              <InterpreterModeIcon
                                size='small'
                                sx={{
                                  margin: "0 4px",
                                }}
                              />

                              {list?.singers ? (
                                list?.singers?.length == 1 ? (
                                  <Link
                                    to='/singer-detail'
                                    state={{
                                      singer_key: list?.singers[0].singer_key,
                                    }}
                                  >
                                    {list?.singers[0]?.name}
                                  </Link>
                                ) : (
                                  list?.singers?.map((singer) => (
                                    <Link
                                      to='/singer-detail'
                                      state={{
                                        singer_key: singer.singer_key,
                                      }}
                                      key={singer.singer_key}
                                    >
                                      {singer.name + " ၊ "}
                                    </Link>
                                  ))
                                )
                              ) : (
                                "N/A"
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align='left'>
                            {list?.album == null ? (
                              "N/A"
                            ) : (
                              <Link
                                to='/album-detail'
                                state={{ album_key: list?.album.album_key }}
                              >
                                {list?.album.name}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.artists ? (
                              list?.artists?.length == 1 ? (
                                <Link
                                  to='/artist-detail'
                                  state={{
                                    artist_key: list?.artists[0].artist_key,
                                  }}
                                >
                                  {list?.artists[0]?.name}
                                </Link>
                              ) : (
                                list?.artists?.map((artist) => (
                                  <Link
                                    to='/artist-detail'
                                    state={{
                                      artist_key: artist.artist_key,
                                    }}
                                    key={artist.artist_key}
                                  >
                                    {artist.name + " ၊ "}{" "}
                                  </Link>
                                ))
                              )
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.music_lists === null ? (
                              "N/A"
                            ) : (
                              <LightTooltip
                                placement='right-start'
                                title={
                                  <Box
                                    sx={{
                                      width: "200px",

                                      padding: "20px",
                                    }}
                                  >
                                    <Typography variant='h6'>Group</Typography>
                                    {list?.music_lists?.map((li) => (
                                      <Typography
                                        sx={{
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {li?.name}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <IconButton
                                  sx={{
                                    color: "#0277bd",
                                  }}
                                >
                                  <PianoIcon />
                                </IconButton>
                              </LightTooltip>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.contracts?.audio == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :{" "}
                                        {list.contracts?.audio?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.audio?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <HeadphonesIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.audioKaraoke == null ? (
                              "N/A"
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.audioKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :{" "}
                                        {list.contracts?.audioKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <InterpreterModeIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.video == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :{" "}
                                        {list.contracts?.video?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.video?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <PersonalVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.videoKaraoke == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ minWidth: 150 }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.videoKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :{" "}
                                        {list.contracts?.videoKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <MusicVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {list?.producer == null ? (
                              "N/A"
                            ) : list?.producer?.length == 1 ? (
                              <Link
                                to='/producer-detail'
                                state={{
                                  producer_key: list?.producer?.producer_key,
                                }}
                              >
                                {list?.producer[0].name}
                              </Link>
                            ) : (
                              <Link
                                to='/producer-detail'
                                state={{
                                  producer_key: list?.producer?.producer_key,
                                }}
                              >
                                {list?.producer.name}
                              </Link>
                            )}
                            {list?.amount > -1 ? (
                              <Tooltip title={list.amount}>
                                <IconButton>
                                  <LocalAtmIcon
                                    sx={{
                                      color: "#fcd975",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                navigate("/song-list/edit", {
                                  state: {
                                    song_detail_data: list,
                                  },
                                })
                              }
                            >
                              <EditIcon color='info' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Alert fullWidth severity='error'>
                            No Song Here!
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component='div'
                count={songTotal}
                // count={Math.ceil(songTotal / rowsPerPage) - 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </>
  );
};

export default Song_List;
