import { createSlice } from "@reduxjs/toolkit";
import { getArtistDetail } from "./Artist_Detail_Api";

const initialState = {
  artistDetail: [],
};

export const ArtistDetailApi = createSlice({
  name: "artist detail",
  initialState,
  reducers: [],
  extraReducers: (builder) => {
    builder.addCase(getArtistDetail.fulfilled, (state, { payload }) => {
      state.artistDetail = payload;
    });
  },
});

export default ArtistDetailApi.reducer;
