import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Song_Select_MusicList from "../../Song_List/songadd_components/Song_Select_MusicList";
import Group_List_Drawer_Album_List from "../group_list_components/Group_List_Drawer_Album_List";
import { setSelectedAlbumMultiSelectDefault } from "../../Album_List/api/AlbumListApiSlice";
import { setSelectedMusicListDefault } from "../../Music_List/api/MusicListApiSlice";
import { addGroup } from "../api/Group_List_Api";
import Group_List_Drawer_Music_List from "../group_list_components/Group_List_Drawer_Music_List";

const Group_List_Add = () => {
  const { token, admin_id } = useSelector((state) => state.authentication);
  const [oPhotoArr, setOPhotoArr] = useState([]);
  const [image, setImage] = useState(null);
  const [type, setType] = useState("");
  const [showFlag, setShowFlag] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMusicList, setDrawerMusicList] = useState(false);
  const [drawerAlbumList, setDrawerAlbumList] = useState(false);
  const { selectedMusicList } = useSelector((state) => state.music_list);
  const { selectedAlbumListMultiSelect } = useSelector(
    (state) => state.album_list
  );
  const [groupName, setGroupName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setType(event.target.value);
    setShowFlag(true);
  };

  useEffect(() => {
    if (type == "playlist") {
      dispatch(setSelectedMusicListDefault());
    } else {
      dispatch(setSelectedAlbumMultiSelectDefault());
    }
  }, [type]);

  const musicListValue = selectedMusicList?.map((item) => {
    return item?.name;
  });

  const albumListValue = selectedAlbumListMultiSelect?.map(
    (item) => item?.name
  );

  const imageUploader = (e) => {
    const allFile = e.target.files;
    setOPhotoArr([...allFile]);
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  // Base64 Format Change Start
  const base64Photo = [];
  useEffect(() => {
    for (let index = 0; index < oPhotoArr.length; index++) {
      const reader = new FileReader();
      reader.onload = function () {
        const imageDataUrl = reader.result;
        base64Photo.push(imageDataUrl);
        console.log(base64Photo);
      };
      reader.readAsDataURL(oPhotoArr[index]);
    }
  }, [oPhotoArr]);

  // Base64 Format Change End

  const addGroupListHandler = (e) => {
    e.preventDefault();
    dispatch(
      addGroup({
        admin_id,
        auth: token,
        group_name: groupName,
        group_type: type,
        group_image: base64Photo[0],
        musiclists:
          selectedMusicList.length > 0
            ? selectedMusicList
            : selectedAlbumListMultiSelect,
      })
    );
    navigate(-1);
  };

  return (
    <div>
      <form onSubmit={addGroupListHandler}>
        <Paper
          variant='elevation'
          my={4}
          elevation={3}
          sx={{
            p: 5,
            height: "100%",
            width: 800,
            m: "2px auto",
          }}
          square
        >
          <Box margin='0 0 2rem 0'>
            <Typography
              variant='h5'
              my={4}
              sx={{
                userSelect: "none",
                fontWeight: "bold",
              }}
            >
              Add Group Form +
            </Typography>
            <Typography
              value={groupName}
              required
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Group Name</Box>
            </Typography>
            <TextField
              onChange={(e) => setGroupName(e.target.value)}
              autoComplete='off'
              required
              fullWidth
            />
            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Select Type</Box>
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id='demo-simple-select-label'>Type</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Type'
                onChange={handleChange}
              >
                <MenuItem value='playlist'>Music List</MenuItem>
                <MenuItem value='albumlist'>Album List</MenuItem>
              </Select>
            </FormControl>

            {showFlag && (
              <Box>
                {type == "playlist" ? (
                  <>
                    <Typography
                      sx={{
                        userSelect: "none",
                      }}
                      variant='h6'
                    >
                      <Box sx={{ fontWeight: "bold", m: 1 }}>
                        Select Music List
                      </Box>
                    </Typography>
                    <TextField
                      value={musicListValue}
                      onClick={() => {
                        setIsDrawerOpen(true);
                        setDrawerMusicList(true);
                        setDrawerAlbumList(false);
                      }}
                      required
                      placeholder='Select Music List'
                      fullWidth
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        userSelect: "none",
                      }}
                      variant='h6'
                    >
                      <Box sx={{ fontWeight: "bold", m: 1 }}>
                        Select Album List
                      </Box>
                    </Typography>
                    <TextField
                      value={albumListValue}
                      onClick={() => {
                        setIsDrawerOpen(true);
                        setDrawerMusicList(false);
                        setDrawerAlbumList(true);
                      }}
                      required
                      placeholder='Select Album List'
                      fullWidth
                    />
                  </>
                )}
              </Box>
            )}

            <Stack marginTop={3}>
              {image && (
                <Stack direction='column' spacing={2}>
                  <Box
                    sx={{
                      height: "25vh",
                      width: "100%",
                      backgroundImage: `url(${URL.createObjectURL(image)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Typography
              sx={{
                userSelect: "none",
              }}
              variant='h6'
            >
              <Box sx={{ fontWeight: "bold", m: 1 }}>Group Image</Box>
            </Typography>
            <TextField
              onChange={imageUploader}
              required
              type='file'
              fullWidth
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction='row' spacing={3}>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: "#f44336",
                  "&:hover, &:focus": {
                    bgcolor: "#d32f2f",
                  },
                }}
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='outlined'>
                Add Group
              </Button>
            </Stack>
          </Box>
        </Paper>
      </form>
      <Drawer
        width={"100%"}
        anchor='button'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box width='1980px'>
          <Box
            sx={{
              display: "flex",
              margin: "80px 0 0 0 ",
            }}
            justifyContent='space-between'
            p={1}
            textAlign='center'
            role='presentation'
          >
            <Typography variant='h6' component='div'>
              Select your music lists
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginRight: "2rem",
              }}
            >
              <IconButton
                size='large'
                sx={{
                  margin: "0 30px 0 0",
                }}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#ED474A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {drawerMusicList && <Group_List_Drawer_Music_List />}
          {drawerAlbumList && <Group_List_Drawer_Album_List />}
        </Box>
      </Drawer>
    </div>
  );
};

export default Group_List_Add;
