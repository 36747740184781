import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  FormGroup,
  FormControlLabel,
  Switch,
  Drawer,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useSelector, useDispatch } from "react-redux";
import { getSongList } from "../../Song_List/api/SongListApi";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import PianoIcon from "@mui/icons-material/Piano";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { BarLoader } from "react-spinners";
import ConfirmDelete from "../../../ReuseComponents/ConfirmDelete";

const titles = [
  { id: "music-title", label: "Music Title", minWidth: 150 },
  { id: "album-name", label: "Album", align: "left", minWidth: 170 },
  { id: "artist-name", label: "Artist", align: "left", minWidth: 170 },
  { id: "group-name", label: "Group", align: "left", minWidth: 170 },
  { id: "contract-type", label: "Contract", align: "left", minWidth: 170 },
  {
    id: "producer-name",
    label: "Producer",
    align: "left",
    minWidth: 170,
  },
  {
    id: "contract action",
    label: "Contract Action",
    align: "left",
    minWidth: 170,
  },
];

const ContractList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { token, admin_id } = useSelector((state) => state.authentication);
  const { songList, isLoading, songTotal, loadingAnimation } = useSelector(
    (state) => state.song_list
  );
  const [inputVal, setInputVal] = useState("");
  const [searchSong, setSearchSong] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const timeoutInput = setTimeout(() => {
      setSearchSong(inputVal);
    }, 400);
    return () => clearInterval(timeoutInput);
  }, [inputVal]);

  useEffect(() => {
    dispatch(
      getSongList({
        admin_id,
        auth: token,
        page,
        limit: rowsPerPage,
        searchSong,
        type: "Song",
        contract: "&contract=1",
      })
    );
  }, [page, rowsPerPage, searchSong]);

  useEffect(() => {
    if (searchSong.length < 1)
      dispatch(
        getSongList({
          admin_id,
          auth: token,
          page,
          limit: rowsPerPage,
          searchSong,
          type: "Song",
          contract: "&contract=1",
        })
      );
  }, [searchSong]);

  console.log({ attachment });

  return (
    <>
      {loadingAnimation ? (
        <>
          <Paper
            sx={{
              padding: "20px",
            }}
          >
            <Box
              component='form'
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack alignItems='center' direction='row' spacing={2}>
                <Typography component='span'>
                  <Box
                    sx={{
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Contracted Song List
                  </Box>
                </Typography>
                <TextField
                  width='5rem'
                  label='Search Song Name'
                  type='text'
                  onChange={(e) => setInputVal(e.target.value)}
                />
              </Stack>
              <Button
                type='button'
                aria-label='search-song'
                onClick={() => navigate("/contract/contract-song")}
                sx={{
                  marginRight: "5rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                variant='outlined'
              >
                Make Contract
              </Button>
            </Box>
          </Paper>
          {isLoading && songList && (
            <Paper
              sx={{ width: "100%", marginTop: "3rem", overflow: "hidden" }}
            >
              <TableContainer>
                <Table stickyHeader aria-label='song-list-table'>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          bgcolor: "#d7ccc8",
                          color: "#424242",
                        },
                      }}
                    >
                      {titles.map((title) => (
                        <TableCell align={title.align} key={title.id}>
                          {title.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {songList?.data?.length > 0 ? (
                      songList?.data?.map((list) => (
                        <TableRow key={list.song_key}>
                          <TableCell>
                            <Typography variant='body1' component='div'>
                              <Link
                                to='/song-detail'
                                state={{
                                  song_key: list.song_key,
                                }}
                              >
                                {list?.title}
                              </Link>
                            </Typography>
                            <br />
                            <Box
                              sx={{
                                display: "flex",
                                alginItems: "center",
                              }}
                            >
                              <InterpreterModeIcon
                                size='small'
                                sx={{
                                  margin: "0 4px",
                                  color: "#1769aa",
                                }}
                              />
                              {list?.singers ? (
                                list?.singers?.length == 1 ? (
                                  <Link
                                    to='/singer-detail'
                                    state={{
                                      singer_key: list?.singers[0].singer_key,
                                    }}
                                  >
                                    {list?.singers[0]?.name}
                                  </Link>
                                ) : (
                                  list?.singers?.map((singer) => (
                                    <Link
                                      to='/singer-detail'
                                      state={{
                                        singer_key: singer.singer_key,
                                      }}
                                      key={singer.singer_key}
                                    >
                                      {singer.name + " ၊ "}
                                    </Link>
                                  ))
                                )
                              ) : (
                                "N/A"
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align='left'>
                            {list?.album == null ? (
                              "N/A"
                            ) : (
                              <Link
                                to='/album-detail'
                                state={{ album_key: list.album_key }}
                              >
                                {list?.album.name}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.artists ? (
                              list?.artists?.length == 1 ? (
                                <Link
                                  to='/artist-detail'
                                  state={{
                                    artist_key: list?.artists[0].artist_key,
                                  }}
                                >
                                  {list?.artists[0]?.name}
                                </Link>
                              ) : (
                                list?.artists?.map((artist) => (
                                  <Link
                                    to='/artist-detail'
                                    state={{
                                      artist_key: artist.artist_key,
                                    }}
                                    key={artist.artist_key}
                                  >
                                    {artist.name + " ၊ "}{" "}
                                  </Link>
                                ))
                              )
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell>
                            {list.music_list === null ? (
                              "N/A"
                            ) : (
                              <Tooltip
                                placement='right-start'
                                sx={{
                                  background: "transparent",
                                }}
                                title={
                                  <Box
                                    sx={{
                                      backgroundColor: "white",
                                      width: "200px",
                                      padding: "20px",
                                      color: "#000",
                                    }}
                                  >
                                    <Typography variant='h6'>Group</Typography>

                                    {list?.music_lists?.map((li) => (
                                      <Typography
                                        sx={{
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {li?.name}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <IconButton
                                  sx={{
                                    color: "#0277bd",
                                  }}
                                >
                                  <PianoIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>
                            {list?.contracts?.audio == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.audio?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.audio?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <HeadphonesIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.audioKaraoke == null ? (
                              "N/A"
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Audio Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.audioKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :
                                        {list.contracts?.audioKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <InterpreterModeIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.video == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.video?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date : {list.contracts?.video?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <PersonalVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {list?.contracts?.videoKaraoke == null ? (
                              ""
                            ) : (
                              <Tooltip
                                title={
                                  <Card sx={{ maxWidth: "200px" }}>
                                    <CardContent>
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        Video Karaoke Contract Timeline
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        Start Date :
                                        {list.contracts?.videoKaraoke?.start}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        End Date :
                                        {list.contracts?.videoKaraoke?.end}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton>
                                  <MusicVideoIcon
                                    sx={{
                                      color: "#9FC490",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {list?.producer == null ? (
                              "N/A"
                            ) : list?.producer?.length == 1 ? (
                              <Link
                                to='/producer-detail'
                                state={{
                                  producer_key: list?.producer?.producer_key,
                                }}
                              >
                                {list?.producer[0].name}
                              </Link>
                            ) : (
                              <Link
                                to='/producer-detail'
                                state={{
                                  producer_key: list?.producer?.producer_key,
                                }}
                              >
                                {list?.producer.name}
                              </Link>
                            )}
                            <Tooltip title={list.amount}>
                              <IconButton>
                                <LocalAtmIcon
                                  sx={{
                                    color: "#fcd975",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Stack direction='row'>
                              <IconButton
                                onClick={() => {
                                  navigate("/contract/update-song-list", {
                                    state: {
                                      contract_song_data: list,
                                    },
                                  });
                                }}
                              >
                                <EditIcon color='info' />
                              </IconButton>
                              <ConfirmDelete />

                              <IconButton
                                onClick={() => {
                                  setIsDrawerOpen(true);
                                  setAttachment(list?.attachments);
                                }}
                              >
                                <PhotoLibraryIcon sx={{ color: "#8bc34a" }} />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/contract/contract-song/contract-upload",
                                    {
                                      state: {
                                        contract_song_data: list,
                                      },
                                    }
                                  )
                                }
                              >
                                <FileUploadIcon sx={{ color: "#827717" }} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Alert fullWidth severity='error'>
                            No Song Here!
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component='div'
                count={songTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          <Drawer
            variant='temporary'
            sx={{
              zIndex: "100",
            }}
            width={"100%"}
            anchor='top'
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <Box
              sx={{
                width: "1980px",
                height: "100vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
                justifyContent='space-between'
                p={1}
                textAlign='center'
                role='presentation'
              >
                <Typography variant='h6' component='div'>
                  Contract Photos
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    margin: "4rem 4rem",
                  }}
                >
                  <IconButton onClick={() => setIsDrawerOpen(false)}>
                    <CloseIcon color='error' />
                  </IconButton>
                </Box>
              </Box>

              <Carousel
                navButtonsAlwaysVisible
                sx={{
                  width: "1000px",
                  backgroundColor: "#fffefa",
                  margin: "0 auto",
                }}
              >
                {attachment?.map((item, i) => (
                  <Stack alignItems='center'>
                    <img
                      textAlign='center'
                      width='700px'
                      height='700px'
                      src={item?.media_link}
                    />
                  </Stack>
                  // </Box>
                ))}
              </Carousel>
            </Box>
          </Drawer>
        </>
      ) : (
        <BarLoader width='100%' color='#36d7b7' />
      )}
    </>
  );
};

export default ContractList;
