import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";
import axios from "axios";

export const addSinger = createAsyncThunk(
  "addSinger",
  async ({ admin_id, auth, data }) => {
    const response = await axios.post(
      REAL_API + `/singers?admin_id=${admin_id}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const updateSinger = createAsyncThunk(
  "updateSinger",
  async ({ admin_id, auth, singer_key, data }) => {
    console.log({ admin_id, auth, singer_key, data });
    const response = await axios.post(
      REAL_API + `/singers/${singer_key}?admin_id=${admin_id}&_method=PUT`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + auth,
        },
      }
    );
    console.log(response);
    console.log(response.data);
    return response.data;
  }
);

export const deleteSinger = createAsyncThunk(
  "delete singer",
  async ({ admin_id, auth, singer_key }) => {
    console.log({ admin_id, auth, singer_key });
    const response = await fetch(
      REAL_API + `/singers/${singer_key}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + auth,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
