import { createAsyncThunk } from "@reduxjs/toolkit";
import { REAL_API } from "../../../globalApi";

export const getGroupList = createAsyncThunk(
  "group list/getGroupList",
  async ({ admin_id, auth }) => {
    const response = await fetch(REAL_API + `/groups?admin_id=${admin_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        credentials: "include",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = await response.json();
    return result;
  }
);

// {{localPath}}/api/admin/groups?admin_id={{adminID}}
export const addGroup = createAsyncThunk(
  "add Group List",
  async ({
    admin_id,
    auth,
    group_name,
    group_image,
    group_type,
    musiclists,
  }) => {
    console.log({ admin_id, auth, group_name, group_image, musiclists });
    const response = await fetch(REAL_API + `/groups?admin_id=${admin_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        credentials: "include",
        Authorization: `Bearer ${auth}`,
      },
      body: JSON.stringify({
        group_name,
        group_image,
        musiclists,
        group_type,
      }),
    });
    const result = await response.json();
    console.log(result);
  }
);

export const deleteGroup = createAsyncThunk(
  "Delete Group",
  async ({ admin_id, auth, group_id, data }) => {
    console.log({ admin_id, auth, group_id });
    const response = await fetch(
      REAL_API + `/groups/${group_id}?admin_id=${admin_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);

// {{localPath}}/api/admin/groups/6423f8fdbdda2d13a807524d?admin_id={{adminID}}&_method=PUT

export const editGroup = createAsyncThunk(
  "Update Group",
  async ({ admin_id, auth, group_id, data }) => {
    console.log({ admin_id, auth, group_id, data });
    const response = await fetch(
      REAL_API + `/groups/${group_id}?admin_id=${admin_id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: `Bearer ${auth}`,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
  }
);
